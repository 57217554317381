import { useEffect, useRef, useState } from 'react';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { useAppSelector } from 'core/hooks/useAppSelector';
import Warnings from 'pages/Workbench/FinalReview/Warnings';
import OrderDetails from 'pages/Workbench/FinalReview/OrderDetails';
import Vesting from 'pages/Workbench/FinalReview/Vesting';
import LegalDescription from 'pages/Workbench/FinalReview/LegalDescription';
import Taxes from 'pages/Workbench/FinalReview/Taxes';
import { textStylesMontserrat } from 'styles/common';
import IconButton from 'components/styled/IconButton';
import { Icon, IconPaths } from 'components/styled/Icon';
import colors from 'core/constants/colors';
import ExceptionsRequirements from 'pages/Workbench/FinalReview/ExceptionsRequirements';
import MainButton from 'components/styled/MainButton';
import { ButtonVariants } from 'core/constants/common';
import mergeDownloadImage from 'core/helpers/mergeDownloadImageBuffer';
import {
    setIsFinalReviewVisible,
    setIsPopoutFinalReview
} from 'core/features/workbenchTabs/workbenchTabsSlice';
import {
    fetchFinalReviewDataThunk,
    fetchFinalReviewWarningsThunk,
    submitFinalReviewThunk,
    setHyperlinkStatusForFinalReport
} from 'core/features/examOrderFinalReview/examOrderFinalReviewSlice';
import api from 'core/api';
import { useNavigate } from 'react-router-dom';
import { ORDERS } from 'core/constants/navigation';
import {
    setCurrentDocSelectValue,
    setCurrentDocumentViewerPanel,
    setModalState
} from 'core/features/documentViewer/documentViewerSlice';
import { getWorkbenchURL } from 'core/helpers/workbenchHelpers';
import SwitchMain from 'components/styled/Switch';
import styles from './styles';

interface FinalReviewProps {
    isPopout: boolean;
}

declare global {
    interface Window {
        handleDocLinkClick: (event: MouseEvent) => void;
    }
}

/**
 * Show final review page
 * @param {boolean} isPopout Is in popout view
 * @component
 * @returns {JSX.Element}
 */
const FinalReview = ({ isPopout }: FinalReviewProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const externalWindow = useRef<Window>();
    const orderId = useAppSelector((state) => state.currentExamOrderData.currentExamOrder?.id);
    const { readonly } = useAppSelector((state) => state.workbenchStatusData);
    const { useImageHyperlinks } = useAppSelector(
        (state) => state.currentExamOrderData.projectOrder
    );
    const { isPopoutFinalReview } = useAppSelector((state) => state.workbenchTabsData);
    const isCompleteReviewButtonDisabled = useAppSelector(
        (state) => state.workbenchTabsData.isCompleteReviewButtonDisabled
    );
    const refToLink = useRef(null);
    const [urlToDownload, setUrlToDownload] = useState('');
    const { subtitle_16_semibold } = textStylesMontserrat().classes;
    const {
        finalReviewContainer,
        finalReviewPanel,
        finalReviewHeader,
        sectionDivider,
        footerDivider,
        finalReviewFooter,
        styledHyperlinksText
    } = styles().classes;

    const isRenderingInsideFinalReviewPopout = window.name === 'final-review-popout-window';

    /**
     * Download TSRI report
     */
    const downloadFile = async () => {
        const { buffer, type } = await api.reswareRemitOrder.getTSRReport(orderId);
        const blob = new Blob([buffer], { type });
        const blobURL = URL.createObjectURL(blob);
        setUrlToDownload(blobURL);
    };

    const handleHyperlinkSwitchChange = () => {
        dispatch(
            // Have to negate the current value because of miscommunication in the
            // naming of the fields between FE and BE
            setHyperlinkStatusForFinalReport(orderId, !useImageHyperlinks)
        );
    };

    /**
     * Set complete status to order
     */
    const completeReview = () => {
        dispatch(submitFinalReviewThunk(orderId));
        dispatch(setIsFinalReviewVisible(false));
        navigate(ORDERS);
    };

    /**
     * Fetch final review data and errors
     */
    useEffect(() => {
        dispatch(fetchFinalReviewDataThunk(orderId));
        dispatch(fetchFinalReviewWarningsThunk(orderId));
    }, []);

    /**
     * When file was downloaded form server, it will be automatically download to user machine
     */
    useEffect(() => {
        if (urlToDownload !== '') {
            refToLink.current.click();
            setUrlToDownload('');
        }
    }, [urlToDownload]);

    /**
     * Main window contains reference to the popout window. When we close the popout-window, it will send close action to its store.
     * Main window will try to sync the same close action in its store.
     * And hence if close is the call then we are closing the window using close method.
     */
    useEffect(() => {
        if (externalWindow.current && !isPopoutFinalReview) {
            externalWindow.current.close();
        }
    }, [isPopoutFinalReview]);

    const mergeDownload = async (docIds: string) => {
        const docIdsArray = docIds.split(',');
        const res = await mergeDownloadImage(null, docIdsArray);
        const blob = new Blob([res.buffer], { type: res.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
    };

    // Define the global function to handle click events on document links
    window.handleDocLinkClick = async function (event: MouseEvent) {
        event.preventDefault();
        const docIds = (event.currentTarget as HTMLAnchorElement).getAttribute('href');
        if (event.ctrlKey) {
            await mergeDownload(docIds);
        } else {
            if (docIds.includes(',')) {
                await mergeDownload(docIds);
            } else {
                dispatch(setCurrentDocSelectValue(docIds));
                dispatch(setCurrentDocumentViewerPanel(null));
                dispatch(setModalState(true));
            }
        }
    };

    /**
     * Open popup handler
     */
    const handleOpenInNewWindow = () => {
        dispatch(setIsPopoutFinalReview(true));
        externalWindow.current = window.open(
            `${getWorkbenchURL()}/final-review`,
            'final-review-popout-window',
            'width=1200,height=800,left=200,top=200'
        );
    };

    return (
        <Box className={finalReviewContainer}>
            <section className={finalReviewPanel}>
                <Stack>
                    <Box className={finalReviewHeader}>
                        <Typography className={subtitle_16_semibold}>
                            {' '}
                            Final Review{' '}
                        </Typography>
                        {!isPopout && (
                            <Stack direction={'row'} columnGap={'20px'}>
                                <IconButton
                                    withoutBackground
                                    icon={
                                        <Icon
                                            viewBox={'0 0 20 20'}
                                            fill={colors.main.secondaryDark}
                                            path={IconPaths.openInNew}
                                        />
                                    }
                                    onClick={handleOpenInNewWindow}
                                />
                                <IconButton
                                    withoutBackground
                                    icon={
                                        <Icon
                                            fill={colors.main.secondaryDark}
                                            path={IconPaths.close}
                                        />
                                    }
                                    onClick={() => dispatch(setIsFinalReviewVisible(false))}
                                />
                            </Stack>
                        )}
                    </Box>
                    <Warnings />
                    <Divider className={sectionDivider} />
                    <OrderDetails />
                    <Divider className={sectionDivider} />
                    <Vesting />
                    <Divider className={sectionDivider} />
                    <LegalDescription />
                    <Divider className={sectionDivider} />
                    <Taxes />
                    <Divider className={sectionDivider} />
                    <ExceptionsRequirements />
                    <Divider className={sectionDivider} />
                    <Box
                        className={footerDivider}
                        display={'flex'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}>
                        <SwitchMain
                            value={'hyperlink-toggle'}
                            checked={!useImageHyperlinks}
                            disabled={readonly}
                            onChange={handleHyperlinkSwitchChange}
                            variant={'secondary'}
                        />
                        <Typography className={styledHyperlinksText}>
                            Remove Hyperlinks
                        </Typography>
                    </Box>
                    <Divider className={sectionDivider} />
                    <Box className={finalReviewFooter}>
                        <MainButton
                            retainCase
                            variant={ButtonVariants.secondary}
                            text={'Cancel'}
                            action={() => {
                                if (isRenderingInsideFinalReviewPopout) {
                                    dispatch(setIsPopoutFinalReview(false));
                                } else {
                                    dispatch(setIsFinalReviewVisible(false));
                                }
                            }}
                        />
                        <Stack direction={'row'} spacing={'20px'}>
                            <MainButton
                                retainCase
                                variant={ButtonVariants.secondary}
                                text={'Preview TSRI'}
                                action={downloadFile}
                            />
                            <MainButton
                                retainCase
                                text={'Complete Review'}
                                action={completeReview}
                                disabled={readonly || isCompleteReviewButtonDisabled}
                            />
                        </Stack>
                    </Box>
                </Stack>
            </section>
            <a
                style={{ display: 'hidden' }}
                ref={refToLink}
                download={'TSRIReport'}
                href={urlToDownload}
            />
        </Box>
    );
};

export default FinalReview;
