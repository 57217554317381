import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Construction from '@mui/icons-material/Construction';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Box,
    Drawer,
    List,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    SvgIcon,
    Button
} from '@mui/material';
import {
    ORDERS,
    TEST_ORDERS,
    TEST_ORDER_FORM,
    WORKBENCH,
    CONFIG_MANAGER,
    DASHBOARD
} from 'core/constants/navigation';
import { OrderStatusesIds, Roles, SidebarPageNames } from 'core/constants/common';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { IconPaths } from 'components/styled/Icon';
import BadgeMain from 'components/styled/Badge';
import { ProjectOrder } from 'types/dataModels';
import PermissionRestrictedComponent from 'components/common/RBAC/PermissionRestrictedComponent';
import ProfileCard from './ProfileCard';
import styles from './LeftSidebar.module.scss';

const NOTIFICATION_COUNT = 1;

/**
 * Component for rendering left sidebar menu for navigation through app pages
 * @component
 * @returns {JSX.Element}
 */
function LeftSidebar() {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const ref = useRef(null);

    const inProgressOrders = useAppSelector(
        (state) => state.ordersData.orders.inProgressOrders
    );

    // code to access left sidebar, when self order is paused by examiner and pause timer displays on screen.
    const { projectOrder } = useAppSelector((state) => state.currentExamOrderData);
    const { projectStatus } = projectOrder || ({} as ProjectOrder);
    const showSidebarOnPauseModalOpen =
        projectStatus?.id === OrderStatusesIds.InProgressPaused;

    /**
     * Check app route for highlight current tab in sidebar
     * @param {string} route Route name
     * @returns {boolean}
     */
    const isActive = (route: string): boolean => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, currentRoute] = location.pathname.split('/');
        return currentRoute === route;
    };

    /**
     * Navigation to selected route
     * @param {string} route Route name
     */
    const onNavigate = (route: string) => {
        navigate(`/${route}`);
        setOpen(false);
    };

    const handleWorkbenchNavigation = () => {
        const count = inProgressOrders?.count ?? 0;
        const orderId = count === 1 && inProgressOrders.data?.[0]?.orderId;
        if (count === 0) {
            onNavigate(`${WORKBENCH}/empty`);
        } else if (count === 1) {
            onNavigate(`${WORKBENCH}/${orderId}`);
        } else if (count > 1) {
            onNavigate(`${WORKBENCH}/empty`);
        }
    };

    return (
        <section
            className={clsx(
                styles.sidebarContainer,
                showSidebarOnPauseModalOpen ? styles.setSidebarVisibility : ''
            )}
            ref={ref}>
            <Drawer
                onClose={() => {
                    setOpen(false);
                }}
                variant="permanent"
                className={clsx(styles.drawer, {
                    [styles.drawerOpen]: open,
                    [styles.drawerClose]: !open
                })}
                classes={{
                    paper: clsx(styles.paper, {
                        [styles.drawerOpen]: open,
                        [styles.drawerClose]: !open
                    })
                }}>
                <Button
                    className={styles.sidebarToggle}
                    onClick={() => setOpen(!open)}
                    disableRipple>
                    <ArrowRightIcon
                        className={open ? styles['arrowRight--expanded'] : styles.arrowRight}
                    />
                </Button>
                <Box className={styles.drawerListItems}>
                    <Box>
                        <Box className={styles.menuTitle}>{open ? 'MENU' : ''}</Box>
                        <List component="nav">
                            <PermissionRestrictedComponent
                                allowedRoles={[Roles.PulseAdmin, Roles.PulseManager]}>
                                <ListItemButton
                                    className={clsx(styles.listItem, {
                                        [styles.active]: isActive(DASHBOARD)
                                    })}
                                    onClick={() => onNavigate(DASHBOARD)}>
                                    <ListItemIcon className={styles.listItem__icon}>
                                        {isActive(DASHBOARD) ? (
                                            <SvgIcon viewBox={'-2 -3 24 24'}>
                                                <path d={IconPaths.leaderboardActive} />
                                            </SvgIcon>
                                        ) : (
                                            <SvgIcon viewBox={'-2 -3 24 24'}>
                                                <path d={IconPaths.leaderboardInactive} />
                                            </SvgIcon>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        className={styles.listItem__text}
                                        primary={SidebarPageNames.dashboard}
                                    />
                                </ListItemButton>
                            </PermissionRestrictedComponent>
                            <ListItemButton
                                className={clsx(styles.listItem, {
                                    [styles.active]: isActive(WORKBENCH)
                                })}
                                onClick={handleWorkbenchNavigation}>
                                <ListItemIcon className={styles.listItem__icon}>
                                    {isActive(WORKBENCH) ? (
                                        <SvgIcon viewBox={'-3 -5 24 24'}>
                                            <path d={IconPaths.viewQuiltActive} />
                                        </SvgIcon>
                                    ) : (
                                        <SvgIcon viewBox={'-3 -5 24 24'}>
                                            <path d={IconPaths.viewQuiltInactive} />
                                        </SvgIcon>
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    className={styles.listItem__text}
                                    primary={SidebarPageNames.workbench}
                                />
                            </ListItemButton>
                            <ListItemButton
                                className={clsx(styles.listItem, {
                                    [styles.active]: isActive(ORDERS)
                                })}
                                onClick={() => onNavigate(ORDERS)}>
                                <ListItemIcon className={styles.listItem__icon}>
                                    {isActive(ORDERS) ? (
                                        <SvgIcon viewBox={'-3 -3 24 24'}>
                                            <path d={IconPaths.stickyNoteActive} />
                                        </SvgIcon>
                                    ) : (
                                        <SvgIcon viewBox={'-3 -3 24 24'}>
                                            <path d={IconPaths.stickyNoteInactive} />
                                        </SvgIcon>
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    className={styles.listItem__text}
                                    primary={SidebarPageNames.orders}
                                />
                            </ListItemButton>
                            <ListItemButton
                                disabled
                                className={clsx(styles.listItem, styles.disabled)}>
                                <ListItemIcon className={styles.listItem__icon}>
                                    <SvgIcon viewBox={'-2 -2 24 24'}>
                                        <path d={IconPaths.libraryBooksInactive} />
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText
                                    className={styles.listItem__text}
                                    primary={SidebarPageNames.history}
                                />
                            </ListItemButton>
                            <ListItemButton
                                className={clsx(styles.listItem, {
                                    [styles.active]: isActive(`${TEST_ORDERS}`)
                                })}
                                onClick={() =>
                                    onNavigate(`${TEST_ORDERS}/${TEST_ORDER_FORM}`)
                                }>
                                <ListItemIcon className={styles.listItem__icon}>
                                    {isActive(`${TEST_ORDERS}`) ? (
                                        <SvgIcon viewBox={'-2.15 0 28 28'}>
                                            <path d={IconPaths.newOrderActive} />
                                        </SvgIcon>
                                    ) : (
                                        <SvgIcon viewBox={'-2.15 0 28 28'}>
                                            <path d={IconPaths.newOrderInactive} />
                                        </SvgIcon>
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    className={styles.listItem__text}
                                    primary={SidebarPageNames.neworder}
                                />
                            </ListItemButton>
                            <ListItemButton
                                disabled
                                className={clsx(styles.listItem, styles.disabled)}>
                                <ListItemIcon className={styles.listItem__icon}>
                                    {NOTIFICATION_COUNT ? (
                                        <BadgeMain badgeContent={NOTIFICATION_COUNT}>
                                            <SvgIcon viewBox={'-2 -2 24 24'}>
                                                <path d={IconPaths.smsInactive} />
                                            </SvgIcon>
                                        </BadgeMain>
                                    ) : (
                                        <SvgIcon viewBox={'-2 -2 24 24'}>
                                            <path d={IconPaths.smsInactive} />
                                        </SvgIcon>
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    className={styles.listItem__text}
                                    primary={SidebarPageNames.messages}
                                />
                            </ListItemButton>
                            <ListItemButton
                                disabled
                                className={clsx(styles.listItem, styles.disabled)}>
                                <ListItemIcon className={styles.listItem__icon}>
                                    <SvgIcon viewBox={'-2 0 24.5 24.5'}>
                                        <path d={IconPaths.headphonesInactive} />
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText
                                    className={styles.listItem__text}
                                    primary={SidebarPageNames.support}
                                />
                            </ListItemButton>
                            <PermissionRestrictedComponent allowedRoles={[Roles.PulseAdmin]}>
                                <ListItemButton
                                    className={clsx(styles.listItem, {
                                        [styles.active]: isActive(CONFIG_MANAGER)
                                    })}
                                    onClick={() => onNavigate(CONFIG_MANAGER)}>
                                    <ListItemIcon className={styles.listItem__icon}>
                                        {isActive(CONFIG_MANAGER) ? (
                                            <SvgIcon>
                                                <Construction />
                                            </SvgIcon>
                                        ) : (
                                            <SvgIcon>
                                                <Construction />
                                            </SvgIcon>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        className={styles.listItem__text}
                                        primary={SidebarPageNames.config}
                                    />
                                </ListItemButton>
                            </PermissionRestrictedComponent>
                        </List>
                    </Box>
                    <ProfileCard open={open} onNavigate={onNavigate} />
                </Box>
            </Drawer>
        </section>
    );
}

export default LeftSidebar;
