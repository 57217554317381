import { HeaderNames } from 'pages/Workbench/FullScreenSidebar/KeyDocumentsGrouping/PropertySubpanelHeader/constants';
import { Directions, KeyDocumentWithSuccessors, SortColumnNames } from 'types/dataModels';

export const sortDocuments = (
    documents: KeyDocumentWithSuccessors[],
    currentSortingColumn: SortColumnNames,
    sortingDirections: Directions
) => {
    if (currentSortingColumn === HeaderNames.Date) {
        return documents.slice().sort((a, b) => {
            const doc1 = new Date(a.recordedDate).getTime();
            const doc2 = new Date(b.recordedDate).getTime();

            if (doc1 < doc2) {
                return sortingDirections[currentSortingColumn] === 'asc' ? -1 : 1;
            }
            if (doc1 > doc2) {
                return sortingDirections[currentSortingColumn] === 'asc' ? 1 : -1;
            }
            return 0;
        });
    } else if (currentSortingColumn === HeaderNames.Description) {
        return documents.slice().sort((a, b) => {
            if (a.examinerDescription < b.examinerDescription) {
                return sortingDirections[currentSortingColumn] === 'asc' ? -1 : 1;
            }
            if (a.examinerDescription > b.examinerDescription) {
                return sortingDirections[currentSortingColumn] === 'asc' ? 1 : -1;
            }

            // If examinerDescription is the same, compare tab indexes
            if (a.tabIndex < b.tabIndex) {
                return sortingDirections[currentSortingColumn] === 'asc' ? -1 : 1;
            }
            if (a.tabIndex > b.tabIndex) {
                return sortingDirections[currentSortingColumn] === 'asc' ? 1 : -1;
            }
            return 0;
        });
    } else if (currentSortingColumn === HeaderNames.DS) {
        return documents.slice().sort((a, b) => {
            if (a.examinerDataSourceDisplayValue < b.examinerDataSourceDisplayValue) {
                return sortingDirections[currentSortingColumn] === 'asc' ? -1 : 1;
            }
            if (a.examinerDataSourceDisplayValue > b.examinerDataSourceDisplayValue) {
                return sortingDirections[currentSortingColumn] === 'asc' ? 1 : -1;
            }

            // If examinerDataSourceDisplayValue is the same, compare tab indexes
            if (a.tabIndex < b.tabIndex) {
                return sortingDirections[currentSortingColumn] === 'asc' ? -1 : 1;
            }
            if (a.tabIndex > b.tabIndex) {
                return sortingDirections[currentSortingColumn] === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    return documents.slice();
};
