import { configureStore } from '@reduxjs/toolkit';
import { createStateSyncMiddleware } from 'core/services/stateSyncService';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import rootReducer from './reducers';

const preloadedState = {};
/**
 * The `specialClientActions` flag- tracks UI actions, allowing the pop-out window to
 * read and maintain an identical "initial" UI state.
 *
 * For example: Pinned warnings, Sidebar panels collapsed or expanded state.
 * */
export const specialClientActions = [
    'fullScreenSidebar',
    'searchWarnings',
    'workbenchSidebar',
    'examOrderKeyDocumentGroup',
    'examOrderManualSearch',
    'workbenchTabs'
];

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            createStateSyncMiddleware({
                name: 'pulse_state_sync',
                /**
                 * If you wish to avoid syncing an action between the base window and
                 * pop-out window, you can utilize the blacklist flag
                 */
                blacklist: [
                    'snackbar/setSnackbarState',
                    'snackbar/setSnackbarClose',
                    'uploadKeyDocumentForm/setIsOpen',
                    'uploadSearchPackageForm/setIsOpen',
                    'workbenchSidebar/setIsCodeBookOpen'
                ],
                whitelist: [],
                broadcastChannelOption: undefined,
                specialClientActions
            })
        ),
    devTools: process.env.NODE_ENV !== 'production' && typeof window === 'object',
    preloadedState
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    AnyAction
>;

// initantiate window.pulse global object
window.pulse = {};

export default store;
