import { AuthenticatedTemplate } from '@azure/msal-react';
import InlineLoadingSpinner from 'components/common/InlineLoadingSpinner';
import LoadingModal from 'components/common/LoadingModal';
import MsalInstanceProvider from 'components/common/MsalInstanceProvider';
import { useAxiosLoader } from 'core/api/api';
import {
    fetchExamOrder,
    fetchProjectOrder
} from 'core/features/currentExamOrder/currentExamOrderSlice';
import {
    fetchFinalReviewDataThunk,
    fetchFinalReviewWarningsThunk
} from 'core/features/examOrderFinalReview/examOrderFinalReviewSlice';
import { setForceEditable } from 'core/features/workbenchStatus/workbenchStatusSlice';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { isEmpty } from 'lodash';
import React, { Suspense, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { setCurrentExamOrder } from 'core/features/currentExamOrder/currentExamOrderSlice';
import { fetchDocumentTypes } from 'core/features/documentTypes/documentTypesSlice';
import { fetchExamCodeBookTemplatesData } from 'core/features/examCodeBook/examCodeBookSlice';
import { fetchExamOrderParentSuccessorsDocumentGroupData } from 'core/features/examOrderKeyDocumentGroup/examOrderKeyDocumentGroupSlice';
import { fetchExamOrderLegalDescriptionData } from 'core/features/examOrderLegalDescription/examOrderLegalDescriptionSlice';
import { getExamOrderSearchPackageGroupDataThunk } from 'core/features/examOrderSearchPackageGroup/examOrderSearchPackageGroupSlice';
import { fetchExamOrderSearchReportData } from 'core/features/examOrderSearchReport/examOrderSearchReportSlice';
import { fetchExamOrderStartersAndPriorsGroupDataThunk } from 'core/features/examOrderStartersAndPriorsGroup/examOrderStartersAndPriorsGroupSlice';
import { fetchExamOrderTaxesDataThunk } from 'core/features/examOrderTaxes/examOrderTaxesSlice';
import { fetchExamOrderVestingData } from 'core/features/examOrderVesting/examOrderVestingSlice';
import {
    setIsKeyDocsExpanded,
    setIsManualSearchExpanded,
    setIsOrderHistoryExpanded,
    setIsPinnedWarningsExpanded,
    setIsSCCExpanded,
    setIsSearchPackageExpanded,
    setOpenKeyDocsGroup,
    setOpenSearchPkgGroup
} from 'core/features/fullScreenSidebar/fullScreenSidebarSlice';
import {
    ISetKeyDocumentGroupPayloadAction,
    setKeyDocumentsGroup
} from 'core/features/keyDocumentsGrouping/keyDocumentsGroupingSlice';
import { fetchOrderHistory } from 'core/features/orderHistory/orderHistorySlice';
import { fetchProfileData } from 'core/features/profile/profileSlice';
import {
    ISetSearchPackageGroupPayloadAction,
    setSearchPackageGroup
} from 'core/features/searchPackageGrouping/searchPackageGroupingSlice';
import { setWarningsPanelPinnedState } from 'core/features/searchWarnings/searchWarningsSlice';
import {
    ISetStartersAndPriorsGroupPayloadAction,
    setStartersAndPriorsGroup
} from 'core/features/startersAndPriorsGrouping/startersAndPriorsGroupingSlice';
import {
    fetchAllowedStatuses,
    setReadOnlyView
} from 'core/features/workbenchStatus/workbenchStatusSlice';
import {
    fetchExamOrderReviewStateThunk,
    resetWorkbenchTabs,
    setIsFinalReviewVisible
} from 'core/features/workbenchTabs/workbenchTabsSlice';
import {
    setIsFullScreenMode,
    setIsOpen,
    setIsPopoutOpen
} from 'core/features/workenchSidebar/workbenchSidebarSlice';

export interface CommonPopoutLayoutProps {
    name: string;
    children: React.ReactNode;
    forceEditable?: boolean;
}

/**
 * Render popout layout
 * @constructor
 */
const CommonPopoutLayout = ({ name, children, forceEditable }: CommonPopoutLayoutProps) => {
    window.name = name || 'common-popout-window';
    const params = useParams();
    const dispatch = useAppDispatch();
    const currentOrder = useAppSelector(
        (state) => state.currentExamOrderData.currentExamOrder
    );
    const [loading] = useAxiosLoader(true);

    /**
     * If all sections are reviewed fetch final review
     * OR If order is on hold or paused, then to view order details data, fetch final review
     */
    useEffect(() => {
        if (params.orderId === 'empty') return;
        dispatch(fetchFinalReviewDataThunk(params.orderId));
        dispatch(fetchFinalReviewWarningsThunk(params.orderId));
        dispatch(fetchProfileData());
        dispatch(fetchExamOrder(params.orderId));
        dispatch(fetchProjectOrder(params.orderId));
        dispatch(setForceEditable(forceEditable));
        dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(params.orderId));
        dispatch(getExamOrderSearchPackageGroupDataThunk(params.orderId));
        dispatch(fetchExamOrderStartersAndPriorsGroupDataThunk(params.orderId));
        dispatch(fetchOrderHistory(params.orderId));
        dispatch(fetchDocumentTypes());
        dispatch(fetchExamCodeBookTemplatesData(params.orderId));
        dispatch(fetchAllowedStatuses(params.orderId));
        dispatch(fetchExamOrderReviewStateThunk(params.orderId));
        dispatch(fetchExamOrderSearchReportData(params.orderId));
        dispatch(fetchExamOrderTaxesDataThunk(params.orderId));
        dispatch(fetchExamOrderLegalDescriptionData(params.orderId));
        dispatch(fetchExamOrderVestingData(params.orderId));

        return () => {
            dispatch(setKeyDocumentsGroup({} as ISetKeyDocumentGroupPayloadAction));
            dispatch(setSearchPackageGroup({} as ISetSearchPackageGroupPayloadAction));
            dispatch(setStartersAndPriorsGroup({} as ISetStartersAndPriorsGroupPayloadAction));
            dispatch(resetWorkbenchTabs());
            dispatch(setCurrentExamOrder(null));
            dispatch(setIsFullScreenMode(false));
            dispatch(setIsOpen(false));
            dispatch(setIsPopoutOpen(false));
            dispatch(setIsFinalReviewVisible(false));
            dispatch(setReadOnlyView(false));
            dispatch(setIsPinnedWarningsExpanded(false));
            dispatch(setIsSCCExpanded(false));
            dispatch(setIsOrderHistoryExpanded(false));
            dispatch(setIsManualSearchExpanded(false));
            dispatch(setIsKeyDocsExpanded(false));
            dispatch(setIsSearchPackageExpanded(false));
            dispatch(setOpenKeyDocsGroup([]));
            dispatch(setOpenSearchPkgGroup([]));
            dispatch(setWarningsPanelPinnedState(false));
            // close popout window when examiner leaves current workbench order.
            if (window.pulse.popoutWindow) {
                window.pulse.popoutWindow.close();
            }
        };
    }, []);

    /**
     * Show empty view if current order is empty
     */
    if (isEmpty(currentOrder) || params.orderId === 'empty') {
        return <LoadingModal isOpen={loading} cancelHandler={null} />;
    }

    return (
        <MsalInstanceProvider>
            <>
                <AuthenticatedTemplate>
                    <LoadingModal isOpen={loading} cancelHandler={null} />
                    <Suspense fallback={<InlineLoadingSpinner />}>{children}</Suspense>
                </AuthenticatedTemplate>
            </>
        </MsalInstanceProvider>
    );
};

export default CommonPopoutLayout;
