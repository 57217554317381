import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { Client } from 'types/dataModels';

/**
 * This function will return a list of clients from the API, or throw an error if it fails.
 * @function
 * @category API
 * @subcategory clients
 * @returns {Client[]} An array of Client objects.
 */
export const getClient = async (): Promise<Client[]> => {
    try {
        const response = await api.getWithCache<Client[]>(`api/client`);
        return response?.data;
    } catch (e) {
        handleError(e);
    }
};
