import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { AccordionDetails, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import colors from 'core/constants/colors';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { contentStyles } from './styles';

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    '&:before': {
        display: 'none'
    },
    '&.Mui-expanded': {
        border: `1px solid ${colors.main.accent}`
    }
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({
    minHeight: '40px',
    padding: '9px 20px',
    backgroundColor: colors.main.accentHighlight5,
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)'
    },
    '& .MuiAccordionSummary-content': {
        margin: 0
    }
}));

export const Details = styled(AccordionDetails)({
    padding: 0,
    backgroundColor: colors.main.primaryLight
});

export interface IFinalReviewSubpanelProps {
    header: string;
    children: ReactNode;
    expanded?: boolean;
}

/**
 * Sub-panel for each search
 * @param {string} header Panel header (search name)
 * @param {string} effectiveDate Effective date (only for key docs)
 * @param {React.ReactNode} children children
 * @constructor
 */
export const FinalReviewSubpanel = ({
    header,
    children,
    expanded = false
}: IFinalReviewSubpanelProps) => {
    const { areAllTaxesExpanded } = useAppSelector((state) => state.finalReviewData);
    const { root, headerRow, headerText } = contentStyles().classes;
    const [isExpanded, setIsExpanded] = useState(expanded);
    useEffect(() => {
        setIsExpanded(areAllTaxesExpanded);
    }, [areAllTaxesExpanded]);
    return (
        <Accordion expanded={isExpanded}>
            <AccordionSummary onClick={() => setIsExpanded(!isExpanded)}>
                <Grid display={'flex'} width={'100%'} justifyContent={'space-between'}>
                    <Grid className={root}>
                        <Grid className={headerRow} gap={'6px'}>
                            <Grid className={headerText}>
                                <span>{header}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <Details>{children}</Details>
        </Accordion>
    );
};
