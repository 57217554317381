import {
    ExceptionsRequirementsPanelTypes,
    FieldValueTypes,
    PartyRoles
} from 'core/constants/common';
import { DocumentType, CommonDocument } from './dataModels';

export enum Section {
    exception = 0,
    requirement = 1
}

export interface Paragraph {
    id: string;
    sequence: number;
    docDetailString: string;
    keepLanguage?: boolean;
    typeInfo: DocumentType;
    linkedProperty: string;
    propertyFields: string[];
    fileIds: string[];
    reswareId: number;
    phraseFieldLinks: PhraseFieldLink[];
    codeBookId: string;
    codeTemplateId: string;
    section: ExceptionsRequirementsPanelTypes;
    code: string;
    label: string;
    phrase: string;
    phraseDocLinks: DocLink[];
    indentLevel: number;
    linkedDoc: string;
    isExpanded?: boolean;
    subParagraphs?: Paragraph[];
    parentId?: string;
    isAddSubPhraseBelowEditorVisible?: boolean;
    examinerDisplayLink: string;
    examinerPropertyAndTypeDisplayValue: string;
}

export interface DocLink {
    id: string;
    docId: string;
    label: string;
}

export interface ParagraphSequence {
    [key: string]: number;
}

export interface PhraseFieldLink {
    id: string;
    docId: string;
    fieldInfo: PhraseFieldInfo;
    inUse: boolean;
}

export interface PhraseFieldInfo {
    id: string;
    displayValue: string;
    fieldNameId: string;
    fieldName: string;
    fieldType: FieldValueTypes;
    typedValue?: ParagraphDocumentParty | Date | number | string;
    displayFieldName: string;
}

export interface ParagraphDocumentParty {
    isGrantee?: boolean;
    role: PartyRoles;
    fullLegal: string;
    ssn: string;
    suffix: string;
    first: string;
    middle: string;
    last: string;
    businessName: string;
}

export interface ParagraphDocDetail {
    instrumentNumber?: string;
    instrumentYear?: string;
    liber: string;
    page: string;
    bookType: string;
    documentNumber: string;
}

export interface DocGroup {
    linkedDoc: CommonDocument; // Would like this to be an actual doc. Makes displaying info easier
    fields: PhraseFieldLink[];
    isAlignedDoc: boolean; // tells us if the group we are displaying are the fields of the current code's document
}

export interface ISpanTagData {
    [key: string]: {
        originalText: string;
        highlightedText: string;
    };
}

export interface ParagraphError {
    subParagraphs?: {
        id: string;
        hasError: boolean;
    }[];
    id: string;
    hasError: boolean;
}
[];
