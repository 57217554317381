import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TestOrderFormErrorsData } from 'types/dataModels';

interface TestOrderFormErrorsState {
    testOrderFormErrors: TestOrderFormErrorsData;
}

const initialState: TestOrderFormErrorsState = {
    testOrderFormErrors: {
        parcelNumbers: {
            '0': {
                parcelNumberError: '',
                streetNumberError: '',
                streetDirectionError: '',
                streetNameError: '',
                streetSuffixError: '',
                unitError: '',
                cityError: '',
                stateError: 'Required field',
                zipError: '',
                countyError: 'Required field'
            }
        },
        subdivisionNameError: '',
        interestEstateTypeError: '',
        resWareFileIdError: '',
        agentFileNumberError: 'Required field',
        lenderError: '',
        loanAmountError: '',
        salePriceError: '',
        owners: {
            '0': {
                ownerBusinessNameError: '',
                ownerFirstNameError: '',
                ownerMiddleError: '',
                ownerLastNameError: '',
                ownerSuffixError: ''
            }
        },
        buyers: {
            '0': {
                buyerBusinessNameError: '',
                buyerFirstNameError: '',
                buyerMiddleError: '',
                buyerLastNameError: '',
                buyerSuffixError: ''
            }
        },
        originalTransactionProductType: {
            productTypeIdError: '',
            transactionTypeIdError: ''
        }
    }
};

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        /**
         * Set errors for test order form
         * @param state Slice state
         * @param action Payload with errors data to set
         */
        setTestOrderFormErrors(state, action: PayloadAction<TestOrderFormErrorsData>) {
            state.testOrderFormErrors = action.payload;
        },
        /**
         * Reset test order form errors data to initial state
         * @param state Slice state
         * @param action Payload with current tab to set
         *
         */
        clearTestOrderFormErrors(state, action: PayloadAction<number>) {
            const currentTab = action.payload;
            switch (currentTab) {
                case 0:
                    state.testOrderFormErrors = {
                        ...state.testOrderFormErrors,
                        resWareFileIdError:
                            initialState.testOrderFormErrors.resWareFileIdError,
                        agentFileNumberError:
                            initialState.testOrderFormErrors.agentFileNumberError,
                        loanAmountError: initialState.testOrderFormErrors.loanAmountError,
                        salePriceError: initialState.testOrderFormErrors.salePriceError
                    };
                    break;
                case 1:
                    state.testOrderFormErrors = {
                        ...state.testOrderFormErrors,
                        parcelNumbers: initialState.testOrderFormErrors.parcelNumbers
                    };
                    break;
                case 2:
                    state.testOrderFormErrors = {
                        ...state.testOrderFormErrors,
                        owners: initialState.testOrderFormErrors.owners,
                        buyers: initialState.testOrderFormErrors.buyers
                    };
                    break;
                default:
                    break;
            }
        }
    }
});

export const { setTestOrderFormErrors, clearTestOrderFormErrors } = formSlice.actions;

export default formSlice.reducer;
