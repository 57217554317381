// @ts-nocheck

import { handleError } from 'core/helpers/errorHandler';
import {
    ExamOrderKeyDocumentGroupType,
    ExamOrderParentSuccessorDocumentGroupType,
    ExamOrderSearchPackageGroupType,
    ExamOrderStartersAndPriorsGroupType,
    PriorFileStatements
} from 'types/dataModels';
import api from '../api';

/**
 * It gets the exam order document group
 * @function
 * @category API
 * @subcategory examOrderDocumentGroup
 * @param {string} orderId - The id of the order
 * @returns {ExamOrderKeyDocumentGroupType[]}
 */
export const getExamOrderDocumentGroup = async (
    orderId: string
): Promise<ExamOrderKeyDocumentGroupType[]> => {
    try {
        const response = await api.get<ExamOrderKeyDocumentGroupType[]>(
            `/api/examOrder/${orderId}/document/group/key`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It gets the exam order document group with parent and successtor relationships
 * @function
 * @category API
 * @subcategory examOrderDocumentGroup
 * @param {string} orderId - The id of the order
 * @returns {ExamOrderParentSuccessorDocumentGroupType[]}
 */
export const getExamOrderParentSuccessorDocumentGroup = async (
    orderId: string
): Promise<ExamOrderParentSuccessorDocumentGroupType[]> => {
    try {
        const response = await api.get<ExamOrderParentSuccessorDocumentGroupType[]>(
            `/api/examOrder/${orderId}/document/group/keyParentChild`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It gets the exam order search package group
 * @function
 * @category API
 * @subcategory examOrderDocumentGroup
 * @param {string} orderId - string -The id of the order
 * @returns {ExamOrderSearchPackageGroupType[]}
 */
export const getExamOrderSearchPackageGroup = async (
    orderId: string
): Promise<ExamOrderSearchPackageGroupType[]> => {
    try {
        const response = await api.get<ExamOrderSearchPackageGroupType[]>(
            `/api/examOrder/${orderId}/document/group/searchPackage`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * It gets the exam order starters and priors group
 * @function
 * @category API
 * @subcategory examOrderDocumentGroup
 * @param {string} orderId - string -The id of the order
 * @returns {ExamOrderStartersAndPriorsGroupType[]}
 */
export const getExamOrderStartersAndPriorsGroup = async (
    orderId: string
): Promise<ExamOrderStartersAndPriorsGroupType[]> => {
    try {
        const response = await api.get<ExamOrderStartersAndPriorsGroupType[]>(
            `/api/examOrder/${orderId}/document/group/priors`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Import statements
 * @function
 * @subcategory examOrderDocumentGroup
 * @param {string} orderId
 * @param {string} documentId
 * @param {PriorFileStatements} priorFileData
 * @returns {ExamOrderSearchPackageGroupType[]}
 */
export const importPriorFile = async (
    orderId: string,
    documentId: string,
    priorFileData: PriorFileStatements
): Promise<ExamOrderSearchPackageGroupType[]> => {
    try {
        const response = await api.post<
            PriorFileStatements,
            ExamOrderSearchPackageGroupType[]
        >(
            `/api/examOrder/${orderId}/document/group/${documentId}/importPriorFile`,
            priorFileData
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
