import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { Roles, SnackbarSeverity } from 'core/constants/common';
import { UserProfileData } from 'types/userProfile';
import { getRoleFromToken } from 'core/api/api';
import { IPublicClientApplication } from '@azure/msal-browser';

export type Role = `${Roles}`;

interface UserProfileState {
    profileImageUrl: string;
    oid: string;
    userData: UserProfileData;
    role: Role[] | null;
}

const initialState: UserProfileState = {
    profileImageUrl: '',
    oid: '',
    userData: {
        id: '',
        name: {
            first: '',
            middle: '',
            last: ''
        },
        division: {
            id: '',
            name: ''
        },
        office: {
            id: '',
            locationId: 0,
            name: ''
        },
        businessSegment: '',
        role: {
            id: '',
            name: ''
        },
        examinerLevel: {
            id: '',
            name: '',
            permissionLevel: 0
        },
        manager: {
            name: {
                first: '',
                middle: '',
                last: ''
            }
        }
    },
    role: null
};

const UserProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        // Sets profile image URL
        setUserProfileImageUrl(state, action: PayloadAction<string>) {
            state.profileImageUrl = action.payload;
        },
        // Sets the user's object ID
        setOid(state, action: PayloadAction<string>) {
            state.oid = action.payload;
        },
        // Updates user profile data
        setUserProfileData(state, action: PayloadAction<UserProfileData>) {
            state.userData = action.payload;
        },
        // Set user role
        setUserRole(state, action: PayloadAction<Role[]>) {
            state.role = action.payload;
        }
    }
});

export const { setUserProfileImageUrl, setOid, setUserProfileData, setUserRole } =
    UserProfileSlice.actions;

// Thunk to fetch user profile by object ID
export const fetchUserByObjectId =
    (oid: string): AppThunk =>
    async (dispatch) => {
        try {
            let response = await api.userProfile.getUserByObjectId(oid);
            if (!response) {
                response = await api.userProfile.createLoggedInUser();
            }
            dispatch(setUserProfileData(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get user profile: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

// Thunk to fetch user profile image
export const fetchUserProfileImage =
    (userId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.userProfile.getUserProfileImage(userId);
            dispatch(setUserProfileImageUrl(response || ''));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get user profile image: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

// Thunk to upload user profile image
export const uploadUserProfileImage =
    (userId: string, formData: FormData, imageSrc: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.userProfile.uploadUserProfileImage(userId, formData);
            if (response) {
                dispatch(setUserProfileImageUrl(imageSrc));
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Upload user profile image: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

// Thunk to remove user profile image
export const removeUserProfileImage =
    (userId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.userProfile.deleteUserProfileImage(userId);
            dispatch(setUserProfileImageUrl(''));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove user profile image: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

// Thunk to upload user profile image
export const updateUserProfileImage =
    (userId: string, formData: FormData, imageSrc: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.userProfile.updateUserProfileImage(userId, formData);
            if (response) {
                dispatch(setUserProfileImageUrl(imageSrc));
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update user profile image: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

// Thunk to get user role
export const getUserRole =
    (instance: IPublicClientApplication): AppThunk =>
    async (dispatch) => {
        try {
            const role = await getRoleFromToken(instance);
            dispatch(setUserRole(role));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get user role: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default UserProfileSlice.reducer;
