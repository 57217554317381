import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import {
    ParagraphReadDto,
    ParagraphWriteDto,
    ParagraphCreateDto
} from 'types/dto/exceptionsRequirementsDto';
import { ParagraphSequence } from 'types/exceptionsRequirements';

/**
 * Get all exceptions by order
 * @function
 * @category API
 * @param {string} orderId
 * @returns {Promise<ParagraphReadDto[]>}
 */
export const getExceptions = async (orderId: string): Promise<ParagraphReadDto[]> => {
    try {
        const response = await api.get<ParagraphReadDto[]>(`/api/order/${orderId}/exceptions`);
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Add exception to order
 * @function
 * @category API
 * @param {string} orderId
 * @param {ParagraphCreateDto} paragraphData
 * @returns {Promise<ParagraphReadDto>}
 */
export const postException = async (
    orderId: string,
    paragraphData: ParagraphCreateDto
): Promise<ParagraphReadDto> => {
    try {
        const response = await api.post<ParagraphCreateDto, ParagraphReadDto>(
            `/api/order/${orderId}/exceptions`,
            paragraphData
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Add exceptions to order
 * @function
 * @category API
 * @param {string} orderId
 * @param {ParagraphCreateDto[]} paragraphData
 * @returns {Promise<ParagraphReadDto[]>}
 */
export const postExceptions = async (
    orderId: string,
    paragraphData: ParagraphCreateDto[]
): Promise<ParagraphReadDto[]> => {
    try {
        const response = await api.post<ParagraphCreateDto[], ParagraphReadDto[]>(
            `/api/order/${orderId}/exceptions/paragraphs`,
            paragraphData
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Get paragraph by id
 * @function
 * @category API
 * @param {string} orderId
 * @param {string} paragraphId
 * @returns {Promise<ParagraphReadDto>}
 */
export const getParagraphById = async (
    orderId: string,
    paragraphId: string
): Promise<ParagraphReadDto> => {
    try {
        const response = await api.get<ParagraphReadDto>(
            `/api/order/${orderId}/exceptions/${paragraphId}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Update paragraph by id
 * @function
 * @category API
 * @param {string} orderId
 * @param {string} paragraphId
 * @param {ParagraphWriteDto} paragraphData
 * @returns {Promise<ParagraphReadDto>}
 */
export const updateParagraphById = async (
    orderId: string,
    paragraphId: string,
    paragraphData: ParagraphWriteDto
): Promise<ParagraphReadDto> => {
    try {
        const response = await api.put<ParagraphWriteDto, ParagraphReadDto>(
            `/api/order/${orderId}/exceptions/${paragraphId}`,
            paragraphData
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

export const changeParagraphById = async (
    orderId: string,
    paragraphId: string,
    codeTemplateId: string,
    keepLanguage: boolean
): Promise<ParagraphReadDto> => {
    try {
        const response = await api.post<{}, ParagraphReadDto>(
            `/api/order/${orderId}/exceptions/changeParagraph/${paragraphId}/${codeTemplateId}/${keepLanguage}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Delete paragraph by id
 * @function
 * @category API
 * @param {string} orderId
 * @param {string} paragraphId
 * @returns {string}
 */
export const deleteParagraphById = async (
    orderId: string,
    paragraphId: string
): Promise<string> => {
    try {
        const response = await api.delete(`/api/order/${orderId}/exceptions/${paragraphId}`);
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Update sequence
 * @function
 * @category API
 * @param {string} orderId
 * @param {ParagraphSequence} paragraphsSequence
 * @returns {Promise<ParagraphReadDto>}
 */
export const updateParagraphSequence = async (
    orderId: string,
    paragraphsSequence: ParagraphSequence
): Promise<ParagraphReadDto[]> => {
    try {
        const response = await api.put<ParagraphSequence, ParagraphReadDto[]>(
            `/api/order/${orderId}/exceptions/sequence`,
            paragraphsSequence
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * link key document to an existing Requirement, Exception, or sub-paragraph
 * @function
 * @category API
 * @param {string} orderId
 * @param {string} paragraphId
 * @param {string} documentId
 * @returns {Promise<ParagraphReadDto>}
 */
export const linkDocument = async (
    orderId: string,
    paragraphId: string,
    documentId: string
): Promise<ParagraphReadDto> => {
    try {
        const response = await api.post<{}, ParagraphReadDto>(
            `/api/order/${orderId}/exceptions/recreateparagraph/${paragraphId}/${documentId}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
