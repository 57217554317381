import clsx from 'clsx';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { textStylesRoboto } from 'styles/common';
import FinalReviewSubpanel from 'pages/Workbench/FinalReview/FinalReviewSubpanel';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { ExceptionsRequirementsPanelTypes } from 'core/constants/common';
import { Paragraph } from 'types/exceptionsRequirements';
import { extractText } from 'core/helpers/extractText';
import { DocLink } from 'types/exceptionsRequirements';
import { ValueDocLink } from 'types/dataModels';
import styles from './styles';

interface ISpanTag {
    id: string;
    spanTag: string;
}

/**
 * Render exceptions and requirements panel on final review
 * @component
 * @returns {JSX.Element}
 */
const ExceptionsRequirements = () => {
    const orderFinalReview = useAppSelector((state) => state.finalReviewData.orderFinalReview);
    const { paragraphs = [] } = orderFinalReview ?? {};
    const requirements = paragraphs.filter(
        (paragraph) => paragraph.section === ExceptionsRequirementsPanelTypes.Requirements
    );
    const exceptions = paragraphs.filter(
        (paragraph) => paragraph.section === ExceptionsRequirementsPanelTypes.Exceptions
    );
    const { body_large_16_semibold, body_14_regular } = textStylesRoboto().classes;
    const {
        exceptionsRequirementsPanel,
        information,
        exceptionsPanelsWrapper,
        subpanelDetails,
        codesList,
        phrase,
        codeDivider,
        panelDivider,
        textCenter
    } = styles().classes;

    const createLinkTagsForDocLinks = (docLinks: ValueDocLink[]) => {
        const links: ISpanTag[] = [];

        docLinks?.forEach((link) => {
            const label =
                "<a href='" +
                link.docId +
                "' title='Ctrl + Click to follow link' doclink='true' data-mce-href='" +
                link.docId +
                "' onClick='handleDocLinkClick(event)'>" +
                link.label +
                '</a>';
            const ret: ISpanTag = {
                id: link.id,
                spanTag: label
            };
            links.push(ret);
        });
        return links;
    };

    /**
     * This handles the display of data. This function recieves a uniqely templated string
     * and serialize the text to be displayed.
     * @param { string } content The string that comes from backend
     * @param { DocLink[] } phraseDocLinks
     * @returns { result }The content to be displayed in the TinyMCE editor
     */
    const convertTextForEditor = (content: string, phraseDocLinks: DocLink[]) => {
        let htmlContent = content;
        const regExp = new RegExp('{[^{}]*}', 'g');
        const docLinks = content.match(regExp);

        // links = a tags created for document links within codes
        // tags = span tags created to support dynamic fields within a code
        const links = createLinkTagsForDocLinks(phraseDocLinks);

        if (docLinks) {
            docLinks.forEach((link) => {
                const obj = links.find((tag) => tag.id === link.slice(1, -1));
                if (obj !== undefined) {
                    // This should never not get hit
                    htmlContent = htmlContent.replace(link, obj.spanTag);
                }
            });
        }
        htmlContent = htmlContent.replaceAll(/\r\n|\r|\n/g, '<br>');
        htmlContent = htmlContent.replaceAll(/\t/g, '&nbsp; &nbsp; ');
        htmlContent = htmlContent.replace(/ {5}/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
        return htmlContent;
    };

    const panel = (paragraphs: Paragraph[], header: string) => {
        return (
            <Stack className={exceptionsPanelsWrapper}>
                <FinalReviewSubpanel header={header} expanded={true}>
                    <Box className={subpanelDetails}>
                        {paragraphs?.length ? (
                            <Stack className={codesList}>
                                {paragraphs?.map((paragraph, i) => {
                                    return (
                                        <Stack key={paragraph.code + '_' + paragraph.id}>
                                            <Typography
                                                className={clsx(body_14_regular, information)}>
                                                {`[${paragraph.code}]`}
                                            </Typography>
                                            <Typography
                                                className={clsx(body_14_regular, phrase)}>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: convertTextForEditor(
                                                            extractText(paragraph.phrase),
                                                            paragraph.phraseDocLinks
                                                        )
                                                    }}
                                                />
                                            </Typography>
                                            {i !== paragraphs.length - 1 && (
                                                <Divider className={codeDivider} />
                                            )}
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        ) : (
                            <Typography className={clsx(body_14_regular, phrase, textCenter)}>
                                {`NO ${header.toUpperCase()}`}
                            </Typography>
                        )}
                    </Box>
                </FinalReviewSubpanel>
            </Stack>
        );
    };

    return (
        <>
            <Box className={exceptionsRequirementsPanel}>
                <Typography className={body_large_16_semibold}>
                    EXCEPTIONS/REQUIREMENTS
                </Typography>
                {panel(requirements, 'Requirements')}
                <Divider className={panelDivider} />
                {panel(exceptions, 'Exceptions')}
            </Box>
        </>
    );
};

export default ExceptionsRequirements;
