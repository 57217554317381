import clsx from 'clsx';
import { Box, Stack, Typography } from '@mui/material';
import { textStylesRoboto } from 'styles/common';
import FinalReviewSubpanel from 'pages/Workbench/FinalReview/FinalReviewSubpanel';
import IconButton from 'components/styled/IconButton';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { setAllTaxesExpandOrCollapseState } from 'core/features/examOrderFinalReview/examOrderFinalReviewSlice';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import TaxesTable from 'pages/Workbench/FinalReview/Taxes/TaxesTable';
import DatesTable from 'pages/Workbench/FinalReview/Taxes/DatesTable';
import AssessmentsTable from 'pages/Workbench/FinalReview/Taxes/AssessmentsTable';
import ExceptionsTable from 'pages/Workbench/FinalReview/Taxes/ExceptionsTable';
import InstallmentTable from 'pages/Workbench/FinalReview/Taxes/InstallmentTable';
import DocumentsTable from 'pages/Workbench/FinalReview/Taxes/DocumentsTable';
import styles from './styles';

/**
 * Render taxes panel on final review
 * @component
 * @returns {JSX.Element}
 */
const Taxes = () => {
    const dispatch = useAppDispatch();

    const { areAllTaxesExpanded } = useAppSelector((state) => state.finalReviewData);
    const orderFinalReview = useAppSelector((state) => state.finalReviewData.orderFinalReview);
    const { taxes } = orderFinalReview ?? {};
    const { properties } = useAppSelector(
        (state) => state.currentExamOrderData.currentExamOrder
    );

    const { body_large_16_semibold, body_strong_14_semibold } = textStylesRoboto().classes;
    const { taxesPanel, taxesHeader, subSectionTitle, taxesList, information, taxDetails } =
        styles().classes;

    const expandCollapseAllTaxes = () => {
        dispatch(setAllTaxesExpandOrCollapseState(!areAllTaxesExpanded));
    };

    return (
        <>
            <Box className={taxesPanel}>
                <Box className={taxesHeader}>
                    <Typography className={body_large_16_semibold}> TAXES </Typography>
                    <IconButton
                        withoutBackground
                        onClick={expandCollapseAllTaxes}
                        icon={
                            <Typography className={clsx(body_strong_14_semibold, information)}>
                                {areAllTaxesExpanded ? 'Collapse All' : 'Expand All'}
                            </Typography>
                        }
                    />
                </Box>
                <Stack className={taxesList}>
                    {taxes?.map((tax) => {
                        const targetProperty = properties.find(
                            (property) => property.id === tax.propertyId
                        );
                        return (
                            <FinalReviewSubpanel
                                expanded={areAllTaxesExpanded}
                                header={`${tax.documentType.documentCategoryName} - PIN:${tax.instrumentNumber} | ${targetProperty.commaCountyAddress}`}
                                key={tax.id}>
                                <Box className={taxDetails}>
                                    <Typography
                                        className={clsx(
                                            body_strong_14_semibold,
                                            subSectionTitle
                                        )}>
                                        Documents
                                    </Typography>
                                    <DocumentsTable tax={tax} />
                                    <TaxesTable tax={tax} />
                                    <DatesTable tax={tax} />
                                    <AssessmentsTable tax={tax} />
                                    <ExceptionsTable tax={tax} />
                                    <InstallmentTable tax={tax} />
                                </Box>
                            </FinalReviewSubpanel>
                        );
                    })}
                </Stack>
            </Box>
        </>
    );
};

export default Taxes;
