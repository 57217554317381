import { Box, Grid, Typography } from '@mui/material';
import colors from 'core/constants/colors';
import {
    setCurrentDocFileIndex,
    setCurrentDocSelectValue,
    setCurrentDocumentViewerPanel,
    setModalState
} from 'core/features/documentViewer/documentViewerSlice';
import downloadImage from 'core/helpers/getImageBuffer';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { textStylesRoboto } from 'styles/common';
import { Tax } from 'types/taxes';
import clsx from 'clsx';
import styles from './styles';

/**
 * Render documents table on tax section final review
 * @param {Tax} tax Tax object
 * @component
 * @returns {JSX.Element}
 */
const DocumentsTable = ({ tax }: { tax: Tax }) => {
    const { tableContainer, tableCell, cellLeft, cellRight } = styles().classes;
    const dispatch = useAppDispatch();
    const { subtitle_12_semibold, body_14_regular } = textStylesRoboto().classes;

    const openDocumentImage = async (
        event: React.MouseEvent<HTMLSpanElement>,
        fileIndex: number,
        taxId: string
    ) => {
        if (event.ctrlKey) {
            const res = await downloadImage(null, taxId, null);
            const blob = new Blob([res.buffer], { type: res.type });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        } else {
            dispatch(setCurrentDocumentViewerPanel(null));
            dispatch(setCurrentDocSelectValue(taxId));
            dispatch(setModalState(true));
            dispatch(setCurrentDocFileIndex(fileIndex));
        }
    };
    return (
        <>
            {!tax.files.length && (
                <Box sx={{ marginTop: '13px' }}>
                    <Typography className={body_14_regular}>No Documents</Typography>
                </Box>
            )}
            {!!tax.files.length && (
                <Grid>
                    {tax.files.map((file, index) => {
                        return (
                            <Grid
                                key={file.id}
                                container
                                className={clsx(tableContainer, body_14_regular)}>
                                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                                    <Typography
                                        onClick={(event) =>
                                            openDocumentImage(event, index, tax.id)
                                        }
                                        className={subtitle_12_semibold}
                                        color={colors.additional.information}
                                        sx={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }}>
                                        {file.fileName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                                    {''}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </>
    );
};

export default DocumentsTable;
