import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { OrderActions, SnackbarSeverity } from 'core/constants/common';
import { Action } from 'types/dataModels';

interface ActionsState {
    actions: Action[];
}

const initialState: ActionsState = {
    actions: []
};

const actionsSlice = createSlice({
    name: 'actions',
    initialState,
    reducers: {
        /**
         * Set a list of actions to store
         * @param state Slice state
         * @param action Payload with Business Segments to set
         */
        setActions(state, action: PayloadAction<Action[]>) {
            state.actions = action.payload;
        }
    }
});

export const { setActions } = actionsSlice.actions;

/**
 * Fetch actions and save response data to store
 * @returns {AppThunk}
 */
export const fetchActions = (): AppThunk => async (dispatch) => {
    try {
        // We are returing hard coded actions.
        // Replace below line with API call in case any change in the future
        const response = await Promise.resolve(OrderActions);
        dispatch(setActions(response));
    } catch (err) {
        dispatch(
            setSnackbarState({
                open: true,
                message: `Get actions array: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

export default actionsSlice.reducer;
