import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { textStylesRoboto } from 'styles/common';
import { extractText } from 'core/helpers/extractText';
import { transformToHTML } from 'components/common/TinyEditor/editorUtils';
import styles from './styles';

/**
 * Render vesting description panel on final review
 * @component
 * @returns {JSX.Element}
 */
const Vesting = () => {
    const vesting = useAppSelector((state) => state.finalReviewData.orderFinalReview.vesting);
    const { value, valueDocLinks } = vesting ?? {};
    const { body_large_16_semibold, body_strong_14_semibold, body_14_regular } =
        textStylesRoboto().classes;
    const { vestingPanel, subSectionTitle, vestingBox } = styles().classes;
    return (
        <Box className={vestingPanel}>
            <Typography className={body_large_16_semibold}> VESTING </Typography>
            <Typography className={clsx(body_strong_14_semibold, subSectionTitle)}>
                Vesting Editor
            </Typography>
            <Box className={clsx(vestingBox, body_14_regular)}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: transformToHTML(extractText(value), valueDocLinks, true)
                    }}
                />
            </Box>
        </Box>
    );
};

export default Vesting;
