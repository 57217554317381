import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IStartersAndPriorsGroupProps {
    /**
     * show only tagged documents
     */
    isTaggedOnly: boolean;
    /**
     * show only documents with selected category
     */
    currentCategory: string;
}

export interface ISetStartersAndPriorsGroupPayloadAction {
    /**
     * payload data with filters
     */
    data: IStartersAndPriorsGroupProps;
    /**
     * panel group ID
     */
    keyGroupId: string;
}

export interface IStartersAndPriorsGroupingSlice {
    [key: string]: IStartersAndPriorsGroupProps;
}

const initialState: IStartersAndPriorsGroupingSlice = {};

const startersAndPriorsGroupingSlice = createSlice({
    name: 'startersAndPriorsGrouping',
    initialState,
    reducers: {
        /**
         * Set UI controls data for a particular starters and priors group
         * @param state Slice state
         * @param action Payload with the starters and priors group data to set
         */
        setStartersAndPriorsGroup(
            state: IStartersAndPriorsGroupingSlice,
            action: PayloadAction<ISetStartersAndPriorsGroupPayloadAction>
        ) {
            state[action.payload.keyGroupId] = action.payload.data;
        }
    }
});

export const { setStartersAndPriorsGroup } = startersAndPriorsGroupingSlice.actions;
export default startersAndPriorsGroupingSlice.reducer;
