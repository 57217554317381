import { makeStyles } from 'tss-react/mui';
import colors from 'core/constants/colors';

const styles = makeStyles()(() => ({
    taxesPanel: {
        padding: '20px'
    },
    taxesHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    taxesList: {
        marginTop: '16px'
    },
    subSectionTitle: {
        color: colors.main.accent
    },
    information: {
        color: colors.additional.information
    },
    taxDetails: {
        padding: '16px 10px 10px 10px'
    },
    tableContainer: {
        border: `1px solid ${colors.main.secondaryLight}`,
        borderBottom: '0px',
        marginTop: '10px'
    },
    tableCell: {
        padding: '10px',
        borderBottom: `1px solid ${colors.main.secondaryLight}`
    },
    cellLeft: {
        fontWeight: '500'
    },
    cellRight: {
        borderLeft: `1px solid ${colors.main.secondaryLight}`
    }
}));

export default styles;
