import clsx from 'clsx';
import { Grid, Typography } from '@mui/material';
import { textStylesRoboto } from 'styles/common';
import { format } from 'date-fns';
import { Tax } from 'types/taxes';
import styles from './styles';
interface InstallmentTableProps {
    tax: Tax;
}

/**
 * Render installments table on tax section final review
 * @param {Tax} tax Tax object
 * @component
 * @returns {JSX.Element}
 */
const InstallmentTable = ({ tax }: InstallmentTableProps) => {
    const { body_strong_14_semibold, body_14_regular } = textStylesRoboto().classes;
    const { subSectionTitle, tableContainer, tableCell, cellLeft, cellRight } =
        styles().classes;
    return (
        <>
            <Typography className={clsx(body_strong_14_semibold, subSectionTitle)}>
                Installments
            </Typography>
            {tax.installments.map((installment, i) => {
                return (
                    <Grid key={i} container className={clsx(tableContainer, body_14_regular)}>
                        <Grid item xs={12} className={clsx(tableCell, cellLeft)}>
                            Installment {i + 1}
                        </Grid>
                        <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                            Year
                        </Grid>
                        <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                            {installment.taxYear?.toString()}
                        </Grid>
                        <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                            Base Amount
                        </Grid>
                        <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                            {installment.baseAmount?.toString()}
                        </Grid>
                        <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                            Amount Paid
                        </Grid>
                        <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                            {installment.amountPaid?.toString()}
                        </Grid>
                        <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                            Paid Date
                        </Grid>
                        <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                            {installment.paidDate
                                ? format(new Date(installment.paidDate), 'MM/dd/yyyy')
                                : ''}
                        </Grid>
                        <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                            Amount Due
                        </Grid>
                        <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                            {installment.amountDue?.toString()}
                        </Grid>
                        <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                            Due Date
                        </Grid>
                        <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                            {installment.payDate
                                ? format(new Date(installment.payDate), 'MM/dd/yyyy')
                                : ''}
                        </Grid>
                        <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                            Delinquent Date
                        </Grid>
                        <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                            {installment.delinquentDate
                                ? format(new Date(installment.delinquentDate), 'MM/dd/yyyy')
                                : ''}
                        </Grid>
                        <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                            Status
                        </Grid>
                        <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                            {installment.status}
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};

export default InstallmentTable;
