import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'core/store/store';
import api from 'core/api';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { updateExamOrderSearchReportWarningsData } from 'core/features/examOrderSearchReport/examOrderSearchReportSlice';
import { setCurrentProjectOrder } from 'core/features/currentExamOrder/currentExamOrderSlice';
import { SnackbarSeverity } from 'core/constants/common';
import { FinalReviewDto } from 'types/dto/finalReviewDto';
import { PulseWarning } from 'types/searchReport';

interface FinalReviewState {
    orderFinalReview: FinalReviewDto;
    warnings: PulseWarning[];
    areAllTaxesExpanded: boolean;
}

const initialState: FinalReviewState = {
    orderFinalReview: {} as FinalReviewDto,
    warnings: [],
    areAllTaxesExpanded: false
};

const finalReviewSlice = createSlice({
    name: 'finalReview',
    initialState,
    reducers: {
        /**
         * Set the Final Review data for the current order
         * @param state Slice state
         * @param action Payload with the Final Review data to set
         */
        setFinalReviewData(state, action: PayloadAction<FinalReviewDto>) {
            state.orderFinalReview = action.payload;
        },
        /**
         * Set the Final Review warnings for the current order
         * @param state Slice state
         * @param action Payload with the list of warnings to set
         */
        setFinalReviewWarnings(state, action: PayloadAction<PulseWarning[]>) {
            state.warnings = action.payload;
        },
        /**
         * Set the expanded state of the taxes panels in Final Review section
         * @param state Slice state
         * @param action Payload with expanded value to set
         */
        setAllTaxesExpandOrCollapseState(state, action: PayloadAction<boolean>) {
            state.areAllTaxesExpanded = action.payload;
        }
    }
});

/**
 * Fetch Final Review data for an order
 * @param {string} orderId ID of the order to fetch data for
 * @returns {AppThunk}
 */
export const fetchFinalReviewDataThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderFinalReview.getFinalReviewDataApi(orderId);
            dispatch(setFinalReviewData(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get final review data: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Fetch Final Review warnings for an order
 * @param {string} orderId ID of the order to fetch warnings for
 * @returns {AppThunk}
 */
export const fetchFinalReviewWarningsThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderFinalReview.getFinalReviewWarningsApi(orderId);
            dispatch(setFinalReviewWarnings(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get final review warnings: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Mark a warning in an order Final Review as solved.
 * @param {string} orderId ID of the order
 * @param {string} warningId ID of the Final Review warning to solve
 * @returns {AppThunk}
 */
export const solveFinalReviewWarningThunk =
    (orderId: string, warningId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderFinalReview.solveFinalReviewWarningApi(
                orderId,
                warningId
            );
            dispatch(setFinalReviewWarnings(response));
            dispatch(updateExamOrderSearchReportWarningsData(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Solve warning: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Generate a TSR report for an order
 * @param {string} orderId ID of the order to generate a TSR report for
 * @returns {AppThunk}
 */
export const generateTSRReportThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            return await api.reswareRemitOrder.getTSRReport(orderId);
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Generate TSRI report: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Submit the Final Rewiew for an order
 * @param {string} orderId ID of the order to submit Final Review for
 * @returns {AppThunk}
 */
export const submitFinalReviewThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examOrder.completeOrder(orderId);
            dispatch(setCurrentProjectOrder(result));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Submit order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Changes the setting of the order for whether or not we include hyperlinks.
 * This will either turn on, or turn off hyperlinks when generating the TSR and submitting the TSR.
 * This does not affect anything within the UI on how we show hyperlinks in text
 * @param {string} orderId The id of the order
 * @param {boolean} useImageHyperlinks Whether or not to remove hyperlinks
 * @returns {AppThunk}
 */
export const setHyperlinkStatusForFinalReport =
    (orderId: string, useImageHyperlinks: boolean): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examOrderFinalReview.setImageHyperlinksForOrder(
                orderId,
                useImageHyperlinks
            );
            dispatch(setCurrentProjectOrder(result));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Submit order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export const { setFinalReviewData, setFinalReviewWarnings, setAllTaxesExpandOrCollapseState } =
    finalReviewSlice.actions;

export default finalReviewSlice.reducer;
