import { handleError } from 'core/helpers/errorHandler';
import {
    CategorySchema,
    ConfigDataRow,
    ICategoryModelsData,
    DynamicConfigFilterDto,
    DropdownItems,
    cdResult,
    NewRowEntry,
    ExportDynamicConfigDto,
    ImportWithoutOverwriteResponse
} from 'types/configManager';
import { PagedConfigData } from 'types/dataModels';
import api from '../api';

export const getCategoryModelsApi = async (): Promise<ICategoryModelsData> => {
    try {
        const response = await api.get<ICategoryModelsData>(
            `api/configManager/categoryModel/list`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

export const getCategoryModelApi = async (id: string): Promise<CategorySchema> => {
    if (id === null || id === '') return null;

    try {
        const response = await api.get<CategorySchema>(
            `api/configManager/categoryModel/${id}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

export const getPagedCategoryDefinitionResults = async (
    configFilter: DynamicConfigFilterDto
): Promise<PagedConfigData<ConfigDataRow>> => {
    try {
        const response = await api.post<
            DynamicConfigFilterDto,
            PagedConfigData<ConfigDataRow>
        >(`api/configManager/dynamicConfig/filter/paged`, configFilter);
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

export const getDropdownResults = async (uri: string) => {
    try {
        const response = await api.get<DropdownItems>(uri);
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

export const updateDynamicConfig = async (
    modelId: string,
    configId: string,
    body: cdResult
) => {
    try {
        const response = await api.post<cdResult, ConfigDataRow>(
            `api/configManager/dynamicConfig/${modelId}/update/${configId}`,
            body
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

export const deleteDynamicConfig = async (modelId: string, configId: string) => {
    try {
        const response = await api.delete(
            `api/configManager/dynamicConfig/${modelId}/delete/${configId}`
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

export const addNewDynamicConfig = async (newConfig: NewRowEntry) => {
    try {
        const response = await api.post('api/configManager/dynamicConfig/new', newConfig);
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

export const exportCurrentDynamicConfig = async (filter: ExportDynamicConfigDto) => {
    try {
        const response = await api.post('api/configManager/dynamicConfig/export', filter);
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

export const importDynamicConfig = async (files: File[], overrideDuplicates: boolean) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
    }
    try {
        const response = await api.post<FormData, ImportWithoutOverwriteResponse[]>(
            `api/configManager/dynamicConfig/import?overrideDuplicates=${overrideDuplicates}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};
