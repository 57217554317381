import { makeStyles } from 'tss-react/mui';
import colors from 'core/constants/colors';

const styles = makeStyles()(() => ({
    orderDetailsPanel: {
        padding: '20px'
    },
    tableContainer: {
        border: `1px solid ${colors.main.secondaryLight}`,
        borderBottom: '0px',
        marginTop: '20px'
    },
    tableCell: {
        padding: '10px',
        borderBottom: `1px solid ${colors.main.secondaryLight}`
    },
    cellLeft: {
        fontWeight: '500'
    },
    cellRight: {
        borderLeft: `1px solid ${colors.main.secondaryLight}`
    },
    propertiesSection: {
        marginTop: '20px'
    },
    propertiesPanelDetails: {
        padding: '10px',
        backgroundColor: colors.main.primaryLight
    },
    propertiesTable: {
        borderBottom: '0px'
    },
    propertiesCell: {
        border: `1px solid ${colors.main.secondaryLight}`,
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    propertyAddressData: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
    },
    legalInfoCell: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '30px'
    },
    legalInfoTitle: {
        fontWeight: 700
    },
    subSectionTitle: {
        color: colors.main.accent,
        marginTop: '20px'
    }
}));

export default styles;
