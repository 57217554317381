import { makeStyles } from 'tss-react/mui';
import colors from 'core/constants/colors';

const styles = makeStyles()(() => ({
    exceptionsRequirementsPanel: {
        padding: '20px'
    },
    exceptionsPanelsWrapper: {
        marginTop: '16px'
    },
    subpanelDetails: {
        padding: '10px'
    },
    codesList: {
        padding: '10px',
        border: `1px solid ${colors.main.secondaryLight}`
    },
    subSectionTitle: {
        color: colors.main.accent,
        marginTop: '20px'
    },
    information: {
        color: colors.additional.information
    },
    phrase: {
        marginTop: '6px',
        lineHeight: '24px'
    },
    codeDivider: {
        backgroundColor: colors.main.secondaryLight,
        marginTop: '10px',
        marginBottom: '15px'
    },
    panelDivider: {
        backgroundColor: colors.main.secondaryLight,
        marginTop: '15px',
        marginBottom: '15px'
    },
    textCenter: {
        textAlign: 'center'
    }
}));

export default styles;
