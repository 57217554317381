import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdersListTableSorting } from 'core/constants/common';
import { SelectItem } from 'types/propTypes';

export interface CurrentFiltersState {
    currentFilters: {
        currentStateInFilter: string[];
        currentCountyInFilter: string[];
        currentProductTypeInFilter: string[];
        currentBusinessSegmentInFilter: string[];
        currentActionInFilter: string[];
        currentProjectStatusInFilter: string[];
        currentOrderFilter: string;
        currentClientInFilter: SelectItem;
        search: string;
        sorting: {
            fieldSorting: { name: OrdersListTableSorting; direction: 0 | 1 }[];
        };
        fromDate?: string;
        toDate?: string;
        isRush?: boolean;
    };
}

const initialState: CurrentFiltersState = {
    currentFilters: {
        currentStateInFilter: [],
        currentCountyInFilter: [],
        currentProductTypeInFilter: [],
        currentBusinessSegmentInFilter: [],
        currentActionInFilter: [],
        currentProjectStatusInFilter: [],
        currentOrderFilter: '',
        currentClientInFilter: null,
        search: '',
        sorting: {
            fieldSorting: [{ name: OrdersListTableSorting.orderNumber, direction: 0 }]
        },
        fromDate: '',
        toDate: '',
        isRush: false
    }
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        /**
         * Set current states to filter orders by
         * @param state Slice state
         * @param action Payload with the array of state IDs to set in the filter
         */
        setCurrentStateInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentStateInFilter = action.payload;
        },
        /**
         * Set current counties to filter orders by
         * @param state Slice state
         * @param action Payload with the array of county IDs to set in the filter
         */
        setCurrentCountyInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentCountyInFilter = action.payload;
        },
        /**
         * Set current product types to filter orders by
         * @param state Slice state
         * @param action Payload with the array of product type IDs to set in the filter
         */
        setCurrentProductTypeInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentProductTypeInFilter = action.payload;
        },
        /**
         * Set current business segments to filter orders by
         * @param state Slice state
         * @param action Payload with the array of business segments IDs to set in the filter
         */
        setCurrentBusinessSegmentInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentBusinessSegmentInFilter = action.payload;
        },
        /**
         * Set current action to filter orders by
         * @param state Slice state
         * @param action Payload with the array of action IDs to set in the filter
         */
        setCurrentActionInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentActionInFilter = action.payload;
        },
        /**
         * Set current project statuses to filter orders by
         * @param state Slice state
         * @param action Payload with the array of project status IDs to set in the filter
         */
        setCurrentProjectStatusInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.currentProjectStatusInFilter = action.payload;
        },
        /**
         * Set current order filter to filter orders by
         * @param state Slice state
         * @param action Payload with the order filter string to set in the filter
         */
        setCurrentOrderFilter(state, action: PayloadAction<string>) {
            state.currentFilters.currentOrderFilter = action.payload;
        },
        /**
         * Set current clients to filter orders by
         * @param state Slice state
         * @param action Payload with the array of client IDs to set in the filter
         */
        setCurrentClientInFilter(state, action: PayloadAction<SelectItem>) {
            state.currentFilters.currentClientInFilter = action.payload;
        },
        /**
         * Set from and to date to filter order by date range
         * @param state Slice state
         * @param action Payload with the array of fromDate, toDate to set in the filter
         */
        setDateRangeInFilter(state, action: PayloadAction<string[]>) {
            state.currentFilters.fromDate = action.payload[0];
            state.currentFilters.toDate = action.payload[1];
        },
        /**
         * Clear the filter data by setting it to its initial state
         * @param state Slice state
         */
        clearFilters(state) {
            state.currentFilters = initialState.currentFilters;
        },
        /**
         * Set current search keywords to filter orders by
         * @param state Slice state
         * @param action Payload with the keyword input to filter orders by
         */
        setSearch(state, action: PayloadAction<string>) {
            state.currentFilters.search = action.payload;
        },
        /**
         * Set sorting criteria for orders
         * @param state Slice state
         * @param action Payload with the sorting data to set
         */
        setSorting(state, action: PayloadAction<OrdersListTableSorting>) {
            //change current sorting parameter sorting direction
            if (state.currentFilters.sorting.fieldSorting[0]?.name === action.payload) {
                state.currentFilters.sorting.fieldSorting[0] = {
                    name: action.payload,
                    direction:
                        state.currentFilters.sorting.fieldSorting[0].direction === 0 ? 1 : 0
                };
            } else
                state.currentFilters.sorting.fieldSorting[0] = {
                    name: action.payload,
                    direction: 0
                };
        },
        /**
         * Set current rush order filter - true/false
         * @param state Slice state
         * @param action Payload with rush order status - true/false
         */
        setCurrentRushOrderFilter(state, action: PayloadAction<boolean>) {
            state.currentFilters.isRush = action.payload;
        }
    }
});

export const {
    setCurrentStateInFilter,
    setCurrentCountyInFilter,
    setCurrentClientInFilter,
    setDateRangeInFilter,
    setCurrentProductTypeInFilter,
    setCurrentProjectStatusInFilter,
    setCurrentOrderFilter,
    setCurrentBusinessSegmentInFilter,
    setCurrentActionInFilter,
    clearFilters,
    setSearch,
    setSorting,
    setCurrentRushOrderFilter
} = filtersSlice.actions;
export default filtersSlice.reducer;
