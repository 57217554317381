import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import colors from 'core/constants/colors';

const StyledSwitch = styled(Switch)({
    width: 34,
    height: 16,
    padding: 0,
    marginLeft: 12,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(18px)'
        }
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1.5,
                backgroundColor: colors.main.secondaryDark
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 10%)',
        width: 12,
        height: 12,
        borderRadius: 6
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: colors.neutral.neutralLight,
        boxSizing: 'border-box'
    },
    '&:focus-within': {
        outline: '2px solid #643BC4'
    }
});

const SecondaryStyledSwitch = styled(StyledSwitch)({
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            '& + .MuiSwitch-track': {
                backgroundColor: colors.main.secondaryDark
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 10%)',
        width: 12,
        height: 12,
        borderRadius: 6
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: colors.neutral.neutralMid,
        boxSizing: 'border-box'
    },
    '&:focus-within': {
        outline: '2px solid #643BC4'
    }
});

const SwitchLabel = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
        marginLeft: '8px',
        marginTop: 'auto',
        marginBottom: 'auto',
        minWidth: 'max-content'
    }
});

export interface SwitchProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; //event: React.ChangeEvent<HTMLInputElement>
    variant: 'primary' | 'secondary';
    value: string;
    label?: string;
    checked?: boolean;
    disabled?: boolean;
}

/**
 * Styled switch component
 * @param {Function} onChange onChange handler
 * @param {string} value Switch value
 * @param {string} label Switch label
 * @param {boolean} checked Is checked
 * @param {boolean} disabled Is disabled
 * @constructor
 */
const SwitchMain = ({
    onChange,
    variant,
    value,
    label,
    checked = false,
    disabled
}: SwitchProps) => {
    switch (variant) {
        case 'primary': {
            return (
                <SwitchLabel
                    checked={checked}
                    value={value}
                    control={<StyledSwitch onChange={onChange} disabled={disabled} />}
                    label={label ?? ''}
                    labelPlacement={'end'}
                />
            );
        }
        case 'secondary': {
            return (
                <SwitchLabel
                    checked={checked}
                    value={value}
                    control={<SecondaryStyledSwitch onChange={onChange} disabled={disabled} />}
                    label={label ?? ''}
                    labelPlacement={'end'}
                />
            );
        }
    }
};

export default SwitchMain;
