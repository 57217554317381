import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import {
    SnackbarSeverity,
    SnackbarSuccessMessage,
    ERROR_TYPES,
    PartyRoles
} from 'core/constants/common';
import {
    getAllExceptionsAndRequirementsThunk,
    updateParagraph
} from 'core/features/exceptions/exceptionsSlice';
import { setFileUploadError } from 'core/features/fileDropzone/fileDropzoneSlice';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { handleError } from 'core/helpers/errorHandler';
import { FileUploadError } from 'core/helpers/errors';
import { sortDocuments } from 'core/helpers/sortDocuments';
import { AppThunk } from 'core/store/store';
import {
    CreatePulseDocumentFileDto,
    Directions,
    DocumentCategorySubCategoryDto,
    DocumentCategorySubCategoryResponse,
    DocumentCode,
    DocumentNote,
    DocumentReference,
    ExamOrderKeyDocumentGroupType,
    ExamOrderParentSuccessorDocumentGroupType,
    KeyDocument,
    PulseDocumentFile,
    PulseDocumentPartyField,
    PulseDocumentPartyFieldWithPara,
    SortColumnNames,
    UpdateKeyDocumentInstrument,
    KeyDocumentWithSuccessors
} from 'types/dataModels';
import { v4 as uuidv4 } from 'uuid';
import { OCRStartStatuses } from 'core/constants/common';
import { setCurrentOCRStatus } from 'core/features/ocrData/ocrDataSlice';
import { HeaderNames } from '../../../pages/Workbench/FullScreenSidebar/KeyDocumentsGrouping/PropertySubpanelHeader/constants';
import {
    fetchExamOrderLegalDescriptionData,
    setHasPulseFilesFlagOfLegalData
} from '../examOrderLegalDescription/examOrderLegalDescriptionSlice';
import {
    fetchExamOrderVestingData,
    setHasPulseFilesFlagOfVestingData
} from '../examOrderVesting/examOrderVestingSlice';
import { fetchExamOrderReviewStateThunk } from '../workbenchTabs/workbenchTabsSlice';

interface ExamOrderDocumentGroupState {
    /**
     *exam order document group array
     */
    examOrderDocumentGroupOld: ExamOrderKeyDocumentGroupType[];
    /**
     *exam order parent successtor document group array
     */
    examOrderDocumentGroup: ExamOrderParentSuccessorDocumentGroupType[];
    referredDocument: {
        docId: string;
        groupId: string;
    } | null;
}

const initialState: ExamOrderDocumentGroupState = {
    examOrderDocumentGroupOld: [],
    examOrderDocumentGroup: [],
    referredDocument: null
};

const examOrderKeyDocumentGroupSlice = createSlice({
    name: 'examOrderKeyDocumentGroup',
    initialState,
    reducers: {
        /**
         * Set key document group data from BE to state
         * @param state Slice state
         * @param action Payload with list of document groups to set
         */
        setExamOrderKeyDocumentGroupData(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<ExamOrderKeyDocumentGroupType[]>
        ) {
            state.examOrderDocumentGroupOld = action.payload;
        },
        /**
         * Set parent successtor key document group data from BE to state
         * @param state Slice state
         * @param action Payload with list of document groups to set
         */
        setExamOrderParentSuccessorKeyDocumentGroupData(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<ExamOrderParentSuccessorDocumentGroupType[]>
        ) {
            state.examOrderDocumentGroup = action.payload;
        },
        /**
         * Set the tagged state of a key document
         * @param state Slice state
         * @param action Payload object with the document ID and isTagged value to assign to the document
         */
        setIsDocumentTagged(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; isTagged: boolean }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. isTagged
             *      b. taggedCount
             *      c. unTaggedCount
             */
            const { docId: targetDocId, isTagged } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                let taggedCount = 0;
                let unTaggedCount = 0;
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.isTagged = isTagged;
                    }
                    if (document.isTagged) ++taggedCount;
                    if (!document.isTagged) ++unTaggedCount;

                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.isTagged = isTagged;
                        }
                        if (childDocument.isTagged) ++taggedCount;
                        if (!childDocument.isTagged) ++unTaggedCount;
                    });
                });
                group.taggedCount = taggedCount;
                group.unTaggedCount = unTaggedCount;
            });
        },
        /**
         * Set the tagged state of all key documents in a group
         * @param state Slice state
         * @param action Payload with the ID of the group and the isTagged value to assign to the documents
         */
        setAllDocsIsTagged(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ groupId: string; isTagged: boolean }>
        ) {
            const targetGroup = state.examOrderDocumentGroup.find(
                (group) => group.groupId === action.payload.groupId
            );
            targetGroup.documents.forEach((doc) => (doc.isTagged = action.payload.isTagged));
            targetGroup.taggedCount = targetGroup.documents.reduce((count, doc) => {
                let currentCount = count;
                return doc.isTagged ? ++currentCount : currentCount;
            }, 0);
            targetGroup.unTaggedCount = targetGroup.documents.reduce((count, doc) => {
                let currentCount = count;
                return !doc.isTagged ? ++currentCount : currentCount;
            }, 0);

            targetGroup.documents.forEach((doc1) => {
                state.examOrderDocumentGroup.forEach((group) => {
                    if (group.groupId != action.payload.groupId) {
                        group.documents.forEach((doc2) => {
                            if (doc1.id == doc2.id) {
                                doc2.isTagged = action.payload.isTagged;
                                group.taggedCount = group.documents.reduce((count, doc) => {
                                    let currentCount = count;
                                    return doc.isTagged ? ++currentCount : currentCount;
                                }, 0);
                                group.unTaggedCount = group.documents.reduce((count, doc) => {
                                    let currentCount = count;
                                    return !doc.isTagged ? ++currentCount : currentCount;
                                }, 0);
                            }
                        });
                    }
                });
            });
        },
        /**
         * Set the untagged state of all child documents of a parent document
         * @param state Slice state
         * @param action Payload with the ID of the group, documentIds, and the isTagged value to assign to the documents
         */
        setChildDocsIsUnTagged(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                groupId: string;
                documentIds: string[];
                isTagged: boolean;
            }>
        ) {
            const { documentIds, isTagged } = action.payload;

            state.examOrderDocumentGroup.forEach((group) => {
                // This change is applicable only to the current group.
                let taggedCount = 0;
                let unTaggedCount = 0;
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (documentIds.find((docId) => docId === document.id)) {
                        document.isTagged = isTagged;
                    }
                    if (document.isTagged) ++taggedCount;
                    if (!document.isTagged) ++unTaggedCount;

                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (documentIds.find((docId) => docId === childDocument.id)) {
                            childDocument.isTagged = isTagged;
                        }
                        if (childDocument.isTagged) ++taggedCount;
                        if (!childDocument.isTagged) ++unTaggedCount;
                    });
                });
                group.taggedCount = taggedCount;
                group.unTaggedCount = unTaggedCount;
            });
        },
        /**
         * Set the tagged state of all files attached to a key document
         * @param state Slice state
         * @param action Payload with the ID of the document and the isTagged value to assign to the files
         */
        setDocumentFilesIsTagged(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; isTagged: boolean }>
        ) {
            const targetGroupAr = state.examOrderDocumentGroup.filter((group) =>
                group.documents.map((doc) => doc.id).includes(action.payload.docId)
            );
            const targetDocument: Array<KeyDocument> = [];
            targetGroupAr.forEach((targetGroup) => {
                targetDocument.push(
                    targetGroup.documents.find((doc) => doc.id === action.payload.docId)
                );
            });
            targetDocument.forEach((item) => {
                item.files.forEach((file) => (file.isTagged = action.payload.isTagged));
            });
        },
        /**
         * Set the tagged state of all files attached to a key document
         * @param state Slice state
         * @param action Payload with the ID of the document and the includeAttachments value to assign to the files
         */
        setDocumentIncludeAttached(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; includeAttachments: boolean }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. includeAttachments
             */
            const { docId: targetDocId, includeAttachments } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.includeAttachments = includeAttachments;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.includeAttachments = includeAttachments;
                        }
                    });
                });
            });
        },
        /**
         * Add a party to a key document
         * @param state Slice state
         * @param action Payload with the group ID, document ID and the new party to add
         */
        addParty(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                groupId: string;
                docId: string;
                newParty: PulseDocumentPartyField;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. newParty
             */
            const { docId: targetDocId, newParty } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        if (document.parties?.length) {
                            document.parties?.push(newParty);
                        } else {
                            document.parties = [newParty];
                        }
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            if (childDocument.parties?.length) {
                                childDocument.parties?.push(newParty);
                            } else {
                                childDocument.parties = [newParty];
                            }
                        }
                    });
                });
            });
        },
        /**
         * Update a party in an existing key document
         * @param state Slice state
         * @param action Payload with the group ID, document ID, party ID and updated party name
         */
        updateParty(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                groupId: string;
                docId: string;
                partyId: string;
                value: PulseDocumentPartyFieldWithPara;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. partyId
             *      b. value
             */
            const { docId: targetDocId, partyId, value } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const targetPartyIndex = document.parties.findIndex(
                            (party) => party.id === partyId
                        );
                        if (
                            //TODO:
                            document.parties?.length
                        ) {
                            document.parties[targetPartyIndex] = value.field;
                        } else {
                            document.parties = [value.field];
                        }
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const targetPartyIndex = childDocument.parties.findIndex(
                                (party) => party.id === partyId
                            );
                            if (
                                // TODO:
                                childDocument.parties?.length
                            ) {
                                childDocument.parties[targetPartyIndex] = value.field;
                            } else {
                                childDocument.parties = [value.field];
                            }
                        }
                    });
                });
            });
        },
        /**
         * Delete a party in an existing key document
         * @param state Slice state
         * @param action Payload with the group ID, document ID and party ID to delete
         */
        deleteParty(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ groupId: string; docId: string; partyId: string }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. partyId
             */
            const { docId: targetDocId, partyId } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const targetPartyIndex = document.parties.findIndex(
                            (party) => party.id === partyId
                        );
                        if (targetPartyIndex > -1 && document.parties?.length) {
                            document.parties.splice(targetPartyIndex, 1);
                        }
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const targetPartyIndex = childDocument.parties.findIndex(
                                (party) => party.id === partyId
                            );
                            if (targetPartyIndex > -1 && childDocument.parties?.length) {
                                childDocument.parties.splice(targetPartyIndex, 1);
                            }
                        }
                    });
                });
            });
        },
        /**
         * Update the instrument identifiers of a key document
         * @param state Slice state
         * @param action Payload with the document ID and updated values object
         */
        updateInstrumentComplexInput(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                newValue: UpdateKeyDocumentInstrument;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. instrumentYear
             *      b. instrumentNumber
             *      c. bookType
             *      d. liber
             *      e. page
             *      f. documentNumber
             */
            const { docId: targetDocId, newValue } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.instrumentYear = newValue.instrumentYear;
                        document.instrumentNumber = newValue.instrumentNumber;
                        document.bookType = newValue.bookType;
                        document.liber = newValue.liber;
                        document.page = newValue.page;
                        document.documentNumber = newValue.documentNumber;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.instrumentYear = newValue.instrumentYear;
                            childDocument.instrumentNumber = newValue.instrumentNumber;
                            childDocument.bookType = newValue.bookType;
                            childDocument.liber = newValue.liber;
                            childDocument.page = newValue.page;
                            childDocument.documentNumber = newValue.documentNumber;
                        }
                    });
                });
            });
        },
        /**
         * Update the recorded date of a key document
         * @param state Slice state
         * @param action Payload with the group ID, document ID and updated ISO date string
         */
        updateRecordedDate(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                groupId: string;
                docId: string;
                newDate: string;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. recordedDate
             */
            const { docId: targetDocId, newDate } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.recordedDate = newDate;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.recordedDate = newDate;
                        }
                    });
                });
            });
        },
        /**
         * Untag the image of a key document
         * @param state Slice state
         * @param action Payload with the document ID to delete the image from
         */
        deleteDocumentImage(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. hasPulseFiles
             *      b. files
             *      c. isOcr
             *      d. includeAttachments
             */
            const { docId: targetDocId } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.hasPulseFiles = false;
                        document.files = [];
                        document.isOcr = false;
                        document.includeAttachments = false;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.hasPulseFiles = false;
                            childDocument.files = [];
                            childDocument.isOcr = false;
                            childDocument.includeAttachments = false;
                        }
                    });
                });
            });
        },
        /**
         * Add references to a key document
         * @param state Slice state
         * @param action Payload with the document ID and references array to add
         */
        addDocumentReferences(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                references: DocumentReference[];
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. documentReferences
             */
            const { docId: targetDocId, references } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.documentReferences = [...references];
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.documentReferences = [...references];
                        }
                    });
                });
            });
        },
        /**
         * set refered documents
         * @param state Slice state
         * @param action Payload with the document ID
         */
        setReferredDocuments(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<string>
        ) {
            const targetGroup = state.examOrderDocumentGroup.find((group) =>
                group.documents.some((doc) => doc.id === action.payload)
            );
            state.referredDocument = {
                docId: action.payload,
                groupId: targetGroup?.groupId
            };
        },
        /**
         * Delete a reference in a key document
         * @param state Slice state
         * @param action Payload with the document ID and ID of the reference to delete
         */
        deleteDocumentReference(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                refId: string;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. Find document reference index
             *      b. and remove
             */
            const { docId: targetDocId, refId } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const targetReferenceIndex = document.documentReferences.findIndex(
                            (ref) => ref.documentReferenceId === refId
                        );
                        document.documentReferences.splice(targetReferenceIndex, 1);
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const targetReferenceIndex =
                                childDocument.documentReferences.findIndex(
                                    (ref) => ref.documentReferenceId === refId
                                );
                            childDocument.documentReferences.splice(targetReferenceIndex, 1);
                        }
                    });
                });
            });
        },
        /**
         * Add codes to a key document
         * @param state Slice state
         * @param action Payload with the document ID and array of codes to add
         */
        addDocumentCodes(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                codes: DocumentCode[];
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. codes
             */
            const { docId: targetDocId, codes } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.codes = [...codes];
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.codes = [...codes];
                        }
                    });
                });
            });
        },
        /**
         * Remove a code from a key document
         * @param state Slice state
         * @param action Payload with the document ID and ID of the code to remove
         */
        removeDocumentCode(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                paragraphId: string;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. Find code index
             *      b. and remove
             */
            const { docId: targetDocId, paragraphId } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const targetCode = document.codes?.findIndex(
                            (el) => el.id === paragraphId
                        );
                        document.codes.splice(targetCode, 1);
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const targetCode = childDocument.codes?.findIndex(
                                (el) => el.id === paragraphId
                            );
                            childDocument.codes.splice(targetCode, 1);
                        }
                    });
                });
            });
        },
        /**
         * Update a file in a key document
         * @param state Slice state
         * @param action Payload with the document ID and the new file to set
         */
        updateDocumentFile(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; files: PulseDocumentFile[] }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. file
             */
            const { docId: targetDocId, files } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.files = files;
                        document.hasPulseFiles = true;
                        document.includeAttachments = true;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.files = files;
                            childDocument.hasPulseFiles = true;
                            document.includeAttachments = true;
                        }
                    });
                });
            });
        },
        /**
         * Update the note in a key document
         * @param state Slice state
         * @param action Payload with the document ID and the new note to set
         */
        updateDocumentNote(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; documentNote: DocumentNote }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. notes
             */
            const { docId: targetDocId, documentNote } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const noteToBeUpdated = document.notes.findIndex(
                            (note) => note.id === documentNote.id
                        );
                        if (noteToBeUpdated !== -1) {
                            document.notes[noteToBeUpdated] = documentNote;
                        } else {
                            document.notes.push(documentNote);
                        }
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const noteToBeUpdated = childDocument.notes.findIndex(
                                (note) => note.id === documentNote.id
                            );
                            if (noteToBeUpdated !== -1) {
                                childDocument.notes[noteToBeUpdated] = documentNote;
                            } else {
                                childDocument.notes.push(documentNote);
                            }
                        }
                    });
                });
            });
        },
        /**
         * Remove a note in a key document
         * @param state Slice state
         * @param action Payload with the document ID and ID of the note to remove
         */
        removeDocumentNote(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; noteId: string }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. Find note index
             *      b. and remove
             */
            const { docId: targetDocId, noteId } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        const targetNoteIndex = document.notes.findIndex(
                            (note) => note.id === noteId
                        );
                        document.notes.splice(targetNoteIndex, 1);
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            const targetNoteIndex = childDocument.notes.findIndex(
                                (note) => note.id === noteId
                            );
                            childDocument.notes.splice(targetNoteIndex, 1);
                        }
                    });
                });
            });
        },
        /**
         * Update the amount field in a key document
         * @param state Slice state
         * @param action Payload with the document ID and the new amount to set
         */
        updateAmount(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; amount: number }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. amount
             */
            const { docId: targetDocId, amount } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.amount = amount;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.amount = amount;
                        }
                    });
                });
            });
        },
        /**
         * Update the transfer tax amount field in a key document
         * @param state Slice state
         * @param action Payload with the document ID and the new amount to set
         */
        updateTransferTaxAmount(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{ docId: string; amount: number }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. transferTaxAmount
             */
            const { docId: targetDocId, amount } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.transferTaxAmount = amount;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.transferTaxAmount = amount;
                        }
                    });
                });
            });
        },
        /**
         * Updates DocumentType data of document on category/subcategory change
         * @param state Slice state
         * @param action Payload with the document ID and DocumentType response
         */
        updateCategorySubCategoryOfDoc(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                docId: string;
                docTypeResponse: DocumentCategorySubCategoryResponse;
            }>
        ) {
            /**
             * 1. Find all the group in which the docId is present
             *      a. This include root, nested children
             * 2. Once you have the groups, loop through each, and
             *      a. Update root, children document if it is the target document
             * 3. Update includes
             *      a. DocumentType object
             */
            const { docId: targetDocId, docTypeResponse: documentType } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.filter(Boolean)?.forEach((document) => {
                    if (document.id === targetDocId) {
                        document.documentType = documentType;
                    }
                    document?.childDocuments?.filter(Boolean)?.forEach((childDocument) => {
                        if (childDocument.id === targetDocId) {
                            childDocument.documentType = documentType;
                        }
                    });
                });
            });
        },
        /**
         * Updates isRowHighlighted data of each row
         * @param state Slice state
         * @param action Payload with tab index and isRowHighlighted
         */
        setIsRowHighlighted(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                tabIndex: number;
                isRowHighlighted: boolean;
            }>
        ) {
            const { tabIndex: targetTabIndex, isRowHighlighted } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.forEach((document) => {
                    // If the document's tabIndex matches the targetTabIndex, set its isRowHighlighted to the action payload value,
                    // otherwise set it to false
                    document.isRowHighlighted =
                        document.tabIndex === targetTabIndex ? isRowHighlighted : false;

                    // If the document has childDocuments, iterate through them and do the same
                    document?.childDocuments?.forEach((childDocument) => {
                        childDocument.isRowHighlighted =
                            childDocument.tabIndex === targetTabIndex
                                ? isRowHighlighted
                                : false;
                    });
                });
            });
        },
        /**
         * Updates isScroll data of each row
         * @param state Slice state
         * @param action Payload with id and isScroll
         */
        setIsScroll(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                id: string;
                isScroll: boolean;
            }>
        ) {
            const { id, isScroll } = action.payload;
            state.examOrderDocumentGroup.forEach((group) => {
                group.documents?.forEach((document) => {
                    // If the document's tabIndex matches the targetTabIndex, set its isRowHighlighted to the action payload value,
                    // otherwise set it to false
                    document.isScroll = document.id === id ? isScroll : false;

                    // If the document has childDocuments, iterate through them and do the same
                    document?.childDocuments?.forEach((childDocument) => {
                        childDocument.isScroll = childDocument.id === id ? isScroll : false;
                    });
                });
            });
        },
        /**
         * Updates currentSortingColumn and sortingDirections data of each group
         * @param state Slice state
         * @param action Payload with groupId, currentSortingColumn and sortingDirections
         */
        setSorting(
            state: ExamOrderDocumentGroupState,
            action: PayloadAction<{
                groupId: string;
                currentSortingColumn: SortColumnNames;
                sortingDirections: Directions;
            }>
        ) {
            const { groupId, currentSortingColumn, sortingDirections } = action.payload;
            const groupToUpdateIndex = state.examOrderDocumentGroup.findIndex(
                (group) => group.groupId === groupId
            );
            if (groupToUpdateIndex !== -1) {
                state.examOrderDocumentGroup[groupToUpdateIndex].currentSortingColumn =
                    currentSortingColumn;
                state.examOrderDocumentGroup[groupToUpdateIndex].sortingDirections =
                    sortingDirections;
            }
        }
    }
});

export const {
    setExamOrderKeyDocumentGroupData,
    setExamOrderParentSuccessorKeyDocumentGroupData,
    setIsDocumentTagged,
    setAllDocsIsTagged,
    setChildDocsIsUnTagged,
    setDocumentFilesIsTagged,
    setDocumentIncludeAttached,
    addParty,
    updateParty,
    deleteParty,
    updateInstrumentComplexInput,
    updateRecordedDate,
    deleteDocumentImage,
    addDocumentReferences,
    setReferredDocuments,
    addDocumentCodes,
    removeDocumentCode,
    updateDocumentFile,
    updateDocumentNote,
    removeDocumentNote,
    updateAmount,
    updateTransferTaxAmount,
    deleteDocumentReference,
    updateCategorySubCategoryOfDoc,
    setIsRowHighlighted,
    setIsScroll,
    setSorting
} = examOrderKeyDocumentGroupSlice.actions;

/**
 * Fetch exam order document group data from BE
 * @param {string} orderId
 * @returns {AppThunk}
 */
export const fetchExamOrderDocumentGroupData =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response =
                await api.examOrderDocumentGroup.getExamOrderDocumentGroup(orderId);
            dispatch(setExamOrderKeyDocumentGroupData(response));
        } catch (err) {
            dispatch(setExamOrderKeyDocumentGroupData([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get key documents groups: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Fetch exam order parent successors document group data from BE
 * @param {string} orderId
 * @returns {AppThunk}
 */
export const fetchExamOrderParentSuccessorsDocumentGroupData =
    (orderId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { examOrderKeyDocumentGroupData } = getState();
            const response =
                await api.examOrderDocumentGroup.getExamOrderParentSuccessorDocumentGroup(
                    orderId
                );

            let updatedResponse = response?.map((group) => {
                const oldGroup = examOrderKeyDocumentGroupData.examOrderDocumentGroup.find(
                    ({ groupId }) => group.groupId === groupId
                );
                const currentSortingColumn =
                    oldGroup?.currentSortingColumn || HeaderNames.Date;
                const sortingDirections = oldGroup?.sortingDirections || {
                    [HeaderNames.Date]: 'asc',
                    [HeaderNames.Description]: 'desc'
                };
                const updatedDocuments = group.documents?.filter(Boolean)?.map((document) => {
                    return {
                        ...document,
                        childDocuments: document?.childDocuments?.filter(Boolean)
                    };
                });

                const sortedDocuments = sortDocuments(
                    updatedDocuments,
                    currentSortingColumn,
                    sortingDirections
                );

                return {
                    ...group,
                    documents: sortedDocuments,
                    currentSortingColumn,
                    sortingDirections
                };
            });

            /**
             *  Post processing on documents
             *      a. Remove null and undefined children
             *      b. Add unique number to each document which will be helpful:
             *          1. tab index focus
             *          2. parent-successtor dash relation line
             **/
            let tabIndex = 0;
            updatedResponse = updatedResponse?.map((group) => {
                return {
                    ...group,
                    documents: group.documents?.map((document) => {
                        return {
                            ...document,
                            tabIndex: ++tabIndex,
                            childDocuments: document?.childDocuments?.map((childDocument) => {
                                return {
                                    ...childDocument,
                                    tabIndex: ++tabIndex
                                };
                            })
                        };
                    })
                };
            });

            dispatch(setExamOrderParentSuccessorKeyDocumentGroupData(updatedResponse));
        } catch (err) {
            dispatch(setExamOrderParentSuccessorKeyDocumentGroupData([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get Parent successor key documents groups: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a party entity to an existing document
 * @param {string} orderId ID of the order
 * @param {string} groupId ID of the group where the document is included
 * @param {string} docId ID of the document to add a party to
 * @param {boolean} isGrantee Flag that describes if the party is a Grantee or Grantor
 * @param {PartyRoles} partyRole Role of the new party
 * @returns {AppThunk}
 */
export const addDocumentPartyThunk =
    (
        orderId: string,
        groupId: string,
        docId: string,
        isGrantee: boolean,
        partyRole: PartyRoles
    ): AppThunk =>
    async (dispatch) => {
        const newPartyObj: PulseDocumentPartyField = {
            id: uuidv4(),
            fieldNameId: uuidv4(),
            isGrantee,
            role: partyRole,
            first: '',
            middle: '',
            last: '',
            businessName: '',
            displayValue: ''
        };
        try {
            const response = await api.examOrderDocumentFields.addDocumentPartyApi(
                orderId,
                docId,
                newPartyObj
            );
            await dispatch(addParty({ groupId, docId, newParty: response.field }));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add document party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a party entity from an existing document
 * @param {string} orderId ID of the order
 * @param {string} groupId ID of the group where the document is included
 * @param {string} docId ID of the document to remove a party from
 * @param {string} partyId ID of the party to remove
 * @returns {AppThunk}
 */
export const deleteDocumentPartyThunk =
    (orderId: string, groupId: string, docId: string, partyId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examOrderDocumentFields.deleteDocumentPartyApi(orderId, docId, partyId);
            await dispatch(deleteParty({ groupId, docId, partyId }));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove document party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the value of a party in an existing document
 * @param {string} orderId ID of the order
 * @param {string} groupId ID of the group where the document is included
 * @param {string} docId ID of the document that owns the party
 * @param {string} partyId ID of the party to update
 * @param {string} value New value for the party
 * @returns {AppThunk}
 */
export const updateDocumentPartyThunk =
    (
        orderId: string,
        groupId: string,
        docId: string,
        partyId: string,
        value: string
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response =
                await api.examOrderDocumentFields.updateDocumentPartyWithParagraphsApi(
                    orderId,
                    docId,
                    partyId,
                    value
                );
            await dispatch(updateParty({ groupId, docId, partyId, value: response }));
            if (response.paragraphs.length > 0) {
                response.paragraphs.forEach((para) => {
                    dispatch(updateParagraph({ id: para.id, paragraph: para }));
                });
            }
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update document party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the recorded date of an existing document,
 * this includes updated paragraphs that may be effected by an update to this field
 * @param {string} orderId ID of the order
 * @param {string} groupId ID of the group where the document is included
 * @param {string} docId ID of the document
 * @param {string} newDate ISO date string that represents the new recorded date of the document
 * @returns {AppThunk}
 */
export const updateRecordedDateThunk =
    (orderId: string, groupId: string, docId: string, newDate: string): AppThunk =>
    async (dispatch) => {
        try {
            const response =
                await api.examOrderDocumentFields.updateDocumentRecordedDateWithParagraphsApi(
                    orderId,
                    docId,
                    newDate
                );
            dispatch(updateRecordedDate({ groupId, docId, newDate }));
            if (response.paragraphs.length > 0) {
                response.paragraphs.forEach((para) => {
                    dispatch(updateParagraph({ id: para.id, paragraph: para }));
                });
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update recorded date: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a document reference to an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to add a reference to
 * @param {string} referenceToDocumentId ID of the reference
 * @param {string} instrumentNumber Instrument number of the referenced document
 * @param {number} instrumentYear Instrument year of the referenced document
 * @param {string} liber Liber of the referenced document
 * @param {string} page Page of the referenced document
 * @returns {AppThunk}
 */
export const addOrderDocumentReferenceThunk =
    (
        orderId: string,
        documentId: string,
        referenceToDocumentId: string,
        instrumentNumber: string,
        instrumentYear: number,
        liber: string,
        page: string
    ): AppThunk =>
    async (dispatch) => {
        try {
            await api.examOrderReferences.addExamOrderDocumentReference(orderId, documentId, {
                referenceToDocumentId,
                instrumentNumber,
                instrumentYear: Number(instrumentYear) || null,
                liber,
                page
            });
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            // After two way references, it is complicated to manually update the store.
            // But due to above key document get api call references will update after loading is completed.
            // dispatch(addDocumentReferences({ docId: documentId, references: newReferences }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add document reference: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a document reference from an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to remove a reference from
 * @param {string} documentReferenceId ID of the reference to remove
 * @returns {AppThunk}
 */
export const removeOrderDocumentReferenceThunk =
    (orderId: string, documentId: string, documentReferenceId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examOrderReferences.deleteExamOrderDocumentReference(
                orderId,
                documentId,
                documentReferenceId
            );
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            dispatch(
                deleteDocumentReference({ docId: documentId, refId: documentReferenceId })
            );
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove document reference: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the instrument identifiers object for an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to update
 * @param {UpdateKeyDocumentInstrument} instrumentObject Object with the updated instrument identifiers
 * @returns {AppThunk}
 */
export const updateInstrumentComplexInputThunk =
    (
        orderId: string,
        documentId: string,
        instrumentObject: UpdateKeyDocumentInstrument
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response =
                await api.examOrderDocumentFields.updateDocumentInstrumentWithParagraphsApi(
                    orderId,
                    documentId,
                    instrumentObject
                );
            dispatch(
                updateInstrumentComplexInput({ docId: documentId, newValue: instrumentObject })
            );
            if (response.paragraphs.length > 0) {
                response.paragraphs.forEach((para) => {
                    dispatch(updateParagraph({ id: para.id, paragraph: para }));
                });
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update instrument complex input: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add an array of legal codes to an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to add codes to
 * @param {number[]} codesArray Array of code IDs to add to the document
 * @returns {AppThunk}
 */
export const addOrderDocumentCodeThunk =
    (orderId: string, documentId: string, codesArray: string[]): AppThunk =>
    async (dispatch) => {
        try {
            const addedCodes = await api.examOrderDocumentFields.addDocumentCodes(
                orderId,
                documentId,
                codesArray
            );
            dispatch(addDocumentCodes({ docId: documentId, codes: addedCodes }));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add Document Code: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * removes an legal code from an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to add codes to
 * @param {number[]} codeId Code Id to be removed
 * @returns {AppThunk}
 */
export const removeOrderKeyDocumentCodeThunk =
    (orderId: string, documentId: string, codeId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { examOrderDocumentGroup } = getState().examOrderKeyDocumentGroupData;

            // A helper function to process documents and their child documents recursively
            const processDocuments = async (
                documents: KeyDocumentWithSuccessors[]
            ): Promise<void> => {
                for (const document of documents) {
                    if (document.id === documentId) {
                        const targetCodeIndex = document.codes.findIndex(
                            (code) => code.id === codeId
                        );
                        if (targetCodeIndex !== -1) {
                            const codesArrayCopy = [...document.codes];
                            codesArrayCopy.splice(targetCodeIndex, 1);
                            const updatedCodes = codesArrayCopy.map((code) => code.id);

                            const addedCodes =
                                await api.examOrderDocumentFields.setDocumentCodes(
                                    orderId,
                                    documentId,
                                    updatedCodes
                                );
                            dispatch(
                                addDocumentCodes({ docId: documentId, codes: addedCodes })
                            );
                            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
                        }
                    }

                    if (document.childDocuments && document.childDocuments.length > 0) {
                        await processDocuments(document.childDocuments);
                    }
                }
            };

            for (const group of examOrderDocumentGroup) {
                await processDocuments(group.documents);
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove Document Code: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a new key document files to an order
 * @param {string} orderId ID of the order
 * @param {File[]} files
 * @returns {CreatePulseDocumentFileDto[]}
 */
export const uploadNewKeyDocumentFiles = async (
    orderId: string,
    files: File[]
): Promise<CreatePulseDocumentFileDto[]> => {
    try {
        return await api.examBlobDocumentFile.uploadImage(orderId, files, true);
    } catch (err) {
        handleError(err, ERROR_TYPES.fileUpload);
    }
};

/**
 * update existing key document to an order and override files
 * @param {string} orderId ID of the order
 * @param {Function} handleCancel Clear and close the form
 * @param {boolean} ocrOnUpload Should OCR document on upload
 * @returns {AppThunk}
 */
export const updateAndOverrideExistingKeyDocumentThunk =
    (orderId: string, handleCancel: () => void, ocrOnUpload: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { formData, document } = getState().uploadKeyDocumentFormData;
            const propertyId =
                getState().currentExamOrderData.currentExamOrder.properties?.[0]?.id;
            const files = getState().fileDropzoneData.files;
            const currentOcrStatus = getState().ocrData.currentOCRStatus;

            await api.examUploadDocument.updateAndOverrideExistingRecordedDocument(
                formData,
                files,
                orderId,
                document,
                propertyId,
                formData.category.id,
                formData.subCategory.id
            );
            await dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            if (ocrOnUpload) {
                dispatch(
                    setCurrentOCRStatus({
                        ...currentOcrStatus,
                        id: document.id,
                        status: OCRStartStatuses.initiated
                    })
                );
                dispatch(
                    setSnackbarState({
                        open: true,
                        message:
                            SnackbarSuccessMessage.OCRStartedOnUpdatedDoc +
                            ` on ${document.examinerDescriptionLink}`,
                        severity: SnackbarSeverity.Info
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: SnackbarSuccessMessage.UpdateDocumentSuccess,
                        severity: SnackbarSeverity.Success
                    })
                );
            }
            handleCancel();
        } catch (err) {
            if (err instanceof FileUploadError) {
                dispatch(
                    setFileUploadError({
                        error: true,
                        message: err.message
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Add Key Document: ${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        }
    };

/**
 * update an existing key document to an order and append files
 * @param {string} orderId ID of the order
 * @param {Function} handleCancel Clear and close the form
 * @param {boolean} ocrOnUpload Should OCR document on upload
 * @returns {AppThunk}
 */
export const updateAndAppendExistingKeyDocumentThunk =
    (orderId: string, handleCancel: () => void, ocrOnUpload: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { formData, document } = getState().uploadKeyDocumentFormData;
            const propertyId =
                getState().currentExamOrderData.currentExamOrder.properties?.[0]?.id;
            const files = getState().fileDropzoneData.files;
            const currentOcrStatus = getState().ocrData.currentOCRStatus;

            await api.examUploadDocument.updateAndAppendExistingRecordedDocument(
                formData,
                files,
                orderId,
                document,
                propertyId,
                formData.category.id,
                formData.subCategory.id
            );
            await dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            if (ocrOnUpload) {
                dispatch(
                    setCurrentOCRStatus({
                        ...currentOcrStatus,
                        id: document.id,
                        status: OCRStartStatuses.initiated
                    })
                );
                dispatch(
                    setSnackbarState({
                        open: true,
                        message:
                            SnackbarSuccessMessage.OCRStartedOnUpdatedDoc +
                            ` on ${document.examinerDescriptionLink}`,
                        severity: SnackbarSeverity.Info
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: SnackbarSuccessMessage.UpdateDocumentSuccess,
                        severity: SnackbarSeverity.Success
                    })
                );
            }
            handleCancel();
        } catch (err) {
            if (err instanceof FileUploadError) {
                dispatch(
                    setFileUploadError({
                        error: true,
                        message: err.message
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Add Key Document: ${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        }
    };

/**
 * Add a new key document to an order
 * @param {string} orderId ID of the order
 * @param {Function} cleanupFn callback fn to (Clear and close the form) || (Clear the form)
 * @param {HTMLInputElement} ref
 * @param {boolean} shouldOCRToggle Should we OCR the doc on upload
 * @returns {AppThunk}
 */
export const addNewKeyDocumentThunk =
    (
        orderId: string,
        cleanupFn?: () => void,
        ref?: HTMLInputElement | HTMLSelectElement,
        shouldOCRToggle?: boolean
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { formData } = getState().uploadKeyDocumentFormData;
            const propertyId =
                getState().currentExamOrderData.currentExamOrder.properties?.[0]?.id;
            const files = getState().fileDropzoneData.files;
            const currentOcrStatus = getState().ocrData.currentOCRStatus;

            let uploadedFiles: CreatePulseDocumentFileDto[] = [];
            if (files?.length) {
                uploadedFiles = await uploadNewKeyDocumentFiles(orderId, files);
            }
            const doc = await api.examUploadDocument.uploadRecordedDocumentWithNoImage(
                orderId,
                formData,
                uploadedFiles,
                propertyId
            );
            dispatch(fetchExamOrderDocumentGroupData(orderId));
            await dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            dispatch(fetchExamOrderVestingData(orderId));
            dispatch(fetchExamOrderLegalDescriptionData(orderId));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            if (typeof cleanupFn === 'function') cleanupFn();
            if (shouldOCRToggle) {
                dispatch(
                    setCurrentOCRStatus({
                        ...currentOcrStatus,
                        id: doc.id,
                        status: OCRStartStatuses.initiated
                    })
                );
                dispatch(
                    setSnackbarState({
                        open: true,
                        message:
                            SnackbarSuccessMessage.OCRStartedOnCompleteDoc +
                            ` on ${doc.examinerDescriptionLink}`,
                        severity: SnackbarSeverity.Info
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: SnackbarSuccessMessage.AddDocumentSuccess,
                        severity: SnackbarSeverity.Success
                    })
                );
            }
            if (ref) ref.focus();
        } catch (err) {
            if (err instanceof FileUploadError) {
                dispatch(
                    setFileUploadError({
                        error: true,
                        message: err.message
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Add Key Document: ${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        }
    };

/**
 * Change the tagged state of all documents in a document group
 * @param {string} orderId ID of the order
 * @param {string[]} documentIds IDs of the documents to update
 * @param {boolean} isTagged Tagged value to assign to the documents
 * @param {string} groupId ID of the group containing the documents
 * @returns {AppThunk}
 */
export const setAllDocumentsTagUntagThunk =
    (orderId: string, documentIds: string[], isTagged: boolean, groupId: string): AppThunk =>
    async (dispatch) => {
        try {
            const {
                isTagged: isTaggedResponse,
                affectedVestingIds,
                affectedLegalDescriptionIds,
                isReviewStatedChanged
            } = await api.documentTagUntag.apiSetAllDocsIsTagged(
                orderId,
                documentIds,
                isTagged
            );
            if (isReviewStatedChanged) dispatch(fetchExamOrderReviewStateThunk(orderId));
            if (affectedVestingIds.length) dispatch(fetchExamOrderVestingData(orderId));
            if (affectedLegalDescriptionIds.length)
                dispatch(fetchExamOrderLegalDescriptionData(orderId));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            dispatch(setAllDocsIsTagged({ groupId: groupId, isTagged: isTaggedResponse }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Set all documents in group tag or untag: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Change the tagged state of child document of a parent document
 * @param {string} orderId ID of the order
 * @param {string[]} documentIds IDs of the documents to update
 * @param {boolean} isTagged Tagged value to assign to the documents
 * @param {string} groupId ID of the group containing the documents
 * @returns {AppThunk}
 */
export const setChildDocumentsTagUntagThunk =
    (orderId: string, documentIds: string[], isTagged: boolean, groupId: string): AppThunk =>
    async (dispatch) => {
        try {
            const {
                isTagged: isTaggedResponse,
                affectedVestingIds,
                affectedLegalDescriptionIds,
                isReviewStatedChanged
            } = await api.documentTagUntag.apiSetAllDocsIsTagged(
                orderId,
                documentIds,
                isTagged
            );
            if (isReviewStatedChanged) dispatch(fetchExamOrderReviewStateThunk(orderId));
            if (affectedVestingIds.length) dispatch(fetchExamOrderVestingData(orderId));
            if (affectedLegalDescriptionIds.length)
                dispatch(fetchExamOrderLegalDescriptionData(orderId));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            dispatch(
                setChildDocsIsUnTagged({
                    groupId: groupId,
                    documentIds,
                    isTagged: isTaggedResponse
                })
            );
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Set child documents of a parent document tag or untag: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };
/**
 * Change the tagged state of a single document in a document group
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to update
 * @param {boolean} isTagged Tagged value to assign to the document
 * @returns {AppThunk}
 */
export const setDocumentTagUntagThunk =
    (orderId: string, documentId: string, isTagged: boolean): AppThunk =>
    async (dispatch) => {
        try {
            const {
                isTagged: isTaggedResponse,
                documentId: documentIdResponse,
                isReviewStatedChanged,
                affectedVestingIds,
                affectedLegalDescriptionIds,
                includeAttachments: includeAttachmentsResponse
            } = await api.documentTagUntag.apiSetDocumentIsTagged(
                orderId,
                documentId,
                isTagged
            );
            if (isReviewStatedChanged) dispatch(fetchExamOrderReviewStateThunk(orderId));
            if (affectedVestingIds.length) dispatch(fetchExamOrderVestingData(orderId));
            if (affectedLegalDescriptionIds.length)
                dispatch(fetchExamOrderLegalDescriptionData(orderId));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            dispatch(
                setIsDocumentTagged({ docId: documentIdResponse, isTagged: isTaggedResponse })
            );
            dispatch(
                setDocumentIncludeAttached({
                    docId: documentIdResponse,
                    includeAttachments: includeAttachmentsResponse
                })
            );
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Set document tag or untag: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Upload a file and attach to an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to attach the file to
 * @param {File[]} files File to attach to the document
 * @returns {AppThunk}
 */
export const uploadDocumentImageThunk =
    (orderId: string, documentId: string, files: File[]): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examBlobDocumentFile.postExamDocumentFile(
                orderId,
                documentId,
                files,
                true,
                true
            );
            dispatch(updateDocumentFile({ docId: documentId, files: result }));
            dispatch(
                setHasPulseFilesFlagOfVestingData({ docId: documentId, hasPulseFiles: true })
            );
            dispatch(
                setHasPulseFilesFlagOfLegalData({ docId: documentId, hasPulseFiles: true })
            );
            dispatch(
                setSnackbarState({
                    open: true,
                    message: SnackbarSuccessMessage.UpdateDocumentSuccess,
                    severity: SnackbarSeverity.Success
                })
            );
        } catch (err) {
            dispatch(
                setFileUploadError({
                    error: true,
                    message: err.message
                })
            );
        }
    };

/**
 * Add a new note to an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to add a note to
 * @param {string} documentNote String containing the document note
 * @returns {AppThunk}
 */
export const addNewNoteThunk =
    (orderId: string, documentId: string, documentNote: string): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examOrderDocumentNotes.addExamOrderDocumentNote(
                orderId,
                documentId,
                documentNote
            );
            dispatch(updateDocumentNote({ docId: documentId, documentNote: result }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add new document note: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update a note in an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document that owns the note
 * @param {DocumentNote} documentNote Updated note object
 * @returns {AppThunk}
 */
export const updateNoteThunk =
    (orderId: string, documentId: string, documentNote: DocumentNote): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examOrderDocumentNotes.updateExamOrderDocumentNote(
                orderId,
                documentId,
                documentNote
            );
            dispatch(updateDocumentNote({ docId: documentId, documentNote: result }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add/update new document note: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a note from an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document that owns the note
 * @param {string} noteId ID of the note to remove
 * @returns {AppThunk}
 */
export const removeNoteThunk =
    (orderId: string, documentId: string, noteId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examOrderDocumentNotes.deleteExamOrderDocumentNoteById(
                orderId,
                documentId,
                noteId
            );
            dispatch(removeDocumentNote({ docId: documentId, noteId }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove document note: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the amount field of a document
 * @param {string} orderId ID of the order
 * @param {string} docId ID of the document to update
 * @param {number} amount Updated amount
 * @returns {AppThunk}
 */
export const updateAmountThunk =
    (orderId: string, docId: string, amount: number): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderDocumentFields.updateAmountFieldWithParagraphs(
                orderId,
                docId,
                amount
            );
            dispatch(updateAmount({ docId, amount }));
            if (response.paragraphs.length > 0) {
                response.paragraphs.forEach((para) => {
                    dispatch(updateParagraph({ id: para.id, paragraph: para }));
                });
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update amount: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the transfer tax amount field of a document
 * @param {string} orderId ID of the order
 * @param {string} docId ID of the document to update
 * @param {number} amount Updated amount
 * @returns {AppThunk}
 */
export const updateTransferTaxAmountThunk =
    (orderId: string, docId: string, amount: number): AppThunk =>
    async (dispatch) => {
        try {
            const response =
                await api.examOrderDocumentFields.updateTransferTaxAmountFieldWithParagraphs(
                    orderId,
                    docId,
                    amount
                );
            dispatch(updateTransferTaxAmount({ docId, amount }));
            if (response.paragraphs.length > 0) {
                response.paragraphs.forEach((para) => {
                    dispatch(updateParagraph({ id: para.id, paragraph: para }));
                });
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update transfer tax amount: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Set category and subcategory
 * @param {DocumentCategorySubCategoryDto}
 * @returns {AppThunk}
 */
export const setSubCategoryThunk =
    ({
        orderId,
        documentId,
        documentCategoryId,
        documentSubCategoryId,
        sourceDocumentTypeName
    }: DocumentCategorySubCategoryDto): AppThunk =>
    async (dispatch) => {
        try {
            const updatedDocTypeNCodesData =
                await api.examOrderDocumentFields.setCategoryAndSubcategoryApi({
                    orderId,
                    documentId,
                    documentCategoryId,
                    documentSubCategoryId,
                    sourceDocumentTypeName
                });
            if (updatedDocTypeNCodesData) {
                const updatedDocCodesData = [...updatedDocTypeNCodesData.codes];
                const updatedDocCategorySubCategoryData = { ...updatedDocTypeNCodesData };
                delete updatedDocCategorySubCategoryData.codes;

                dispatch(addDocumentCodes({ docId: documentId, codes: updatedDocCodesData }));
                dispatch(
                    updateCategorySubCategoryOfDoc({
                        docId: documentId,
                        docTypeResponse: updatedDocCategorySubCategoryData
                    })
                );
                dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Set document and subdocument: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default examOrderKeyDocumentGroupSlice.reducer;
