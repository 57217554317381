import { v4 as uuidv4 } from 'uuid';
import {
    DocumentAddReferenceDto,
    PulseDocumentParty,
    UpdateRecordedDocumentDto,
    KeyDocument
} from 'types/dataModels';
import { UploadKeyDocumentFormData } from 'types/uploadKeyDocumentForm';

/**
 * Update data for existing recorded document from user input form
 * @function
 * @param {string} categoryId - Document category id
 * @param {string} subCategoryId - Documents subcategory id
 * @param {UploadKeyDocumentFormData} documentData - Data from input form
 * @param {File[]} files
 * @param {string} propertyId - Document property id
 * @param {KeyDocument} document - key document row data
 * @returns {UpdateRecordedDocumentDto}
 */
export const updateDataForRecordedExistingDocument = (
    categoryId: string,
    subCategoryId: string,
    documentData: UploadKeyDocumentFormData,
    files: File[],
    propertyId: string,
    document: KeyDocument
): UpdateRecordedDocumentDto => {
    /**
     * Get number[] of id's from CodeTemplate[]
     */
    const codeTemplateIds: number[] = documentData.codes.reduce(
        (acc, current) => [...acc, current.id],
        []
    );

    /**
     * Convert ExamOrderReference[] to DocumentAddReferenceDto[]
     */
    const existingReferences: DocumentAddReferenceDto[] =
        documentData.foundReferencedDocuments.map((ref) => {
            return {
                referenceToDocumentId: ref.referenceToDocumentId,
                documentReferenceId: ref.documentReferenceId,
                instrumentNumber: '',
                instrumentYear: null,
                liber: '',
                page: ''
            };
        });

    /**
     * Create DocumentAddReferenceDto[] from input received from user for not existing references for current order
     */
    const notExistingReferences: DocumentAddReferenceDto[] =
        documentData.notExistingReferencedDocuments.map((ref) => {
            return {
                referenceToDocumentId: null,
                documentReferenceId: ref.documentReferenceId ?? null,
                instrumentNumber: ref.instrumentNumber,
                instrumentYear: ref.instrumentYear,
                liber: ref.book,
                page: ref.page
            };
        });

    /**
     * Concat existing and not existing references
     */
    const references: DocumentAddReferenceDto[] = [
        ...existingReferences,
        ...notExistingReferences
    ];

    /**
     * Convert PulseDocumentPartyField[] to PulseDocumentParty[]
     */
    const parties: PulseDocumentParty[] = documentData.parties.reduce(
        (acc, currentValue) => [...acc, currentValue],
        [] as PulseDocumentParty[]
    );

    return {
        id: document.id,
        bookType: document.bookType,
        documentType: {
            documentCategoryId: categoryId,
            documentSubCategoryId: subCategoryId,
            sourceDocumentTypeName: document?.documentType?.sourceDocumentTypeName ?? ''
        },
        propertyId,
        instrumentNumber: documentData.instrumentNumber,
        liber: documentData.book,
        page: documentData.page,
        codeTemplateIds: codeTemplateIds,
        instrumentYear: documentData.instrumentYear,
        notes: document?.notes?.length
            ? [{ id: document?.notes[0]?.id, note: documentData?.notes }]
            : documentData.notes
              ? [{ id: uuidv4(), note: documentData?.notes }]
              : [],
        amount: documentData?.amount,
        transferTaxAmount: documentData.transferTaxAmount,
        references,
        parties,
        datedDate: documentData.instrumentDate || null,
        recordedDate: documentData.recordedDate,
        effectiveDate: documentData.instrumentDate || null,
        documentSource: {
            dataProviderSourceName: document.examinerDataSourceDisplayValue
        },
        documentNumber: document.documentNumber,
        documentLegalDescription: document.documentLegalDescription
    };
};
