import Grid from '@mui/material/Grid';
import colors from 'core/constants/colors';
import InputMain from 'components/styled/Input';
import MainButton from 'components/styled/MainButton';
import { ButtonVariants } from 'core/constants/common';
import MainSelect from 'components/styled/Select';
import { useState, useEffect } from 'react';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { getStateCounty } from 'core/api/stateCounty';
import { SelectItem } from 'types/propTypes';
import { RoleMappings } from 'core/constants/common';
import styles from '../styles';

interface FormValueProps {
    firstName: string;
    middleName: string;
    lastName: string;
    username: string;
    email: string;
    phoneNumber: string;
    division: string;
    county: string;
    state: string;
    businessSegment: string;
    role: string;
    examinerLevel: string;
    manager: string;
}

const UserDetailsForm = () => {
    const { rightGridElement } = styles().classes;
    const statesData = useAppSelector((state) => state.statesData.states);
    const businessSegmentsData = useAppSelector(
        (state) => state.businessSegmentsData.businessSegments
    );
    const userProfileData = useAppSelector((state) => state.userProfileData.userData);
    const role = useAppSelector((state) => state.userProfileData.role);
    const { name, division, office, businessSegment, examinerLevel, manager } =
        userProfileData;
    const [stateMenuItems, setStateMenuItems] = useState([]);
    const [countyMenuItems, setCountyMenuItems] = useState([]);
    const [businessSegmentMenuItems, setBusinessSegmentMenuItems] = useState<SelectItem[]>([]);
    const [formValues, setFormValues] = useState<FormValueProps>({
        firstName: name.first,
        middleName: name.middle,
        lastName: name.last,
        username: '',
        email: '',
        phoneNumber: '',
        division: division.name,
        county: office.name,
        state: office.name,
        businessSegment: businessSegment,
        role: RoleMappings[role?.[0]] || '',
        examinerLevel: examinerLevel.name,
        manager: manager.name.first + ' ' + manager.name.last
    });

    const handleSubmit = () => {};

    const handleCancel = () => {};

    /**
     * Updates form values when user profile data changes.
     */
    useEffect(() => {
        setFormValues((prev) => {
            return {
                ...prev,
                firstName: name.first,
                middleName: name.middle,
                lastName: name.last,
                username: '',
                email: '',
                phoneNumber: '',
                division: division.name,
                county: '',
                state: office.name,
                businessSegment: businessSegment,
                role: RoleMappings[role?.[0]] || '',
                examinerLevel: examinerLevel.name,
                manager: manager.name.first + ' ' + manager.name.last
            };
        });
    }, [userProfileData, role]);

    /**
     * Updates state menu items when state data is available.
     */
    useEffect(() => {
        if (statesData.length) {
            setStateMenuItems(
                statesData.map((state) => ({ id: state.id, value: state.name }))
            );
        }
    }, [statesData.length]);

    /**
     * Fetches counties for the selected state when state changes.
     */
    useEffect(() => {
        (async () => {
            await fetchStateCounties();
        })();
    }, [formValues.state]);

    /**
     * Updates business segment menu items when data is available.
     */
    useEffect(() => {
        if (businessSegmentsData.length) {
            const menuItems = businessSegmentsData.map((segment) => ({
                id: segment.id,
                value: segment.id.toString(),
                display: segment.name
            }));
            setBusinessSegmentMenuItems(menuItems);
        }
    }, [businessSegmentsData]);

    /**
     * Fetches and sets counties for the selected state.
     */
    const fetchStateCounties = async () => {
        if (formValues.state !== '') {
            const currentState = statesData.find((el) => el.name === formValues.state);
            if (currentState?.id) {
                const counties = await getStateCounty(currentState?.id);
                setCountyMenuItems(
                    counties?.map((county) => ({ id: county.id, value: county.name }))
                );
            }
        }
    };

    return (
        <Grid item height="56%" className={rightGridElement}>
            <Grid
                container
                paddingBottom="2px"
                gap={'22px'}
                sx={{ backgroundColor: colors.main.primaryLight }}
                padding={2}>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <InputMain
                            value={formValues.firstName || ''}
                            label={'First Name'}
                            placeHolder={'e.g. David'}
                            disabled
                            onChange={(value) => {
                                setFormValues((prev) => {
                                    return { ...prev, firstName: value };
                                });
                            }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <InputMain
                            value={formValues.middleName}
                            label={'Middle Name'}
                            placeHolder={'e.g. John'}
                            disabled
                            onChange={(value) => {
                                setFormValues((prev) => {
                                    return { ...prev, middleName: value };
                                });
                            }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <InputMain
                            value={formValues.lastName}
                            label={'Last Name'}
                            placeHolder={'e.g. Law'}
                            disabled
                            onChange={(value) => {
                                setFormValues((prev) => {
                                    return { ...prev, lastName: value };
                                });
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <InputMain
                            value={formValues.username}
                            label={'Username'}
                            placeHolder={'ACraw'}
                            disabled
                            onChange={(value) => {
                                setFormValues((prev) => {
                                    return { ...prev, username: value };
                                });
                            }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <InputMain
                            value={formValues.email}
                            label={'Email'}
                            placeHolder={'abi.crawford@stewart.com'}
                            disabled
                            onChange={(value) => {
                                setFormValues((prev) => {
                                    return { ...prev, email: value };
                                });
                            }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <InputMain
                            value={formValues.phoneNumber}
                            label={'Phone Number'}
                            placeHolder={'e.g. 8798789878'}
                            disabled
                            onChange={(value) => {
                                setFormValues((prev) => {
                                    return { ...prev, phoneNumber: value };
                                });
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <InputMain
                            value={formValues.division}
                            label={'Division'}
                            placeHolder={'SEFS'}
                            disabled
                            onChange={(value) => {
                                setFormValues((prev) => {
                                    return { ...prev, division: value };
                                });
                            }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <MainSelect
                            menuItems={countyMenuItems}
                            selectValue={formValues.county}
                            label={'Office Location/County'}
                            placeholder={'California'}
                            disabled
                            selectOnChange={(e) => {
                                setFormValues((prev) => {
                                    return { ...prev, county: e.target.value };
                                });
                            }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        {!!stateMenuItems.length && (
                            <MainSelect
                                menuItems={stateMenuItems}
                                selectValue={formValues.state}
                                label={'States'}
                                placeholder={'Florida, California, Texas'}
                                disabled
                                selectOnChange={(e) => {
                                    setFormValues((prev) => {
                                        return { ...prev, state: e.target.value };
                                    });
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        {!!businessSegmentMenuItems.length && (
                            <MainSelect
                                placeholder={'Business segment'}
                                label={'Business Segment'}
                                selectOnChange={(e) => {
                                    setFormValues((prev) => {
                                        return { ...prev, businessSegment: e.target.value };
                                    });
                                }}
                                selectValue={formValues.businessSegment || ''}
                                menuItems={businessSegmentMenuItems}
                                disabled
                            />
                        )}
                    </Grid>
                    <Grid item md={4}>
                        <InputMain
                            value={formValues.role}
                            label={'Role'}
                            placeHolder={'Examiner'}
                            disabled
                            onChange={(value) => {
                                setFormValues((prev) => {
                                    return { ...prev, role: value };
                                });
                            }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <InputMain
                            value={formValues.examinerLevel}
                            label={'Examiner Level'}
                            placeHolder={'2'}
                            disabled
                            onChange={(value) => {
                                setFormValues((prev) => {
                                    return { ...prev, examinerLevel: value };
                                });
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <InputMain
                            value={formValues.manager}
                            label={'Manager'}
                            placeHolder={'John Finch'}
                            disabled
                            onChange={(value) => {
                                setFormValues((prev) => {
                                    return { ...prev, manager: value };
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{ backgroundColor: colors.main.primaryLight }}
                padding={3}
                marginTop={'2px'}
                gap={2}>
                <MainButton
                    retainCase
                    text={'Save Changes'}
                    action={() => handleSubmit()}
                    disabled={false}
                />
                <MainButton
                    retainCase
                    variant={ButtonVariants.secondary}
                    text={'Cancel Changes'}
                    action={handleCancel}
                />
            </Grid>
        </Grid>
    );
};

export default UserDetailsForm;
