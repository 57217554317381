export const DASHBOARD = 'dashboard';
export const WORKBENCH = 'workbench';
export const WORKBENCH_FORCE_EDITABLE = 'workbench_force_editable';
export const ORDERS = 'orders';
export const HISTORY = 'history';
export const TEST_ORDERS = 'test_orders';
export const TEST_ORDER_FORM = 'test_order_form';
export const TEST_ORDERS_LIST = 'test_orders_list';
export const CONFIG_MANAGER = 'config_manager';
export const USER_PROFILE = 'user_profile';
export const SANDBOX = 'super_duper_secret_sandbox';
