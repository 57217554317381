import { makeStyles } from 'tss-react/mui';
import colors from 'core/constants/colors';

const styles = makeStyles()(() => ({
    finalReviewContainer: {
        overflow: 'auto',
        width: '100%',
        height: '100%'
    },
    finalReviewPanel: {
        border: `1px solid ${colors.main.accent}`,
        backgroundColor: colors.main.primaryLight
    },
    finalReviewHeader: {
        padding: '20px',
        backgroundColor: colors.neutral.neutralLight,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    sectionDivider: {
        backgroundColor: colors.main.accent
    },
    footerDivider: {
        height: '40px',
        backgroundColor: colors.neutral.neutralLight
    },
    finalReviewFooter: {
        height: '86px',
        padding: '0px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    styledHyperlinksText: {
        fontWeight: 600,
        fontSize: '16px',
        minWidth: 'max-content',
        padding: '8px 60px 8px 2px',
        borderRadius: 0,
        textTransform: 'none',
        fontFamily: ['Montserrat', 'sans-serif'].join(',')
    },
    styledHyperlinkSwitch: {
        padding: 8,
        '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            '&::before, &::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16
            },
            '&::before': {
                left: 12
            },
            '&::after': {
                right: 12
            }
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: `${colors.main.accent}`
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: `${colors.main.accent}`
        }
    }
}));

export default styles;
