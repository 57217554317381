import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import MsalAuthService from 'core/services/MsalAuthService';
import { useEffect } from 'react';

/**
 * Page for redirection to auth service
 * @component
 * @returns {JSX.Element}
 */
export default function UnauthorizedView() {
    const { instance, accounts, inProgress } = useMsal();
    const msalConfigReq = async () => await MsalAuthService.getLoginRequestParams();

    useEffect(() => {
        if (inProgress == InteractionStatus.None) {
            msalConfigReq().then(async (config) => {
                if (accounts.length == 0) {
                    await instance.loginRedirect(config).catch((e) => {
                        console.error(e);
                    });
                }
            });
        }
    }, []);

    return <>Redirecting...</>;
}
