import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import UserImage from 'pages/UserProfile/UserImage/UserImage';
import UserScoreCard from 'pages/UserProfile/UserScoreCard/UserScoreCard';
import UserMetrics from 'pages/UserProfile/UserMetrics/UserMetrics';
import UserDetailsForm from 'pages/UserProfile/UserDetailsForm/UserDetailsForm';
import { useEffect } from 'react';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { fetchUserByObjectId } from 'core/features/userProfile/userProfileSlice';

const UserProfile = () => {
    const dispatch = useAppDispatch();
    const oid = useAppSelector((state) => state.userProfileData.oid);

    /**
     * Fetches user data when the `oid` changes.
     */
    useEffect(() => {
        if (oid) {
            dispatch(fetchUserByObjectId(oid));
        }
    }, [oid]);

    return (
        <Box sx={{ width: '99%', padding: 1, height: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }} height="100%">
                <UserImage />
                <UserScoreCard />
                <UserMetrics />
                <UserDetailsForm />
            </Grid>
        </Box>
    );
};

export default UserProfile;
