import clsx from 'clsx';
import { Grid, Typography } from '@mui/material';
import { textStylesRoboto } from 'styles/common';
import { Tax } from 'types/taxes';
import styles from './styles';

interface ExceptionsTableProps {
    tax: Tax;
}

/**
 * Render exceptions table on tax section final review
 * @param {Tax} tax Tax object
 * @component
 * @returns {JSX.Element}
 */

const ExceptionsTable = ({ tax }: ExceptionsTableProps) => {
    const { body_strong_14_semibold, body_14_regular } = textStylesRoboto().classes;
    const { subSectionTitle, tableContainer, tableCell, cellLeft, cellRight } = styles().classes;
    return (
        <>
            <Typography className={clsx(body_strong_14_semibold, subSectionTitle)}>
                Exceptions
            </Typography>
            <Grid container className={clsx(tableContainer, body_14_regular)}>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Homeowners
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.exemptionAmount?.toFixed(2)}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Mortgage
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.exemptionMortgageAmount?.toFixed(2)}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Homestead Supplemental
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.exemptionHomesteadSupplierAmount?.toFixed(2)}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Additional
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.exemptionAdditionalAmount?.toFixed(2)}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Other Exemptions
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.exemptionOtherAmount?.toFixed(2)}
                </Grid>
            </Grid>
        </>
    );
};

export default ExceptionsTable;
