import api from 'core/api/api';
import { handleError } from 'core/helpers/errorHandler';
import {
    ManualSearchResult,
    ManualSearchSiteData,
    ManualSearchSiteTypeData
} from 'types/dataModels';

export const getManualSearchSiteData = async (
    atsOrderId: number
): Promise<ManualSearchSiteData> => {
    try {
        const response = await api.get<ManualSearchSiteData>(
            `/api/manualSearch/sites/${atsOrderId}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

export const getManualSearchSiteTypeData = async (
    atsOrderId: number,
    siteType: number
): Promise<ManualSearchSiteTypeData> => {
    try {
        const response = await api.get<ManualSearchSiteTypeData>(
            `/api/manualSearch/sites/${atsOrderId}/${siteType}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

export const getSearchResultsFromManualSearch = async (
    getSearchResultsRequestPayload: Object
): Promise<ManualSearchResult> => {
    try {
        const response = await api.post<Object, ManualSearchResult>(
            `/api/manualSearch`,
            getSearchResultsRequestPayload,
            { showModalLoadingPopout: false }
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
