export const getWorkbenchURL = () => {
    const { protocol, host, pathname } = window.location;
    return `${protocol}//${host}${pathname}`;
};

export const getOrderURL = () => {
    const { protocol, host, pathname } = window.location;
    const [workbench, orderId] = pathname.split('/').filter(Boolean) ?? [];
    return `${protocol}//${host}/${workbench}/${orderId}`;
};

export const isWorkbenchPage = (orderId: string) => {
    return (
        window.location.pathname.includes(`/workbench/${orderId}`) ||
        window.location.pathname.includes(`/workbench_force_editable/${orderId}`)
    );
};
