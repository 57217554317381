import clsx from 'clsx';
import { Grid, Typography } from '@mui/material';
import { textStylesRoboto } from 'styles/common';
import { Tax } from 'types/taxes';
import styles from './styles';

interface TaxesTableProps {
    tax: Tax;
}

/**
 * Render taxes amount table on tax section final review
 * @param {Tax} tax Tax object
 * @component
 * @returns {JSX.Element}
 */
const TaxesTable = ({ tax }: TaxesTableProps) => {
    const { body_strong_14_semibold, body_14_regular } = textStylesRoboto().classes;
    const { subSectionTitle, tableContainer, tableCell, cellLeft, cellRight } = styles().classes;
    return (
        <>
            <Typography className={clsx(body_strong_14_semibold, subSectionTitle)}>
                Taxes
            </Typography>
            <Grid container className={clsx(tableContainer, body_14_regular)}>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Base Amount
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.baseAmount?.toFixed(2)}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Amount Due
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.amountDue?.toFixed(2)}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Delinquent Amount
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.foundDelinquent?.toFixed(2)}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Amount Paid
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.amountPaid?.toFixed(2)}
                </Grid>
            </Grid>
        </>
    );
};

export default TaxesTable;
