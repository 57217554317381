import {
    ExamOrderParentSuccessorDocumentGroupType,
    KeyDocumentWithSuccessors
} from 'types/dataModels';

export const flattenDocuments = (keyDocs: ExamOrderParentSuccessorDocumentGroupType[]) => {
    let flatDocs: KeyDocumentWithSuccessors[] = [];
    keyDocs.forEach((docGroup) => {
        docGroup.documents.forEach((doc) => {
            const tempArrDoc: KeyDocumentWithSuccessors[] = [];
            if (doc.childDocuments.length > 0) {
                doc.childDocuments.forEach((doc) => tempArrDoc.push(doc));
            }
            flatDocs.push(doc);
            if (tempArrDoc.length > 0) {
                flatDocs = [...flatDocs, ...tempArrDoc];
            }
        });
    });
    return flatDocs;
};
