import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api/api';
import { PagedOrdersApiParams } from 'types/propTypes';
import {
    ExaminerOrder,
    MultipleProjectOrderUpdateResponse,
    OriginalOrder,
    PagedData,
    ProjectOrder,
    ProjectStatus
} from 'types/dataModels';

enum PagedApiParamsNames {
    page = 'page',
    pageSize = 'pageSize',
    search = 'search',
    orderIds = 'orderIds',
    countyIds = 'countyIds',
    stateIds = 'stateIds',
    businessSegmentIds = 'businessSegmentIds',
    productTypeIds = 'productTypeIds',
    clientIds = 'clientIds',
    statusId = 'statusIds',
    sorting = 'sorting',
    fromDate = 'fromDate',
    toDate = 'toDate',
    isRush = 'isRush',
    assigned = 'assigned'
}

/**
 * Get paged list of orders
 * @function
 * @category API
 * @param {number} page
 * @param {number} pageSize
 * @param {string} search
 * @param {number[]} stateIds
 * @param {number[]} countyIds
 * @param {number[]} productTypeIds
 * @param {number[]} businessSegmentIds
 * @param {number[]} actionsIds
 * @param {string[]} clientIds
 * @param {string[]} statusIds
 * @param {string} orderFilter
 * @param {Object} sorting
 * @param {string} fromDate
 * @param {string} toDate
 * @param {boolean} isRush
 * @param {AssignedTo} assignedTo
 * @returns {PagedData<ProjectOrder>}
 */
export const getPagedOrdersDataApi = async ({
    page,
    pageSize,
    search,
    stateIds,
    countyIds,
    productTypeIds,
    businessSegmentIds,
    actionsIds,
    clientIds,
    statusIds,
    orderFilter,
    sorting,
    fromDate,
    toDate,
    isRush,
    assigned
}: PagedOrdersApiParams): Promise<PagedData<ProjectOrder>> => {
    const requestData: PagedOrdersApiParams = {
        page,
        pageSize,
        search: search === '' ? null : search,
        stateIds: stateIds.length ? stateIds : null,
        countyIds: countyIds.length ? countyIds : null,
        productTypeIds: productTypeIds.length ? productTypeIds : null,
        businessSegmentIds: businessSegmentIds.length ? businessSegmentIds : null,
        actionsIds: actionsIds.length ? actionsIds : null,
        clientIds: clientIds.length ? clientIds : null,
        statusIds: statusIds.length ? statusIds : null,
        orderFilter: orderFilter === '' ? null : orderFilter,
        sorting,
        fromDate: fromDate === '' ? null : fromDate,
        toDate: toDate === '' ? null : toDate,
        isRush: isRush,
        assigned
    };

    // Remove unused fields
    const filteredRequestData: Omit<PagedOrdersApiParams, 'page' | 'pageSize'> = Object.keys(
        requestData
    )
        .filter((k) => {
            if (k === PagedApiParamsNames.isRush) {
                return requestData[k as PagedApiParamsNames];
            }
            return requestData[k as PagedApiParamsNames] != null;
        })
        .reduce((a, k) => ({ ...a, [k]: requestData[k as PagedApiParamsNames] }), {});

    // Temporary solution, sorting reduce performance
    // After changes from backend need to delete line below
    delete filteredRequestData.sorting;
    try {
        const response = await api.getPaged<PagedData<ProjectOrder>>({
            ...filteredRequestData,
            pageSize,
            pageNumber: page,
            url: `/api/projectOrder/paged`
        });
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Create a new order
 * @function
 * @category API
 * @param {Omit<OriginalOrder, 'id'>} data
 */
export const postOrdersDataApi = async (data: Omit<OriginalOrder, 'id'>): Promise<void> => {
    try {
        await api.post<Omit<OriginalOrder, 'id'>, string>(`/api/testorder/resware`, data);
    } catch (err) {
        handleError(err);
    }
};

/**
 * Get an order by id value
 * @function
 * @category API
 * @param {string} id - target order id
 * @returns {ProjectOrder}
 */
export const getOrderById = async (id: string): Promise<ProjectOrder> => {
    try {
        const response = await api.get<ProjectOrder>(`/api/orderProject/${id}`);
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Get list of allowed statuses for order
 * @function
 * @category API
 * @param {string} orderId - Target order id value
 * @returns {ProjectStatus[]}
 */
export const getAllowedStatuses = async (orderId: string): Promise<ProjectStatus[]> => {
    try {
        const response = await api.get<ProjectStatus[]>(
            `/api/orderProject/${orderId}/status/allowed`
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Update current order status
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} statusId - New status id
 * @param {string} notes - Pause reason
 * @returns {ProjectOrder}
 */
export const updateOrderStatus = async (
    orderId: string,
    statusId: string,
    notes?: string
): Promise<ProjectOrder> => {
    try {
        const response = await api.put<
            { projectStatusId: string; notes: string },
            ProjectOrder
        >(`/api/orderProject/${orderId}/status/set`, {
            projectStatusId: statusId,
            notes: notes ?? 'Default note'
        });
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Update manager order status
 * @function
 * @category API
 * @param {string} projectId - project id
 * @param {string} statusId - New status id
 * @param {string} notes - Pause reason
 * @returns {ProjectOrder}
 */
export const updateManagerOrderStatus = async (
    projectId: string,
    statusId: string,
    notes?: string
): Promise<ProjectOrder> => {
    try {
        const response = await api.put<
            { projectStatusId: string; notes: string },
            ProjectOrder
        >(`/api/managerActions/${projectId}/status/set`, {
            projectStatusId: statusId,
            notes: notes ?? 'Default note'
        });
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Start new exam
 * @function
 * @category API
 * @param {string} projectId - Target order id
 * @returns {ProjectOrder}
 */
export const startExam = async (projectId: string): Promise<ProjectOrder> => {
    try {
        const response = await api.put<null, ProjectOrder>(
            `/api/projectOrder/${projectId}/status/startExam`
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Assign next order
 * @function
 * @category API
 * @returns {ProjectOrder}
 */
export const assignNextOrder = async (): Promise<ProjectOrder> => {
    try {
        const response = await api.put<null, ProjectOrder>(`/api/projectOrder/assignNext`);
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Assign next order
 * @function
 * @param {string} statusId
 * @param {string} userId
 * @category API
 * @returns {ProjectOrder}
 */
export const getInProgressOrders = async (
    statusId: string,
    userId: string
): Promise<PagedData<ProjectOrder>> => {
    try {
        try {
            const response = await api.get<PagedData<ProjectOrder>>(
                `/api/projectOrder/paged?Page=1&PageSize=5&StatusIds=${statusId}&AssignedUserIds=${userId}`
            );
            return response.data;
        } catch (e) {
            handleError(e);
        }
    } catch (err) {
        handleError(err);
    }
};

/**
 * Unassign order
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @returns {ProjectOrder}
 */
export const unassignOrder = async (orderId: string): Promise<ProjectOrder> => {
    try {
        const response = await api.put<null, ProjectOrder>(
            `/api/managerActions/${orderId}/unAssignOrder`
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Update Rush status of an Order
 * @function
 * @category API
 * @param {string} projectId - New status id
 * @param {boolean} isRush - Pause reason
 * @returns {ProjectOrder}
 */
export const updateRushOrderStatus = async (
    projectId: string,
    isRush: boolean
): Promise<ProjectOrder> => {
    try {
        const response = await api.put<{ isRush: boolean }, ProjectOrder>(
            `/api/managerActions/${projectId}/isRush`,
            { isRush }
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Updates the order status to canceled
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @returns {ProjectOrder}
 */
export const cancelOrder = async (orderId: string): Promise<ProjectOrder> => {
    try {
        const response = await api.put<null, ProjectOrder>(
            `/api/managerActions/${orderId}/cancelOrder`
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Reassigns the order to user
 * @function
 * @category API
 * @param {string} projectId - Project Id
 * @param {string} userId - User Id
 * @returns {ProjectOrder}
 */
export const reassignOrder = async (
    projectId: string,
    userId: string
): Promise<ProjectOrder> => {
    try {
        const response = await api.put<{ assignedUserId: string }, ProjectOrder>(
            `/api/managerActions/${projectId}/assignAndReassignOrder`,
            { assignedUserId: userId }
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Get all Examiner data
 * @function
 * @category API
 * @returns {ProjectOrder[]}
 */
export const getAllExaminersNOrders = async (): Promise<ExaminerOrder[]> => {
    try {
        const response = await api.get<ExaminerOrder[]>(`/api/managerActions/examinerOrders`);
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Marks Orders as canceled for given list of orders
 * @function
 * @category API
 * @param {string[]} orderIdList - List of order ids
 * @returns {MultipleProjectOrderUpdateResponse[]}
 */
export const markMultipleOrdersAsCanceled = async (
    orderIdList: string[]
): Promise<MultipleProjectOrderUpdateResponse[]> => {
    try {
        const response = await api.put<string[], MultipleProjectOrderUpdateResponse[]>(
            `/api/managerActions/cancelOrders`,
            orderIdList
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Marks Orders as Rush orders for given list of orders
 * @function
 * @category API
 * @param {{projectId: string, isRush: boolean}[]} orderList - List of orders with ProjectId and IsRush flag
 * @returns {MultipleProjectOrderUpdateResponse[]}
 */
export const markMultipleOrdersAsRush = async (
    orderList: { projectId: string; isRush: boolean }[]
): Promise<MultipleProjectOrderUpdateResponse[]> => {
    try {
        const response = await api.put<
            { projectId: string; isRush: boolean }[],
            MultipleProjectOrderUpdateResponse[]
        >(`/api/managerActions/isRushOrders`, orderList);
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Marks Orders as Unassigned for given list of orders
 * @function
 * @category API
 * @param {string[]} orderIdList - List of order Ids
 * @returns {MultipleProjectOrderUpdateResponse[]}
 */
export const markMultipleOrdersAsUnassgined = async (
    orderIdList: string[]
): Promise<MultipleProjectOrderUpdateResponse[]> => {
    try {
        const response = await api.put<string[], MultipleProjectOrderUpdateResponse[]>(
            `/api/managerActions/unAssignOrders`,
            orderIdList
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Marks Orders as Reassigned for given list of orders with given user Id
 * @function
 * @category API
 * @param {{projectId: string, assignedUserId: string}[]} orderList - List of orders with project Id and Assigned user Id
 * @returns {MultipleProjectOrderUpdateResponse[]}
 */
export const markMultipleOrdersAsReassgined = async (
    orderList: { projectId: string; assignedUserId: string }[]
): Promise<MultipleProjectOrderUpdateResponse[]> => {
    try {
        const response = await api.put<
            { projectId: string; assignedUserId: string }[],
            MultipleProjectOrderUpdateResponse[]
        >(`/api/managerActions/assignAndReassignOrders`, orderList);
        return response.data;
    } catch (err) {
        handleError(err);
    }
};
