import clsx from 'clsx';
import { Grid, Typography } from '@mui/material';
import { textStylesRoboto } from 'styles/common';
import { Tax } from 'types/taxes';
import styles from './styles';

interface AssessmentsTableProps {
    tax: Tax;
}

/**
 * Render assessments table on tax section final review
 * @param {Tax} tax Tax object
 * @component
 * @returns {JSX.Element}
 */
const AssessmentsTable = ({ tax }: AssessmentsTableProps) => {
    const { body_strong_14_semibold, body_14_regular } = textStylesRoboto().classes;
    const { subSectionTitle, tableContainer, tableCell, cellLeft, cellRight } = styles().classes;
    return (
        <>
            <Typography className={clsx(body_strong_14_semibold, subSectionTitle)}>
                Assessments
            </Typography>
            <Grid container className={clsx(tableContainer, body_14_regular)}>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Total Tax Value
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.totalAssessment?.toFixed(2)}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Land Value
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.assessedValueLand?.toFixed(2)}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Improvements Value
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.assessedValueImprovement?.toFixed(2)}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Total Assessed Value
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.assessedValueTotal?.toFixed(2)}
                </Grid>
            </Grid>
        </>
    );
};

export default AssessmentsTable;
