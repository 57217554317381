import { makeStyles } from 'tss-react/mui';
import colors from 'core/constants/colors';

const styles = makeStyles()(() => ({
    tableContainer: {
        border: `1px solid ${colors.main.secondaryLight}`,
        borderBottom: '0px',
        marginTop: '10px'
    },
    tableCell: {
        padding: '10px',
        borderBottom: `1px solid ${colors.main.secondaryLight}`
    },
    cellLeft: {
        fontWeight: '500'
    },
    cellRight: {
        borderLeft: `1px solid ${colors.main.secondaryLight}`
    }
}));

export default styles;
