import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IStartersAndPriorsInformationSlice {
    isPriorPolicyGeneralInformationOpen: boolean;
    keepLanguageForRequirements: string[];
    keepLanguageForExceptions: string[];
    selectedLegals: string[];
    selectedRequirements: string[];
    selectedExceptions: string[];
    selectedOthers: string[];
}

const initialState: IStartersAndPriorsInformationSlice = {
    isPriorPolicyGeneralInformationOpen: false,
    keepLanguageForRequirements: [],
    keepLanguageForExceptions: [],
    selectedLegals: [],
    selectedRequirements: [],
    selectedExceptions: [],
    selectedOthers: []
};

const startersAndPriorsInformationSlice = createSlice({
    name: 'startersAndPriorsInformation',
    initialState,
    reducers: {
        /**
         * Set open state for prior policy general information
         * @param state Slice state
         * @param action Payload with the boolean value to set
         */
        setIsPriorPolicyGeneralInformationOpen(
            state: IStartersAndPriorsInformationSlice,
            action: PayloadAction<boolean>
        ) {
            state.isPriorPolicyGeneralInformationOpen = action.payload;
        },
        setKeepLanguageForRequirements(
            state: IStartersAndPriorsInformationSlice,
            action: PayloadAction<string[]>
        ) {
            state.keepLanguageForRequirements = action.payload;
        },
        setKeepLanguageForExceptions(
            state: IStartersAndPriorsInformationSlice,
            action: PayloadAction<string[]>
        ) {
            state.keepLanguageForExceptions = action.payload;
        },
        setSelectedLegals(
            state: IStartersAndPriorsInformationSlice,
            action: PayloadAction<string[]>
        ) {
            state.selectedLegals = action.payload;
        },
        setSelectedRequirements(
            state: IStartersAndPriorsInformationSlice,
            action: PayloadAction<string[]>
        ) {
            state.selectedRequirements = action.payload;
        },
        setSelectedExceptions(
            state: IStartersAndPriorsInformationSlice,
            action: PayloadAction<string[]>
        ) {
            state.selectedExceptions = action.payload;
        },
        setSelectedOthers(
            state: IStartersAndPriorsInformationSlice,
            action: PayloadAction<string[]>
        ) {
            state.selectedOthers = action.payload;
        }
    }
});

export const {
    setIsPriorPolicyGeneralInformationOpen,
    setKeepLanguageForRequirements,
    setKeepLanguageForExceptions,
    setSelectedLegals,
    setSelectedRequirements,
    setSelectedExceptions,
    setSelectedOthers
} = startersAndPriorsInformationSlice.actions;
export default startersAndPriorsInformationSlice.reducer;
