import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import colors from 'core/constants/colors';
import Button from '@mui/material/Button';
import profile from 'pages/UserProfile/UserImage/image.png';
import { useEffect, useState } from 'react';
import {
    fetchUserProfileImage,
    removeUserProfileImage
} from 'core/features/userProfile/userProfileSlice';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { useAppSelector } from 'core/hooks/useAppSelector';
import UploadImageModal from 'pages/UserProfile/UserImage/UploadImageModal';
import { Modes } from 'core/constants/common';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import styles from '../styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: colors.main.primaryLight,
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
    maxHeight: '100%',
    borderRadius: '0px',
    boxShadow: 'none'
}));

const PrimaryButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'retainCase'
})(() => ({
    backgroundColor: '#483D91',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    height: '100%',
    width: '100%',
    minWidth: 'max-content',
    padding: '8px 12px',
    border: '1.5px solid #483D91',
    borderRadius: 0,
    textTransform: 'none',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    '&.Mui-focusVisible': {
        outline: '2px solid #643BC4',
        outlineOffset: '-2px'
    }
}));

const UserImage = () => {
    const dispatch = useAppDispatch();
    const profileImageUrl = useAppSelector((state) => state.userProfileData.profileImageUrl);
    const userId = useAppSelector((state) => state.userProfileData.userData.id);
    const [isUploadImageModalOpen, setIsUploadImageModal] = useState(false);
    const [mode, setMode] = useState<Modes>(profileImageUrl ? Modes.UPDATE : Modes.ADD);
    const { profileImage, profileContainer, leftGridElement, buttonContainer } =
        styles().classes;

    /**
     * Fetches user profile image when user ID changes.
     */
    useEffect(() => {
        if (userId !== '') {
            dispatch(fetchUserProfileImage(userId));
        }
    }, [userId]);

    useEffect(() => {
        if (profileImageUrl !== '') {
            setMode(Modes.UPDATE);
        }
    }, [profileImageUrl]);

    /**
     * Opens the file upload dialog.
     */
    const handleUploadImage = () => {
        setIsUploadImageModal(true);
    };

    /**
     * Removes the user profile image.
     */
    const handleRemoveImage = () => {
        dispatch(removeUserProfileImage(userId));
        setMode(Modes.ADD);
    };

    return (
        <Grid item height="43%" className={leftGridElement}>
            <Item className={profileContainer}>
                <img src={profileImageUrl || profile} alt="profile" className={profileImage} />
                <Grid item className={buttonContainer}>
                    <PrimaryButton
                        onClick={handleUploadImage}
                        variant="contained"
                        size="large"
                        style={{ borderRadius: 0, padding: '15px', border: 'none' }}
                        endIcon={
                            profileImageUrl ? (
                                <ModeEditOutlineOutlinedIcon style={{ fontSize: '15px' }} />
                            ) : null
                        }>
                        {profileImageUrl ? 'Change' : 'Upload image'}
                    </PrimaryButton>
                    {profileImageUrl !== '' && (
                        <PrimaryButton
                            onClick={handleRemoveImage}
                            variant="contained"
                            size="large"
                            style={{
                                borderRadius: 0,
                                padding: '15px',
                                border: 'none',
                                marginLeft: '5px'
                            }}
                            endIcon={<CancelOutlinedIcon style={{ fontSize: '15px' }} />}>
                            Remove
                        </PrimaryButton>
                    )}
                </Grid>
            </Item>
            {isUploadImageModalOpen && (
                <UploadImageModal
                    isUploadImageModalOpen={isUploadImageModalOpen}
                    setIsUploadImageModal={setIsUploadImageModal}
                    mode={mode}
                />
            )}
        </Grid>
    );
};

export default UserImage;
