import { makeStyles } from 'tss-react/mui';
import colors from 'core/constants/colors';
const styles = makeStyles()(() => ({
    root: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: colors.main.primaryLight
    }
}));
export default styles;
