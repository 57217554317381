import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarSeverity, TestOrderFormTypes } from 'core/constants/common';
import { AppThunk } from 'core/store/store';
import api from 'core/api';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { OriginalOrder, TestOrderFormData } from 'types/dataModels';

const defaultReswareOfficeId = 3;

interface TestOrderFormState {
    formType: TestOrderFormTypes;
    testOrderForm: TestOrderFormData;
}

const initialState: TestOrderFormState = {
    formType: TestOrderFormTypes.resware,
    testOrderForm: {
        parcelNumbers: {
            '0': {
                parcelNumber: '',
                streetNumber: '',
                streetDirection: '',
                streetName: '',
                streetSuffix: '',
                unit: '',
                city: '',
                state: '',
                zip: '',
                county: ''
            }
        },
        subdivisionName: '',
        interestEstateType: 'FEE SIMPLE',
        resWareFileId: '',
        agentFileNumber: '',
        lender: '',
        loanAmount: '',
        salePrice: '',
        officeId: defaultReswareOfficeId,
        clientName: '',
        transactionProductType: {
            transactionTypeId: 1,
            productTypeId: 1,
            transactionType: '',
            productType: ''
        },
        owners: {},
        buyers: {}
    }
};

const testOrderFormSlice = createSlice({
    name: 'testOrderForm',
    initialState,
    reducers: {
        /**
         * Set current test order form data to store
         * @param state Slice state
         * @param action Payload with the test order form data to set
         */
        setTestOrderForm(state, action: PayloadAction<TestOrderFormData>) {
            state.testOrderForm = action.payload;
        },
        /**
         * Clear all fields in test order form
         * @param state Slice state
         * @param action Payload with current tab to set
         *
         */
        clearTestOrderForm(state, action: PayloadAction<number>) {
            const currentTab = action.payload;

            switch (currentTab) {
                case 0:
                    state.testOrderForm = {
                        ...state.testOrderForm,
                        resWareFileId: initialState.testOrderForm.resWareFileId,
                        agentFileNumber: initialState.testOrderForm.agentFileNumber,
                        loanAmount: initialState.testOrderForm.loanAmount,
                        salePrice: initialState.testOrderForm.salePrice,
                        transactionProductType:
                            initialState.testOrderForm.transactionProductType,
                        officeId: initialState.testOrderForm.officeId
                    };
                    break;
                case 1:
                    state.testOrderForm = {
                        ...state.testOrderForm,
                        parcelNumbers: initialState.testOrderForm.parcelNumbers,
                        subdivisionName: initialState.testOrderForm.subdivisionName,
                        interestEstateType: initialState.testOrderForm.interestEstateType
                    };
                    break;
                case 2:
                    state.testOrderForm = {
                        ...state.testOrderForm,
                        owners: initialState.testOrderForm.owners,
                        buyers: initialState.testOrderForm.buyers
                    };
                    break;
                default:
                    state.testOrderForm = initialState.testOrderForm;
                    break;
            }
        },

        /**
         * Set the form type of test order
         * @param state Slice state
         * @param action Payload with the enum value to set as form type
         */
        setTestOrderFormType(state, action: PayloadAction<TestOrderFormTypes>) {
            state.formType = action.payload;
        },
        /**
         * Remove a buyer panel from test order form
         * @param state Slice state
         * @param action Payload with the ID of the buyer panel to remove
         */
        removeBuyerPanel(state, action: PayloadAction<string>) {
            delete state.testOrderForm.buyers[action.payload];
        },
        /**
         * Remove an owner panel from test order form
         * @param state Slice state
         * @param action Payload with the ID of the owner panel to remove
         */
        removeOwnersPanel(state, action: PayloadAction<string>) {
            delete state.testOrderForm.owners[action.payload];
        }
    }
});

export const {
    setTestOrderForm,
    clearTestOrderForm,
    setTestOrderFormType,
    removeBuyerPanel,
    removeOwnersPanel
} = testOrderFormSlice.actions;

/**
 * Create a new test order and post to BE
 * @param {Omit<OriginalOrder, 'id'>} data Object containing the fields of the new test order
 * @returns {AppThunk}
 */
export const postTestOrder =
    (data: Omit<OriginalOrder, 'id'>): AppThunk =>
    async (dispatch) => {
        try {
            await api.orders.postOrdersDataApi(data);
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Post Test Order: success`,
                    severity: SnackbarSeverity.Success
                })
            );
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Post Test order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default testOrderFormSlice.reducer;
