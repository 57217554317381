import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { textStylesRoboto } from 'styles/common';
import { extractText } from 'core/helpers/extractText';
import { transformToHTML } from 'components/common/TinyEditor/editorUtils';
import styles from './styles';

/**
 * Render legal description panel on final review
 * @component
 * @returns {JSX.Element}
 */
const LegalDescription = () => {
    const legalDescription = useAppSelector(
        (state) => state.finalReviewData.orderFinalReview.legalDescription
    );
    const { value, valueDocLinks } = legalDescription ?? {};
    const { body_large_16_semibold, body_strong_14_semibold, body_14_regular } =
        textStylesRoboto().classes;
    const { legalPanel, subSectionTitle, legalBox } = styles().classes;
    return (
        <Box className={legalPanel}>
            <Typography className={body_large_16_semibold}> LEGAL DESCRIPTION </Typography>
            <Typography className={clsx(body_strong_14_semibold, subSectionTitle)}>
                Legal Editor
            </Typography>
            <Box className={clsx(legalBox, body_14_regular)}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: transformToHTML(extractText(value), valueDocLinks, true)
                    }}
                />
            </Box>
        </Box>
    );
};

export default LegalDescription;
