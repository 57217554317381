import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { specialClientActions } from 'core/store/store';
import { useEffect } from 'react';

/**
 * You can read more about `specialClientActions` flag in store.ts
 *
 * Below code will dispatch the ui actions (current client state in base window) inside Popout window
 * For example: Whether warnings are pinned, order history panel is opened, etc.
 */
export const useSpecialClientAction = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (Array.isArray(specialClientActions) && specialClientActions?.length) {
            for (const [type, value] of Object.entries(sessionStorage)) {
                if (type && value) {
                    try {
                        const payload = JSON.parse(value);
                        const requiredAction = specialClientActions.some(
                            (actionName) => !!type.includes(actionName)
                        );
                        if (requiredAction && payload) {
                            dispatch({ type, payload });
                        }
                    } catch (error) {
                        return;
                    }
                }
            }
        }
    }, []);
};
