import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SnackbarSeverity } from 'core/constants/common';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import {
    ManualSearchResult,
    ManualSearchSiteData,
    ManualSearchSiteTypeData,
    ManualSearchSites
} from 'types/dataModels';

export interface ManualSearchState {
    manualSearchSiteData: ManualSearchSiteData;
    manualSearchSiteTypeData: ManualSearchSiteTypeData;
    selectedProperty: { id: string; atsOrderId: number; commaCountyAddress: string };
    selectedDataSource: ManualSearchSites;
    selectedDataSourceType: {
        html: string;
        moduleId: number;
        name: string;
    };
    manualSearchResults: ManualSearchResult;
}

export const initialState: ManualSearchState = {
    manualSearchSiteData: null,
    manualSearchSiteTypeData: null,
    selectedProperty: null,
    selectedDataSource: null,
    selectedDataSourceType: null,
    manualSearchResults: null
};

const manualSearchSlice = createSlice({
    name: 'examOrderManualSearch',
    initialState,
    reducers: {
        setSelectedProperty(
            state: ManualSearchState,
            action: PayloadAction<{
                id: string;
                atsOrderId: number;
                commaCountyAddress: string;
            }>
        ) {
            if (action.payload) {
                state.selectedProperty = {
                    id: action.payload.id,
                    atsOrderId: action.payload.atsOrderId,
                    commaCountyAddress: action.payload.commaCountyAddress
                };
            } else {
                state.selectedProperty = action.payload;
            }
        },
        setManualSearchSiteData(
            state: ManualSearchState,
            action: PayloadAction<ManualSearchSiteData>
        ) {
            state.manualSearchSiteData = action.payload;
        },
        setManualSearchSiteTypeData(
            state: ManualSearchState,
            action: PayloadAction<ManualSearchSiteTypeData>
        ) {
            state.manualSearchSiteTypeData = action.payload;
        },
        setDataSourceSelection(
            state: ManualSearchState,
            action: PayloadAction<ManualSearchSites>
        ) {
            state.selectedDataSource = action.payload;
        },
        setDataSourceTypeSelection(
            state: ManualSearchState,
            action: PayloadAction<{
                html: string;
                moduleId: number;
                name: string;
            }>
        ) {
            if (action.payload) {
                state.selectedDataSourceType = {
                    html: action.payload.html,
                    moduleId: action.payload.moduleId,
                    name: action.payload.name
                };
            } else {
                state.selectedDataSourceType = action.payload;
            }
        },
        setManualSearchResults(
            state: ManualSearchState,
            action: PayloadAction<ManualSearchResult>
        ) {
            state.manualSearchResults = action.payload;
        }
    }
});

export const {
    setManualSearchSiteData,
    setManualSearchSiteTypeData,
    setSelectedProperty,
    setDataSourceSelection,
    setDataSourceTypeSelection,
    setManualSearchResults
} = manualSearchSlice.actions;

export default manualSearchSlice.reducer;

export const getManualSearchSiteDataThunk =
    (atsOrderId: number): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.manualSearch.getManualSearchSiteData(atsOrderId);
            if (response?.errors?.length) {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `No DataSource present for manual search`,
                        severity: SnackbarSeverity.Error
                    })
                );
            } else {
                const updatedResponse = filterAndUpdateEnabledDataSource(response);
                dispatch(setManualSearchSiteData(updatedResponse));
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Fetch Manual Search Site Data: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

const filterAndUpdateEnabledDataSource = (
    response: ManualSearchSiteData
): ManualSearchSiteData => {
    return {
        ...response,
        sites: response.sites
            .filter((dataSource) => dataSource.features?.enabledSite)
            .map((dataSource) => {
                // condition 1: hardcoded check -----
                // For dataSource DMV, we dont want to show siteTypes
                // and directly show msg 'data source only'
                // condition 2: -----
                // This is to handle dataSource with Disabled Text
                // for those DS, we need to show msg 'data source only'
                if (dataSource.siteTypeId === 69 || dataSource.disabledText !== null) {
                    dataSource.features.enabledLinkOnly = true;
                }
                return dataSource;
            })
    };
};

export const getManualSearchSiteTypeDataThunk =
    (atsOrderId: number, siteTypeId: number): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.manualSearch.getManualSearchSiteTypeData(
                atsOrderId,
                siteTypeId
            );
            if (response?.errors?.length) {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: response.errors.join(', '),
                        severity: SnackbarSeverity.Error
                    })
                );
            } else dispatch(setManualSearchSiteTypeData(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Fetch Manual Search Site Type: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export const getSearchResultsFromManualSearchThunk =
    (searchFormData: Object): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setManualSearchResults({ isLoading: true }));
            const response =
                await api.manualSearch.getSearchResultsFromManualSearch(searchFormData);
            dispatch(setManualSearchResults(response));
        } catch (err) {
            dispatch(
                setManualSearchResults({
                    Errors: ['Unfortunately, something has gone wrong.']
                })
            );
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Manual Search Results: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };
