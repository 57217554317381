import { Grid, Typography, Slider, Stack } from '@mui/material';
import { textStylesRoboto } from 'styles/common';
import colors from 'core/constants/colors';
import Cropper from 'react-easy-crop';
import { useState } from 'react';
import { Point, Area } from 'react-easy-crop/types';
import ZoomOut from '@mui/icons-material/ZoomOut';
import ZoomIn from '@mui/icons-material/ZoomIn';
import MainButton from 'components/styled/MainButton';
import { ButtonVariants } from 'core/constants/common';
import getCroppedImg from 'core/helpers/cropImage';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import ReplayIcon from '@mui/icons-material/Replay';
import styles from '../styles';

interface CropViewProps {
    imageSrc: string;
    setImageSrc: Function;
    croppedSrc: string;
    setCroppedSrc: Function;
    setCropView: Function;
    setIsPreview: Function;
    setCroppedFile: Function;
    setIsUploadImageModal: Function;
}

const CropView = ({
    imageSrc,
    setImageSrc,
    croppedSrc,
    setCroppedSrc,
    setCropView,
    setIsPreview,
    setCroppedFile,
    setIsUploadImageModal
}: CropViewProps) => {
    const dispatch = useAppDispatch();
    const { body_large_16_semibold } = textStylesRoboto().classes;
    const { cropContainer, cropContainerWrapper } = styles().classes;
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleZoomOut = () => {
        if (zoom > 1) {
            setZoom((prev) => prev - 0.1);
        }
    };

    const handleZoomIn = () => {
        if (zoom < 3) {
            setZoom((prev) => prev + 0.1);
        }
    };

    const handleUndo = () => {
        setZoom(1);
    };

    const handleNext = async () => {
        try {
            const croppedResult = await getCroppedImg(imageSrc, croppedAreaPixels);
            if (croppedResult) {
                const { blobUrl, croppedFile } = croppedResult;
                setCroppedSrc(blobUrl);
                setCropView(false);
                setIsPreview(true);
                setCroppedFile(croppedFile);
            }
        } catch (e) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `User profile image: ${e.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

    const handleCancel = () => {
        setCroppedSrc(null);
        setCropView(false);
        setIsPreview(false);
        setImageSrc(null);
        setIsUploadImageModal(false);
    };

    return (
        <>
            <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                <Typography
                    className={body_large_16_semibold}
                    color={colors.main.secondaryDark}>
                    Crop
                </Typography>
            </Grid>
            <div className={cropContainerWrapper}>
                <div className={cropContainer}>
                    <Cropper
                        image={croppedSrc || imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        cropShape="round"
                    />
                </div>
            </div>
            <Stack
                spacing={2}
                direction="row"
                sx={{ mb: 1, width: '100%' }}
                alignItems="center">
                <ZoomOut onClick={handleZoomOut} style={{ color: colors.main.accent }} />
                <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e: Event, zoom: number) => setZoom(Number(zoom))}
                    classes={{ root: 'slider' }}
                    style={{ color: colors.main.accent }}
                />
                <ZoomIn
                    sx={{ pr: 2 }}
                    onClick={handleZoomIn}
                    style={{ color: colors.main.accent }}
                />
                <MainButton
                    retainCase
                    variant={ButtonVariants.secondary}
                    text={'Undo'}
                    action={handleUndo}
                    endIcon={<ReplayIcon style={{ fontSize: '15px' }} />}
                />
            </Stack>
            <Stack spacing={2} direction="row" sx={{ mb: 1, mt: 3 }} alignItems="center">
                <MainButton retainCase text={'Next'} action={handleNext} disabled={false} />
                <MainButton
                    retainCase
                    variant={ButtonVariants.secondary}
                    text={'Cancel'}
                    action={handleCancel}
                />
            </Stack>
        </>
    );
};

export default CropView;
