import clsx from 'clsx';
import { Box, Grid, Typography, Stack, Divider } from '@mui/material';
import { Icon, IconPaths } from 'components/styled/Icon';
import colors from 'core/constants/colors';
import { textStylesRoboto } from 'styles/common';
import IconButton from 'components/styled/IconButton';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { solveFinalReviewWarningThunk } from 'core/features/examOrderFinalReview/examOrderFinalReviewSlice';
import styles from './styles';

/**
 * Render warnings panel on final review
 * @component
 * @returns {JSX.Element}
 */
const Warnings = () => {
    const dispatch = useAppDispatch();
    const finalReviewData = useAppSelector((state) => state.finalReviewData);
    const { warnings } = finalReviewData;
    const { id: orderId } = useAppSelector(
        (state) => state.currentExamOrderData.currentExamOrder
    );
    const { body_large_16_semibold, body_14_regular, body_strong_14_semibold } =
        textStylesRoboto().classes;
    const { warningsPanel, warningsList, warningItemRow, warningInfo, resolveButton } =
        styles().classes;

    return (
        <Box className={warningsPanel}>
            <Typography className={body_large_16_semibold}> WARNINGS </Typography>
            {warnings?.length ? (
                <Stack className={warningsList}>
                    {warnings?.map((warning, i) => {
                        return (
                            <Box key={i}>
                                <Grid container className={warningItemRow}>
                                    <Grid item className={warningInfo}>
                                        <Icon
                                            width={18}
                                            fill={
                                                warning.status === 0
                                                    ? colors.additional.error_fail
                                                    : colors.additional.success_win
                                            }
                                            path={
                                                warning.status === 0
                                                    ? IconPaths.error
                                                    : IconPaths.done
                                            }
                                        />
                                        <Typography className={body_14_regular}>
                                            {warning.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            withoutBackground
                                            onClick={() =>
                                                dispatch(
                                                    solveFinalReviewWarningThunk(
                                                        orderId,
                                                        warning.id
                                                    )
                                                )
                                            }
                                            icon={
                                                <Typography
                                                    className={clsx(
                                                        body_strong_14_semibold,
                                                        resolveButton
                                                    )}>
                                                    Resolve Issue
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Box>
                        );
                    })}
                </Stack>
            ) : (
                <Typography marginTop={'10px'} className={body_14_regular}>
                    None found
                </Typography>
            )}
        </Box>
    );
};

export default Warnings;
