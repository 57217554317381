import { Role } from 'core/features/userProfile/userProfileSlice';
import { useAppSelector } from 'core/hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
    allowedRoles: Role[];
    children: React.ReactNode;
}

const ProtectedRoute = ({ allowedRoles, children }: ProtectedRouteProps) => {
    const [isMount, setIsMount] = useState(false);
    const role = useAppSelector((state) => state.userProfileData.role);
    const accept = !!allowedRoles.filter((value) => role?.includes(value))?.length;

    useEffect(() => {
        setIsMount(true);
    }, []);

    if (role && accept) {
        return <>{children}</>;
    }

    /** We know user role, The page is mounted but user does not have sufficient permission */
    if (role && isMount && !accept) {
        return <Navigate to="/unauthorized" />;
    }

    return null;
};

export default ProtectedRoute;
