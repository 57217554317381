import { Typography } from '@mui/material';
import { textStylesRoboto } from 'styles/common';
import colors from 'core/constants/colors';
import { useRef } from 'react';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { SnackbarSeverity } from 'core/constants/common';
import MainButton from 'components/styled/MainButton';
import { ButtonVariants } from 'core/constants/common';
import { isFileTooLarge, isFileTypeSupported } from 'core/helpers/cropImage';
import ComputerIcon from '@mui/icons-material/Computer';

interface UploadImageViewProps {
    setImageSrc: Function;
    setCropView: Function;
}

const UploadImageView = ({ setImageSrc, setCropView }: UploadImageViewProps) => {
    const { body_14_regular, caption_12_regular } = textStylesRoboto().classes;
    const imageUploadRef = useRef(null);
    const dispatch = useAppDispatch();

    const uploadImage = async () => {
        const uploadedFile = imageUploadRef.current?.files?.[0];
        if (!uploadedFile) {
            dispatchError('No file selected. Please select a file to upload.');
            return;
        }
        const { size, type } = uploadedFile;
        if (isFileTooLarge(size)) {
            dispatchError(
                `User profile image: Invalid File Size. Allowed files should be less than 5MB`
            );
            return;
        }
        if (!isFileTypeSupported(type)) {
            dispatchError(
                `User profile image: Invalid File Type. Allowed file types are: JPG, JPEG, PNG`
            );
            return;
        }
        const cachedURL = URL.createObjectURL(uploadedFile);
        setImageSrc(cachedURL);
        setCropView(true);
    };

    const dispatchError = (message: string) => {
        dispatch(
            setSnackbarState({
                open: true,
                message,
                severity: SnackbarSeverity.Error
            })
        );
    };

    const handleupload = () => {
        imageUploadRef.current.click();
    };

    return (
        <>
            <Typography
                className={body_14_regular}
                color={colors.main.secondaryDark}
                sx={{ paddingTop: '40px' }}>
                Drag photo here
            </Typography>
            <Typography
                className={body_14_regular}
                color={colors.main.secondaryDark}
                sx={{ paddingTop: 1, paddingBottom: 1 }}>
                or
            </Typography>
            <input type="file" id="file" ref={imageUploadRef} onChange={uploadImage} hidden />
            <MainButton
                retainCase
                variant={ButtonVariants.secondary}
                text={'Upload from computer'}
                action={handleupload}
                endIcon={<ComputerIcon />}
            />
            <Typography
                className={caption_12_regular}
                color={'#7F7F7F'}
                sx={{ paddingTop: 2 }}>
                Supported formats: JPG, JPEG, PNG
            </Typography>
            <Typography
                className={caption_12_regular}
                color={'#7F7F7F'}
                sx={{ paddingTop: 1 }}>
                Size limit: 5MB
            </Typography>
        </>
    );
};

export default UploadImageView;
