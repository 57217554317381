import { makeStyles } from 'tss-react/mui';
import colors from 'core/constants/colors';

const styles = makeStyles()(() => ({
    profileImage: {
        borderRadius: '50%',
        margin: 'auto',
        objectFit: 'cover',
        width: '280px',
        height: '280px'
    },
    profileContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    leftGridElement: {
        flexBasis: '22%',
        flexGrow: '0',
        maxWidth: '22%'
    },
    rightGridElement: {
        flexBasis: '78%',
        flexGrow: '0',
        maxWidth: '78%'
    },
    buttonContainer: {
        flexBasis: 'fit-content',
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%'
    },
    cropContainer: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '80px'
    },
    cropContainerWrapper: {
        width: '320px',
        height: '400px',
        position: 'relative',
        marginTop: '25px'
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'max-content',
        paddingTop: '25px',
        paddingBottom: '35px',
        paddingLeft: '20px',
        paddingRight: '20px',
        background: colors.main.primaryLight
    },
    previewUploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '30px',
        maxWidth: '300px'
    }
}));

export default styles;
