import { Role } from 'core/features/userProfile/userProfileSlice';
import { useAppSelector } from 'core/hooks/useAppSelector';
import React from 'react';

interface PermissionRestrictedComponentProps {
    children: React.ReactNode;
    allowedRoles: Role[];
}

/**
 * Component that will handle the permission or else render nothing
 * @component
 * @returns {JSX.Element}
 */
const PermissionRestrictedComponent = ({
    children,
    allowedRoles
}: PermissionRestrictedComponentProps) => {
    const role = useAppSelector((state) => state.userProfileData.role);
    const accept = allowedRoles.filter((value) => role?.includes(value))?.length;

    return accept ? <>{children}</> : null;
};

export default PermissionRestrictedComponent;
