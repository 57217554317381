import api from 'core/api/api';
import {
    createDataForCommonDocumentUpload,
    createDataForRecordedDocumentUpload
} from 'core/helpers/createDataForRecordedDocumentUpload';
import { handleError } from 'core/helpers/errorHandler';
import { updateDataForRecordedExistingDocument } from 'core/helpers/updateDataForRecordedDocumentUpload';
import { UploadSearchPackageFormData } from 'types/uploadSearchPackageForm';
import {
    CommonDocument,
    CreatePulseDocumentFileDto,
    KeyDocument,
    NewCommonDocumentWithFilesDto,
    NewRecordedDocumentWithFilesDto,
    PulseDocumentFile,
    UpdateRecordedDocumentDto
} from 'types/dataModels';
import { UploadKeyDocumentFormData } from 'types/uploadKeyDocumentForm';
import {
    deleteExamDocumentFile,
    overrideExamDocumentFile,
    postExamDocumentFile
} from 'core/api/examBlobDocumentFile';

/**
 * Creates new recorded document from user input form (second step of document creation after uploading images)
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {UploadKeyDocumentFormData} formData
 * @param {CreatePulseDocumentFileDto[]} uploadedFiles - Array of files for upload
 * @param {string} propertyId - Document property id
 * @returns {CommonDocument}
 */
export const uploadRecordedDocumentWithNoImage = async (
    orderId: string,
    formData: UploadKeyDocumentFormData,
    uploadedFiles: CreatePulseDocumentFileDto[],
    propertyId: string
): Promise<CommonDocument> => {
    try {
        const newKeyDocument = createDataForRecordedDocumentUpload(
            uploadedFiles,
            formData,
            propertyId
        );
        const response = await api.post<NewRecordedDocumentWithFilesDto, CommonDocument>(
            `/api/examOrder/${orderId}/document/recorded/withNoImage`,
            newKeyDocument
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

// Override
export const updateAndOverrideExistingRecordedDocument = async (
    documentData: UploadKeyDocumentFormData,
    files: File[],
    orderId: string,
    document: KeyDocument,
    propertyId: string,
    categoryId: string,
    subCategoryId: string
): Promise<CommonDocument> => {
    const uploadRequestPayload = updateDataForRecordedExistingDocument(
        categoryId,
        subCategoryId,
        documentData,
        files,
        propertyId,
        document
    );
    let response;

    if (files?.length > 0) {
        await overrideExamDocumentFile(
            orderId,
            document.id,
            document.files?.[0]?.id,
            files,
            true
        );
    }

    try {
        response = await api.put<FormData | UpdateRecordedDocumentDto, CommonDocument>(
            `/api/examOrder/${orderId}/document/${document.id}/recorded`,
            uploadRequestPayload
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

// Upload
export const updateAndAppendExistingRecordedDocument = async (
    documentData: UploadKeyDocumentFormData,
    files: File[],
    orderId: string,
    document: KeyDocument,
    propertyId: string,
    categoryId: string,
    subCategoryId: string
): Promise<CommonDocument> => {
    const uploadRequestPayload = updateDataForRecordedExistingDocument(
        categoryId,
        subCategoryId,
        documentData,
        files,
        propertyId,
        document
    );
    let response;
    /**
     * Test case:
     *  1. If a document has no attched files - post request
     *  2. If document has previously attached files - put request
     *     A. If examiner has removed any previusoly attached file - delete request
     */
    if (document.files?.length === 0) {
        // 1. If a document has no attched files - post request with overwriteImageFiles false and mergeImageFiles true
        await postExamDocumentFile(orderId, document.id, files, false, true);
    } else if (document.files?.length > 0) {
        // 2. If document has previously attached files - put request with overwriteImageFiles false

        const removedFiles: string[] = [];
        const onlyNewlyAttachedFiles = files.filter((f) => f.size);
        const onlyUploadedFiles = files.filter((f) => !f.size);

        document.files.forEach((file: PulseDocumentFile) => {
            // @ts-ignore
            if (!onlyUploadedFiles.find((f) => f.id === file.id)) {
                removedFiles.push(file.id);
            }
        });

        if (removedFiles?.length > 0) {
            // Delete removed files
            const promises = removedFiles.map(async (fileId) => {
                return await deleteExamDocumentFile(orderId, document.id, fileId);
            });
            await Promise.all(promises);

            if (onlyNewlyAttachedFiles?.length) {
                // Upload newly attached files;
                await postExamDocumentFile(
                    orderId,
                    document.id,
                    onlyNewlyAttachedFiles,
                    false,
                    true
                );
            }
        } else {
            // No change in previous files, upload newly added files
            await overrideExamDocumentFile(
                orderId,
                document.id,
                document.files?.[0]?.id,
                onlyNewlyAttachedFiles,
                false
            );
        }
    }

    try {
        response = await api.put<FormData | UpdateRecordedDocumentDto, CommonDocument>(
            `/api/examOrder/${orderId}/document/${document.id}/recorded`,
            uploadRequestPayload
        );
        return response.data;
    } catch (err) {
        handleError(err);
    }
};

/**
 * Creates new common document from user input form (second step of document creation after uploading images)
 * @function
 * @category API
 * @param {UploadSearchPackageFormData} formData - Data from input form
 * @param {CreatePulseDocumentFileDto[]} uploadedFiles - Array of files for upload
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Document property id
 * @returns {CommonDocument}
 */
export const uploadCommonDocumentWithNoImage = async (
    formData: UploadSearchPackageFormData,
    uploadedFiles: CreatePulseDocumentFileDto[],
    orderId: string,
    propertyId: string
): Promise<CommonDocument> => {
    const resultFormData = createDataForCommonDocumentUpload(
        uploadedFiles,
        formData,
        propertyId
    );
    try {
        const response = await api.post<NewCommonDocumentWithFilesDto, CommonDocument>(
            `/api/examOrder/${orderId}/document/common/withNoImage`,
            resultFormData
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
