import { handleError } from 'core/helpers/errorHandler';
import { Address, County } from 'types/dataModels';
import {
    ExamProperty,
    PropertyIdentifier,
    PropertyLegalDescription,
    UpdatePropertyAddressResponse
} from 'types/examOrderDataModel';
import api from '../api';

/**
 * Creates a new property for order
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {{address: Partial<Address>, legals: PropertyLegalDescription[], parcelIds: PropertyIdentifier[]}} propertyData - New property data
 * @returns {ExamProperty}
 */
export const addExamPropertyApi = async (
    orderId: string,
    propertyData: {
        address: Partial<Address>;
        legals: PropertyLegalDescription[];
        parcelIds: PropertyIdentifier[];
    }
): Promise<ExamProperty> => {
    try {
        const response = await api.post<
            {
                address: Partial<Address>;
                legals: PropertyLegalDescription[];
                parcelIds: PropertyIdentifier[];
            },
            ExamProperty
        >(`/api/examOrder/${orderId}/property`, propertyData);
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Remove a property for order
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} propertyId
 * @returns {ExamProperty}
 */
export const removeExamPropertyApi = async (
    orderId: string,
    propertyId: string
): Promise<ExamProperty> => {
    try {
        const response = await api.delete(`/api/examOrder/${orderId}/property/${propertyId}`);
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Update property address
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Current property address
 * @param {Address} updatedAddress - Updated address data
 * @returns {UpdatePropertyAddressResponse}
 */
export const updatePropertyAddressApi = async (
    orderId: string,
    propertyId: string,
    updatedAddress: Address
): Promise<UpdatePropertyAddressResponse> => {
    try {
        const response = await api.put<Address, UpdatePropertyAddressResponse>(
            `/api/examOrder/${orderId}/property/${propertyId}/address`,
            updatedAddress
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Update County Property
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Target property id value
 * @param {County} newCounty - Updated County value
 * @param {boolean} runAutoSearch - runAutoSearch value
 * @returns {ExamProperty}
 */
export const updateCountyPropertyAddressApi = async (
    orderId: string,
    propertyId: string,
    newCounty: County,
    runAutoSearch: boolean
): Promise<ExamProperty> => {
    try {
        const response = await api.put<County, ExamProperty>(
            `/api/examOrder/${orderId}/property/${propertyId}/address/${runAutoSearch}/countyChange`,
            newCounty
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Add new parcel to property
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Target property id value
 * @param {string} value - Parcel value
 * @returns {PropertyIdentifier}
 */
export const addPropertyParcelApi = async (
    orderId: string,
    propertyId: string,
    value: string
): Promise<PropertyIdentifier> => {
    try {
        const response = await api.post<{ examValue: string }, PropertyIdentifier>(
            `/api/examOrder/${orderId}/property/${propertyId}/parcelId`,
            {
                examValue: value
            }
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Update parcel value
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Target property id value
 * @param {string} parcelId - Target parcel id value
 * @param {string} value - Parcel value
 * @returns {PropertyIdentifier}
 */
export const updatePropertyParcelApi = async (
    orderId: string,
    propertyId: string,
    parcelId: string,
    value: string
): Promise<PropertyIdentifier> => {
    try {
        const response = await api.put<{ examValue: string }, PropertyIdentifier>(
            `/api/examOrder/${orderId}/property/${propertyId}/parcelId/${parcelId}`,
            {
                examValue: value
            }
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Remove parcel value from property
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Target property id value
 * @param {string} parcelId - Target parcel id value
 * @returns {void}
 */
export const removePropertyParcelApi = async (
    orderId: string,
    propertyId: string,
    parcelId: string
): Promise<void> => {
    try {
        const response = await api.delete(
            `/api/examOrder/${orderId}/property/${propertyId}/parcelId/${parcelId}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Add property legal description
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Target property id value
 * @param {Omit<PropertyLegalDescription, 'id'>} legalDescriptionValue - New legal description value
 * @returns {PropertyLegalDescription}
 */
export const addPropertyLegalDescriptionApi = async (
    orderId: string,
    propertyId: string,
    legalDescriptionValue: Omit<PropertyLegalDescription, 'id'>
): Promise<PropertyLegalDescription> => {
    try {
        const response = await api.post<
            Omit<PropertyLegalDescription, 'id'>,
            PropertyLegalDescription
        >(
            `/api/examOrder/${orderId}/property/${propertyId}/legalDescription`,
            legalDescriptionValue
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Update property legal description
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Target property id value
 * @param {string} legalDescriptionId - Target legal description id value
 * @param {Omit<PropertyLegalDescription, 'id'>} legalDescriptionValue - New legal description value
 * @returns {PropertyLegalDescription}
 */
export const updatePropertyLegalDescriptionApi = async (
    orderId: string,
    propertyId: string,
    legalDescriptionId: string,
    legalDescriptionValue: Omit<PropertyLegalDescription, 'id'>
): Promise<PropertyLegalDescription> => {
    try {
        const response = await api.put<
            Omit<PropertyLegalDescription, 'id'>,
            PropertyLegalDescription
        >(
            `/api/examOrder/${orderId}/property/${propertyId}/legalDescription/${legalDescriptionId}`,
            legalDescriptionValue
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};

/**
 * Remove property legal description
 * @function
 * @category API
 * @param {string} orderId - Current order id
 * @param {string} propertyId - Target property id value
 * @param {string} legalDescriptionId - Target legal description id value
 * @returns {void}
 */
export const removePropertyLegalDescriptionApi = async (
    orderId: string,
    propertyId: string,
    legalDescriptionId: string
): Promise<void> => {
    try {
        const response = await api.delete(
            `/api/examOrder/${orderId}/property/${propertyId}/legalDescription/${legalDescriptionId}`
        );
        return response.data;
    } catch (e) {
        handleError(e);
    }
};
