import { makeStyles } from 'tss-react/mui';
import colors from 'core/constants/colors';

export const contentStyles = makeStyles()(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row'
    },
    headerRow: {
        display: 'flex'
    },
    headerText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
        color: colors.main.accent
    },
    descriptionText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        color: colors.main.secondaryDark
    }
}));
