import { QueryClient, QueryCache, MutationCache } from '@tanstack/react-query';
import { flattenDocuments } from 'core/helpers/flattenDocs';
import { OCRRequestResult, OCRRequest, OCRCancelRequest } from 'types/dataModels';
import {
    AllowedStatusForOCRRequest,
    OCRStartStatuses,
    SnackbarSeverity,
    EnableOCRSuccessStatus
} from 'core/constants/common';
import store from 'core/store/store';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    },
    queryCache: new QueryCache({
        onSuccess: async (data, query) => {
            const isPollingRequest = query.queryKey.includes('requestId');
            if (isPollingRequest) {
                const ocrPollingResult = data as OCRRequestResult;
                const status = ocrPollingResult.status;

                // If you end up here because we are not getting a snackbar, it most likely is because we have not added the
                // correct status to the EnableOCRSuccessStatus array below. If we are getting valid results from the status then
                // add it there and it should be good
                if (EnableOCRSuccessStatus.includes(status)) {
                    const docs = flattenDocuments(
                        store.getState().examOrderKeyDocumentGroupData.examOrderDocumentGroup
                    );
                    const docName = docs.find(
                        (doc) => doc.id === ocrPollingResult.documentId
                    ).examinerDescriptionLink;
                    await store.dispatch({
                        type: 'snackbar/setSnackbarState',
                        payload: {
                            open: true,
                            message: `OCR Complete for ${docName}!`,
                            severity: SnackbarSeverity.Success
                        }
                    });
                    if (store.getState().documentViewerData.isModalOpen) {
                        await store.dispatch({
                            type: 'ocrData/setCurrentOCRStatus',
                            payload: {
                                id: ocrPollingResult.documentId,
                                status: OCRStartStatuses.completed
                            }
                        });
                    } else {
                        await store.dispatch({
                            type: 'ocrData/setCurrentOCRStatus',
                            payload: {
                                id: ocrPollingResult.documentId,
                                status: OCRStartStatuses.idle
                            }
                        });
                    }
                } else if (
                    status === AllowedStatusForOCRRequest.processing ||
                    status === AllowedStatusForOCRRequest.cancelled ||
                    status === AllowedStatusForOCRRequest.conversionComplete
                ) {
                    return;
                } else {
                    await store.dispatch({
                        type: 'snackbar/setSnackbarState',
                        payload: {
                            open: true,
                            message: `OCR has finished, but it may not have results. Click Start OCR again.`,
                            severity: SnackbarSeverity.Warning
                        }
                    });
                }
            }
        },
        onError: async (data, query) => {
            const isPollingRequest = query.queryKey.includes('requestId');
            if (isPollingRequest) {
                const ocrPollingResult = data as OCRRequestResult;
                const docs = flattenDocuments(
                    store.getState().examOrderKeyDocumentGroupData.examOrderDocumentGroup
                );
                const docName = docs.find(
                    (doc) => doc.id === ocrPollingResult.documentId
                ).examinerDescriptionLink;
                await store.dispatch({
                    type: 'snackbar/setSnackbarState',
                    payload: {
                        open: true,
                        message: `OCR Failed for ${docName}!`,
                        severity: SnackbarSeverity.Error
                    }
                });
                await store.dispatch({
                    type: 'ocrData/setCurrentOCRStatus',
                    payload: {
                        id: ocrPollingResult.documentId,
                        status: OCRStartStatuses.failed
                    }
                });
            }
        }
    }),
    mutationCache: new MutationCache({
        onMutate: async (vars, mutation) => {
            const opts = mutation.options;
            if (opts.mutationKey) {
                const isOCRRequest = mutation.options.mutationKey.includes('createOCR');
                const isOCRCancellation = mutation.options.mutationKey.includes('cancelOCR');
                if (isOCRRequest) {
                    const { documentId } = vars as OCRRequest;
                    const docs = flattenDocuments(
                        store.getState().examOrderKeyDocumentGroupData.examOrderDocumentGroup
                    );
                    if (
                        store.getState().ocrData.currentOCRStatus.status !==
                        OCRStartStatuses.initiated
                    ) {
                        const docName = docs?.find(
                            (doc) => doc.id === documentId
                        ).examinerDescriptionLink;
                        await store.dispatch({
                            type: 'snackbar/setSnackbarState',
                            payload: {
                                open: true,
                                message: `Sending OCR Request for ${docName}`,
                                severity: SnackbarSeverity.Info
                            }
                        });
                    }
                } else if (isOCRCancellation) {
                    const { documentId } = vars as OCRCancelRequest;
                    const docs = flattenDocuments(
                        store.getState().examOrderKeyDocumentGroupData.examOrderDocumentGroup
                    );

                    const docName = docs.find(
                        (doc) => doc.id === documentId
                    ).examinerDescriptionLink;
                    await store.dispatch({
                        type: 'snackbar/setSnackbarState',
                        payload: {
                            open: true,
                            message: `Cancelling OCR Request for ${docName}`,
                            severity: SnackbarSeverity.Success
                        }
                    });
                }
            }
        }
    })
});

export default queryClient;
