import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IFullScreenSidebarSlice {
    /**
     * Is pinned warning section expanded
     */
    isPinnedWarningsExpanded: boolean;
    /**
     * Is SCC section expanded
     */
    isSCCExpanded: boolean;
    /**
     * Is Manual Search section expanded
     */
    isManualSearchExpanded: boolean;
    /**
     * Is Order History section expanded
     */
    isOrderHistoryExpanded: boolean;
    /**
     * Is Key documents section expanded
     */
    isKeyDocsExpanded: boolean;
    /**
     * Is Search Package section expanded
     */
    isSearchPackageExpanded: boolean;
    /**
     * Is Search Package section expanded
     */
    isStartersAndPriorsExpanded: boolean;
    /**
     * Key document open groups
     */
    keyDocsOpenGroups: string[];
    /**
     * Search package open groups
     */
    searchPackageOpenGroups: string[];
    /**
     * Search package open groups
     */
    startersAndPriorsOpenGroups: string[];
}

export const initialState: IFullScreenSidebarSlice = {
    isPinnedWarningsExpanded: false,
    isSCCExpanded: false,
    isManualSearchExpanded: false,
    isOrderHistoryExpanded: false,
    isKeyDocsExpanded: false,
    keyDocsOpenGroups: [],
    isSearchPackageExpanded: false,
    searchPackageOpenGroups: [],
    isStartersAndPriorsExpanded: false,
    startersAndPriorsOpenGroups: []
};

const fullScreenSidebarSlice = createSlice({
    name: 'fullScreenSidebar',
    initialState,
    reducers: {
        /**
         * Set collapsed/expanded state for Pinned Warnings section
         * @param state Slice state
         * @param action Payload with the boolean value we use to set the variable
         */
        setIsPinnedWarningsExpanded(
            state: IFullScreenSidebarSlice,
            action: PayloadAction<IFullScreenSidebarSlice['isPinnedWarningsExpanded']>
        ) {
            state.isPinnedWarningsExpanded = action.payload;
        },
        /**
         * Set collapsed/expanded state for SCC section
         * @param state Slice state
         * @param action Payload with the boolean value we use to set the variable
         */
        setIsSCCExpanded(
            state: IFullScreenSidebarSlice,
            action: PayloadAction<IFullScreenSidebarSlice['isSCCExpanded']>
        ) {
            state.isSCCExpanded = action.payload;
        },
        /**
         * Set collapsed/expanded state for Order History section
         * @param state Slice state
         * @param action Payload with the boolean value we use to set the variable
         */
        setIsOrderHistoryExpanded(
            state: IFullScreenSidebarSlice,
            action: PayloadAction<IFullScreenSidebarSlice['isOrderHistoryExpanded']>
        ) {
            state.isOrderHistoryExpanded = action.payload;
        },
        /**
         * Set collapsed/expanded state for Manual Search section
         * @param state Slice state
         * @param action Payload with the boolean value we use to set the variable
         */
        setIsManualSearchExpanded(
            state: IFullScreenSidebarSlice,
            action: PayloadAction<IFullScreenSidebarSlice['isManualSearchExpanded']>
        ) {
            state.isManualSearchExpanded = action.payload;
        },
        /**
         * Set collapsed/expanded state for Keu Docs section
         * @param state Slice state
         * @param action Payload with the boolean value we use to set the variable
         */
        setIsKeyDocsExpanded(
            state: IFullScreenSidebarSlice,
            action: PayloadAction<IFullScreenSidebarSlice['isKeyDocsExpanded']>
        ) {
            state.isKeyDocsExpanded = action.payload;
        },
        /**
         *
         * @param state Slice state
         * @param action Payload with the array to set open key documents
         */
        setOpenKeyDocsGroup(
            state: IFullScreenSidebarSlice,
            action: PayloadAction<IFullScreenSidebarSlice['keyDocsOpenGroups']>
        ) {
            state.keyDocsOpenGroups = action.payload;
        },
        /**
         * Set collapsed/expanded state for Search Package section
         * @param state Slice state
         * @param action Payload with the boolean value we use to set the variable
         */
        setIsSearchPackageExpanded(
            state: IFullScreenSidebarSlice,
            action: PayloadAction<IFullScreenSidebarSlice['isSearchPackageExpanded']>
        ) {
            state.isSearchPackageExpanded = action.payload;
        },
        /**
         *
         * @param state Slice state
         * @param action Payload with the array to set open search package
         */
        setOpenSearchPkgGroup(
            state: IFullScreenSidebarSlice,
            action: PayloadAction<IFullScreenSidebarSlice['searchPackageOpenGroups']>
        ) {
            state.searchPackageOpenGroups = action.payload;
        },
        /**
         * Set collapsed/expanded state for Starters And Priors section
         * @param state Slice state
         * @param action Payload with the boolean value we use to set the variable
         */
        setIsStartersAndPriorsExpanded(
            state: IFullScreenSidebarSlice,
            action: PayloadAction<IFullScreenSidebarSlice['isStartersAndPriorsExpanded']>
        ) {
            state.isStartersAndPriorsExpanded = action.payload;
        },
        /**
         *
         * @param state Slice state
         * @param action Payload with the array to set open starters and priors
         */
        setOpenStartersAndPriorsGroup(
            state: IFullScreenSidebarSlice,
            action: PayloadAction<IFullScreenSidebarSlice['startersAndPriorsOpenGroups']>
        ) {
            state.startersAndPriorsOpenGroups = action.payload;
        },
        /**
         * Set the state to the initial state of all panels closed
         * @param state Slice state
         */
        closeSidebarPanels(state) {
            // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unused-vars
            state = initialState;
        }
    }
});

export const {
    setIsPinnedWarningsExpanded,
    setIsSCCExpanded,
    setIsOrderHistoryExpanded,
    setIsManualSearchExpanded,
    setIsKeyDocsExpanded,
    setOpenKeyDocsGroup,
    setIsSearchPackageExpanded,
    setOpenSearchPkgGroup,
    setIsStartersAndPriorsExpanded,
    setOpenStartersAndPriorsGroup,
    closeSidebarPanels
} = fullScreenSidebarSlice.actions;
export default fullScreenSidebarSlice.reducer;
