import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import colors from 'core/constants/colors';
import styles from '../styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: colors.main.primaryLight,
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
    maxHeight: '100%',
    borderRadius: '0px',
    boxShadow: 'none'
}));

const UserMetrics = () => {
    const { leftGridElement } = styles().classes;

    return (
        <Grid item height="55%" className={leftGridElement}>
            <Item>States</Item>
        </Grid>
    );
};

export default UserMetrics;
