import { makeStyles } from 'tss-react/mui';
import colors from 'core/constants/colors';

const styles = makeStyles()(() => ({
    vestingPanel: {
        padding: '20px'
    },
    subSectionTitle: {
        color: colors.main.accent,
        marginTop: '20px'
    },
    vestingBox: {
        marginTop: '16px',
        border: `1px solid ${colors.main.accent}`,
        padding: '15px'
    }
}));

export default styles;
