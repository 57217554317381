import { Typography, Stack } from '@mui/material';
import { textStylesRoboto } from 'styles/common';
import colors from 'core/constants/colors';
import { ButtonVariants } from 'core/constants/common';
import MainButton from 'components/styled/MainButton';
import { MouseEvent } from 'react';

interface PreviewImageProps {
    handleSave: (event?: MouseEvent) => void;
    handlePreviewCancel: (event?: MouseEvent) => void;
}

const PreviewImage = ({ handleSave, handlePreviewCancel }: PreviewImageProps) => {
    const { body_14_regular } = textStylesRoboto().classes;

    return (
        <>
            <Typography
                className={body_14_regular}
                color={colors.main.secondaryDark}
                sx={{ paddingTop: '40px', textAlign: 'center' }}>
                It could take a day or two to see the change take effect.
            </Typography>
            <Stack spacing={2} direction="row" sx={{ mb: 1, mt: 3 }} alignItems="center">
                <MainButton retainCase text={'Save'} action={handleSave} disabled={false} />
                <MainButton
                    retainCase
                    variant={ButtonVariants.secondary}
                    text={'Cancel'}
                    action={handlePreviewCancel}
                />
            </Stack>
        </>
    );
};

export default PreviewImage;
