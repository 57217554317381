import { Grid, Modal, Typography } from '@mui/material';
import colors from 'core/constants/colors';
import { textStylesRoboto } from 'styles/common';
import { useAppSelector } from 'core/hooks/useAppSelector';
import profile from 'pages/UserProfile/UserImage/image.png';
import { useState, useCallback, DragEvent, useEffect } from 'react';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { Modes, SnackbarSeverity } from 'core/constants/common';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import {
    uploadUserProfileImage,
    updateUserProfileImage
} from 'core/features/userProfile/userProfileSlice';
import CropView from 'pages/UserProfile/UserImage/CropView';
import PreviewImage from 'pages/UserProfile/UserImage/PreviewImage';
import UploadImageView from 'pages/UserProfile/UserImage/UploadImageView';
import { isFileTooLarge, isFileTypeSupported } from 'core/helpers/cropImage';
import styles from '../styles';

interface UploadImageModalProps {
    isUploadImageModalOpen: boolean;
    setIsUploadImageModal: Function;
    mode: Modes;
}

const UploadImageModal = ({
    isUploadImageModalOpen,
    setIsUploadImageModal,
    mode
}: UploadImageModalProps) => {
    const { body_large_16_semibold } = textStylesRoboto().classes;
    const { profileImage, modalContainer, previewUploadContainer } = styles().classes;
    const dispatch = useAppDispatch();
    const profileImageUrl = useAppSelector((state) => state.userProfileData.profileImageUrl);
    const userId = useAppSelector((state) => state.userProfileData.userData.id);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [croppedSrc, setCroppedSrc] = useState<string | null>(null);

    const [isCropView, setCropView] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [croppedFile, setCroppedFile] = useState<File>();

    useEffect(() => {
        if (mode === Modes.UPDATE && profileImageUrl !== '') {
            setImageSrc(profileImageUrl);
        }
    }, [profileImageUrl]);

    // Helper function to dispatch error messages
    const dispatchError = (message: string): void => {
        dispatch(
            setSnackbarState({
                open: true,
                message,
                severity: SnackbarSeverity.Error
            })
        );
    };

    // Helper function to process the file
    const processFile = (
        file: File,
        setImageSrc: (src: string) => void,
        setCropView: (view: boolean) => void
    ) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageSrc(reader.result as string);
            setCropView(true);
        };
        reader.readAsDataURL(file);
    };

    const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];
            if (isFileTooLarge(file.size)) {
                dispatchError(
                    'User profile image: Invalid File Size. Allowed files should be less than 5MB'
                );
                return;
            }
            if (isFileTypeSupported(file.type)) {
                processFile(file, setImageSrc, setCropView);
            } else {
                dispatchError(
                    'User profile image: Invalid File Type. Allowed file types are: JPG, JPEG, PNG'
                );
            }
        }
    }, []);

    // Handle drag over to enable drop
    const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    // Handle drag enter and leave for styling purposes
    const handleDragEnter = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        // Add custom styles for drag enter if needed
    }, []);

    const handleDragLeave = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        // Add custom styles for drag leave if needed
    }, []);

    const handlePreviewCancel = () => {
        setCropView(true);
        setCroppedSrc(imageSrc);
    };

    const handleSave = () => {
        const formData = new FormData();
        formData.append('file', croppedFile);
        if (mode === Modes.ADD) {
            dispatch(uploadUserProfileImage(userId, formData, croppedSrc));
        } else {
            dispatch(updateUserProfileImage(userId, formData, croppedSrc));
        }

        setIsUploadImageModal(false);
    };

    return (
        <Modal open={isUploadImageModalOpen}>
            <Grid
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                className={modalContainer}>
                {isCropView ? (
                    <CropView
                        imageSrc={imageSrc}
                        setImageSrc={setImageSrc}
                        croppedSrc={croppedSrc}
                        setCroppedSrc={setCroppedSrc}
                        setCropView={setCropView}
                        setIsPreview={setIsPreview}
                        setCroppedFile={setCroppedFile}
                        setIsUploadImageModal={setIsUploadImageModal}
                    />
                ) : (
                    <Grid sx={{ px: '55px' }}>
                        <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                            <Typography
                                className={body_large_16_semibold}
                                color={colors.main.secondaryDark}>
                                {isPreview
                                    ? 'Your new profile picture'
                                    : `${mode === 'add' ? 'Upload' : 'Change'} profile picture`}
                            </Typography>
                        </Grid>
                        <Grid className={previewUploadContainer}>
                            <img
                                src={croppedSrc || imageSrc || profile}
                                alt="profile"
                                className={profileImage}
                            />
                            {isPreview ? (
                                <PreviewImage
                                    handlePreviewCancel={handlePreviewCancel}
                                    handleSave={handleSave}
                                />
                            ) : (
                                <UploadImageView
                                    setImageSrc={setImageSrc}
                                    setCropView={setCropView}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
};

export default UploadImageModal;
