import { Box, Grid } from '@mui/material';
import { textStylesMontserrat } from 'styles/common';
import styles from './styles';

/**
 * Render unauthorized page
 * @component
 * @returns {JSX.Element}
 */
const UnAuthorized = () => {
    const { root } = styles().classes;
    const { subtitle_16_semibold } = textStylesMontserrat().classes;

    return (
        <Grid className={root} display={'flex'} flexDirection={'column'}>
            <Box className={subtitle_16_semibold} color={'#000000'}>
                You don&apos;t have permission to view this page.
            </Box>
        </Grid>
    );
};

export default UnAuthorized;
