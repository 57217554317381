import clsx from 'clsx';
import { Box, Grid, Typography } from '@mui/material';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { textStylesRoboto } from 'styles/common';
import FinalReviewSubpanel from 'pages/Workbench/FinalReview/FinalReviewSubpanel';
import styles from './styles';

/**
 * Render order details panel on final review
 * @component
 * @returns {JSX.Element}
 */
const OrderDetails = () => {
    const orderFinalReview = useAppSelector((state) => state.finalReviewData.orderFinalReview);
    const { details: orderDetails, properties, parties } = orderFinalReview ?? {};
    const { body_large_16_semibold, body_14_regular, body_strong_14_semibold } =
        textStylesRoboto().classes;
    const {
        orderDetailsPanel,
        tableContainer,
        tableCell,
        cellLeft,
        cellRight,
        propertiesSection,
        propertiesPanelDetails,
        propertiesTable,
        propertiesCell,
        propertyAddressData,
        legalInfoCell,
        legalInfoTitle,
        subSectionTitle
    } = styles().classes;

    return (
        <Box className={orderDetailsPanel}>
            <Typography className={body_large_16_semibold}> ORDER DETAILS </Typography>
            <Grid container className={clsx(tableContainer, body_14_regular)}>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Business Segment
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {orderDetails?.businessSegment}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Product Type
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {orderDetails?.productType}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Client Name
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {orderDetails?.clientName}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Transaction
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {orderDetails?.transactionType}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Office ID
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {orderDetails?.officeId}
                </Grid>
            </Grid>
            <Typography className={clsx(body_strong_14_semibold, subSectionTitle)}>
                General Info
            </Typography>
            <Box className={propertiesSection}>
                <FinalReviewSubpanel header={'Property Details'} expanded={true}>
                    <Box className={propertiesPanelDetails}>
                        {properties?.map((property, propertyIndex) => {
                            const {
                                county,
                                streetNumber,
                                streetName,
                                streetSuffix,
                                streetDirection,
                                unit,
                                zip,
                                city
                            } = property.address;
                            return (
                                <Grid
                                    key={propertyIndex}
                                    container
                                    className={clsx(propertiesTable, body_14_regular)}>
                                    <Grid item xs={4} className={propertiesCell}>
                                        <Box className={propertyAddressData}>
                                            <Typography className={body_strong_14_semibold}>
                                                {`Property ${propertyIndex + 1} ${
                                                    propertyIndex === 0 ? '(Primary)' : ''
                                                }`}
                                            </Typography>
                                            <Typography className={body_strong_14_semibold}>
                                                {`${county.state.abbr}, ${county.name}, ${streetNumber} ${streetDirection ? streetDirection : ''} ${streetName}
                                                ${streetSuffix}${unit ? ' ' + unit : ''}, ${city}${zip ? ', ' + zip : ''}`}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} className={propertiesCell}>
                                        <Typography className={body_14_regular}>
                                            {property.pids}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={5}
                                        className={clsx(propertiesCell, legalInfoCell)}>
                                        {property.legals.map((legal, i) => {
                                            return (
                                                <Box key={i}>
                                                    <Typography
                                                        className={clsx(
                                                            body_14_regular,
                                                            legalInfoTitle
                                                        )}>
                                                        {`Legal Info ${i + 1}`}
                                                    </Typography>
                                                    <Typography className={body_14_regular}>
                                                        {legal}
                                                    </Typography>
                                                </Box>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Box>
                </FinalReviewSubpanel>
            </Box>
            <Typography className={clsx(body_strong_14_semibold, subSectionTitle)}>
                Parties
            </Typography>
            <Grid container className={clsx(tableContainer, body_14_regular)}>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Buyers
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {parties?.buyers}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Sellers
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {parties?.sellers}
                </Grid>
            </Grid>
        </Box>
    );
};

export default OrderDetails;
