import { makeStyles } from 'tss-react/mui';
import colors from 'core/constants/colors';

const styles = makeStyles()(() => ({
    warningsPanel: {
        padding: '20px'
    },
    warningsList: {
        marginTop: '5px'
    },
    warningItemRow: {
        marginTop: '22px',
        marginBottom: '10px',
        justifyContent: 'space-between'
    },
    warningInfo: {
        display: 'flex',
        columnGap: '12px'
    },
    resolveButton: {
        color: colors.additional.information
    }
}));

export default styles;
